import React, { useState, useEffect } from 'react';
// CONTEXT
import { useUserData } from '../../../context/UserContext';
// COMPONENTS
import DefaultSelect from './DefaultSelect';
import Loading from '../../loading/Loading';
// FORM
import { isMandatoryMessage } from '../validator/ValidatorMessages';
// ACTIONS
import { list as condominiumsList, detail as condominiumDetail } from '../../../actions/Condominiums';
// UTILS
import { sortList } from '../../../utils/Sort';

const DefaultCondominiumSelect = ({ handleCondominiumList, handleCondominiumDetail, needDetail = false, options, value, onChange, onBlur, hasError }) => {
	// CONTEXT
	const { state } = useUserData();

	// STATE
	const [isLoading, setIsLoading] = useState(false);

	// USEEFFECT
	useEffect(
		() => {
			const fetchCondominiumsData = async () => {
				setIsLoading(true);
				await condominiumsList({
					withAddress: true,
					withManagers: true,
					withCounters: true,
					managerEntityId: state.user.profileAdminOrManager ? null : state.user.id,
					success: data => {
						let sortedList = sortList(data, 'code', 'asc');
						handleCondominiumList(sortedList.data);
						setIsLoading(false);
					},
					error: error => {}
				});
			};

			fetchCondominiumsData();
		}, // eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const handleCondominiumChange = option => {
		onChange(option);
		if (option && needDetail) {
			getCondominiumDetail(option);
		}
	};

	const getCondominiumDetail = async selectedcondominium => {
		setIsLoading(true);
		await condominiumDetail({
			id: selectedcondominium.id,
			success: condominium => {
				handleCondominiumDetail(condominium);
				setIsLoading(false);
			},
			error: error => {}
		});
	};

	return (
		<>
			<DefaultSelect
				label={'Condomínio'}
				instanceId={'condominiumSelect'}
				required={true}
				options={options}
				getOptionLabel={option => `${option.code} - ${option.name}`}
				defaultValue={null}
				value={value}
				onChange={handleCondominiumChange}
				isDisabled={options.length === 0}
				onBlur={onBlur}
				hasError={hasError}
				errorMessage={isMandatoryMessage}
			/>
			{isLoading && <Loading label={'A carregar...'} />}
		</>
	);
};

export default DefaultCondominiumSelect;
