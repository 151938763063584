import React from 'react';
// MODULES
import { Container } from 'react-bootstrap';
// COMPONENTS
import PageHeader from '../../components/header/PageHeader';
// CONTENT
import { SelectContent } from '../../content/resources';
import { content } from './content/resources';
// UTILS
import { userTypes } from '../../utils/Enums';

const Libax = () => {
	return (
		<div className="libax-main">
			<Container>
				<PageHeader
					marginsAndBorders="mt-5 mx-4"
					subtitle={SelectContent(content.subtitle, userTypes)}
					title={SelectContent(content.title, userTypes)}
					desc={SelectContent(content.desc, userTypes)}
					icon={'/img/servicos-mais-seguros-icon.png'}
				/>
				<div>
					<div className="iframe-container">
						<iframe
							id="libax-iframe"
							className="mt-0"
							src={'https://pages.libax.com/313/widget/privatearea'}
							title={SelectContent(content.title, userTypes)}
						></iframe>
					</div>
				</div>
			</Container>
		</div>
	);
};

export default Libax;
