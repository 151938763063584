import Form from 'react-bootstrap/Form';
import Select from 'react-select';
// STYLES
import { defaultSelectStyles } from '../styles/defaultSelectStyles';

const DefaultSelect = ({
	label,
	showLabel = true,
	instanceId,
	required,
	options,
	getOptionValue = option => option.id,
	getOptionLabel = option => option.label,
	defaultValue,
	value,
	onChange,
	onBlur,
	placeholder = '',
	menuPlacement,
	isDisabled = false,
	isClearable = false,
	hasError = false,
	errorMessage
}) => {
	return (
		<Form.Group className={`${isDisabled ? 'form--disabled' : ''} mb-4`}>
			{showLabel && <Form.Label className={`${required ? 'form--required' : ''}`}> {label} </Form.Label>}
			<Select
				label={label}
				instanceId={instanceId}
				options={options}
				getOptionValue={getOptionValue}
				getOptionLabel={getOptionLabel}
				defaultValue={defaultValue}
				value={value}
				styles={defaultSelectStyles}
				noOptionsMessage={() => 'Sem resultados'}
				placeholder={placeholder}
				menuPlacement={menuPlacement}
				onChange={onChange}
				onBlur={onBlur}
				isDisabled={isDisabled}
				isClearable={isClearable}
			/>
			{hasError && errorMessage !== '' && <small className={'text-danger d-block mt-2'}>{errorMessage}</small>}
		</Form.Group>
	);
};

export default DefaultSelect;
