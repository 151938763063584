import React from 'react';

// Modules
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// icons
import { FaShieldAlt } from 'react-icons/fa';
import { FaLightbulb } from 'react-icons/fa6';
export default function PartnerBalance({ partner, contract, value }) {
	return (
		<div className="balance-partner">
			<Row>
				<Col>
					{partner === 'Fast Path' ? <FaShieldAlt /> : <FaLightbulb />}
					<span>{partner}</span>
				</Col>
				<Col>
					<span>{contract} contratos</span>
				</Col>
				<Col xs={2} className="text-end">
					<strong>{value}€</strong>
				</Col>
			</Row>
		</div>
	);
}
