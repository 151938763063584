import React from 'react';

// Modules
import Table from 'react-bootstrap/Table';

export default function TotalBalance() {
	const invoiceList = [
		{
			benefit: '18',
			submit: '18-07-2023',
			state: 'Processamento'
		},
		{
			benefit: '18',
			submit: '17-07-2023',
			state: 'Pago'
		},
		{
			benefit: '54',
			submit: '10-07-2023',
			state: 'Cancelado'
		},
		{
			benefit: '38',
			submit: '3-07-2023',
			state: 'Pago'
		},
		{
			benefit: '24',
			submit: '17-06-2023',
			state: 'Pago'
		},
		{
			benefit: '18',
			submit: '15-06-2023',
			state: 'Pago'
		},
		{
			benefit: '36',
			submit: '13-05-2023',
			state: 'Pago'
		},
		{
			benefit: '18',
			submit: '13-05-2023',
			state: 'Pago'
		},
		{
			benefit: '24',
			submit: '23-04-2023',
			state: 'Cancelado'
		},
		{
			benefit: '24',
			submit: '23-04-2023',
			state: 'Pago'
		}
	];
	return (
		<Table className="balance-invoice--table">
			<thead>
				<tr>
					<th>Valor</th>
					<th>Submetido</th>
					<th>Estado da fatura</th>
				</tr>
			</thead>
			<tbody>
				{invoiceList.map((item, index) => (
					<tr key={index}>
						<td className="text-end">{item.benefit}€</td>
						<td>{item.submit}</td>
						{item.state === 'Processamento' && <td className="processing">{item.state}</td>}
						{item.state === 'Pago' && <td className="approved">{item.state}</td>}
						{item.state === 'Cancelado' && <td className="canceled">{item.state}</td>}
					</tr>
				))}
			</tbody>
		</Table>
	);
}
