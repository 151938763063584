import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import PageHeader from '../../components/header/PageHeader';
import * as Utils from '../../utils/Utils';
import './saldos.scss';
// CONTENT
import { content } from './content/resources';
import { SelectContent } from '../../content/resources';
import { finance as financeDetail } from '../../actions/Finance';

export const Saldos = ({ userType }) => {
	const [financeData, setFinanceData] = useState(null);

	const getBalances = async () => {
		await financeDetail({
			success: finance => {
				setFinanceData(finance);
			},
			error: error => {}
		});
	};

	useEffect(() => {
		getBalances();
	}, []);

	const formatDate = financeData => {
		const date = financeData.lastUpdatedOn;

		if (!Utils.isUndefinedOrNull(date)) {
			const originalDate = new Date(date);

			const day = originalDate.getDate();
			const month = originalDate.getMonth() + 1;
			const year = originalDate.getFullYear();

			const formattedDateString = `${day}/${month}/${year}`;

			return formattedDateString;
		} else {
			return '0';
		}
	};

	return (
		<Container className="saldos-page-main my-3">
			<PageHeader
				title={SelectContent(content.title, userType)}
				subtitle={SelectContent(content.subtitle, userType)}
				desc={
					<div className="d-flex align-items-center gap-3">
						{SelectContent(content.desc, userType)}
						{financeData && (
							<div className="d-flex flex-column align-items-center">
								<div className="saldos-page-balance-date text-center">
									<p className="pt-2">Atualizado em</p>
									<div className="mb-2">{formatDate(financeData)}</div>
								</div>
								<div className="saldos-page-balance d-flex align-items-center justify-content-center flex-column text-center">
									<p>Valor disponível</p>
									<div className="saldos-page-balance-value">
										{!Utils.isUndefinedOrNull(financeData.accruedBalance) ? financeData.accruedBalance : '0'} €
									</div>
								</div>
							</div>
						)}
					</div>
				}
			></PageHeader>
			<div>{SelectContent(content.info.title, userType)}</div>
			<br />
			<div>
				{financeData && (
					<div>
						No âmbito dos serviços contratados através da área Serviços+ na sua plataforma Gecond, informamos que tem um saldo acumulado no valor de{' '}
						{!Utils.isUndefinedOrNull(financeData.accruedBalance) ? financeData.accruedBalance : '0'} €{' '}
					</div>
				)}
			</div>
			<div className="pb-5">{SelectContent(content.info, userType)}</div>
		</Container>
	);
};
