import * as Utils from '../utils/Utils';
import { ApiRequest } from './Helper';

export async function validateVatNumber(a) {
	try {
		var parameters = [];

		if (!Utils.isUndefinedOrNull(a.value)) parameters.push('value=' + a.value);

		const response = await ApiRequest('GET', null, `${process.env.REACT_APP_API_URL}/api/Validators/ValidateVatNumber${parameters.length > 0 ? '?' + parameters.join('&') : ''}`);

		if (response.ok) {
			a.success(true);
		} else {
			a.error(false);
		}
	} catch (error) {
		a.error();
	}
}
