import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { GetToken, SetToken } from '../actions/Token';
// Actions
import { authentication } from '../actions/Authentication';
import { list as condominiumsList } from '../actions/Condominiums';
// Enums
import { CharibCompanyId, MarAzulCondoId } from '../utils/Constants';
import { userTypes } from '../utils/Enums';

const UserContext = createContext();

const initialState = {
	user: {},
	loading: true
};

const dataReducer = (state, action) => {
	switch (action.type) {
		case 'SET_DATA':
			return { ...state, user: action.payload, loading: false };
		case 'SET_LOADING':
			return { ...state, loading: action.payload };
		default:
			return state;
	}
};

export const UserDataProvider = ({ children }) => {
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const token = params.get('t');
	const subsidiaryId = params.get('s');
	const companyId = params.get('c');
	const store_token = GetToken();

	const user =
		store_token && token == null
			? JSON.parse(store_token)
			: {
					token: token,
					subsidiaryId: subsidiaryId,
					companyId: companyId
			  };

	// useReducer
	const [state, dispatch] = useReducer(dataReducer, initialState);

	// useEffect
	useEffect(
		() => {
			dispatch({ type: 'SET_LOADING', payload: true });

			const fetchData = async () => {
				if (store_token) {
					dispatch({ type: 'SET_DATA', payload: JSON.parse(store_token) });
				} else {
					dispatch({ type: 'SET_LOADING', payload: true });

					await authentication({
						token: user.token,
						success: data => {
							let user_data = {
								id: data.id,
								token: user.token,
								companyId: companyId,
								email: data.email,
								cellphone: data.cellphone,
								phone: data.phone,
								name: data.socialDesignation,
								photo: data.foto,
								licenseKey: data.licenseKey,
								subsidiaryId: subsidiaryId,
								subsidiaryVat: data.subsidiaryVat,
								profileAdminOrManager: data.profileAdminOrManager,
								functionId: data.functionId
							};

							SetToken(JSON.stringify(user_data));

							condominiumsList({
								success: data => {
									let userType = null;

									switch (true) {
										case isSelfManagement(data):
											userType = userTypes.SELF_MANAGEMENT;
											break;
										default:
											break;
									}

									user_data = {
										...user_data,
										type: userType
									};

									SetToken(JSON.stringify(user_data));
									dispatch({ type: 'SET_DATA', payload: user_data });
								},
								error: error => {
									throw new Error('Failed to fetch data');
								}
							});
						},
						error: error => {
							throw new Error('Failed to fetch data');
						}
					});
				}
			};

			fetchData();
		}, // eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	// METHODS
	const isCharib = () => {
		return companyId === CharibCompanyId;
	};

	const isSelfManagement = condominiumsList => {
		if (isCharib()) {
			return false;
		}

		switch (condominiumsList.length) {
			case 1:
				return condominiumsList.every(condo => condo.vatNumber.toString().startsWith('9'));
			case 2:
				const CondoMarAzulExists = condominiumsList.find(condo => condo.id === MarAzulCondoId);
				return CondoMarAzulExists && condominiumsList.every(condo => condo.vatNumber.toString().startsWith('9'));
			default:
				return false;
		}
	};

	return <UserContext.Provider value={{ state, dispatch }}>{children}</UserContext.Provider>;
};

export const useUserData = () => {
	const context = useContext(UserContext);
	if (!context) {
		throw new Error('useUserData must be used within a UserDataProvider');
	}
	return context;
};
