import { Col, Row, Table } from 'react-bootstrap';

export const content = {
	title: {
		default: ''
	},
	subtitle: {
		default: ''
	},
	desc: {
		default: (
			<>
				<Row style={{ border: '.3rem solid transparent', width: '27em' }}>
					<Col>
						<p
							style={{
								backgroundColor: '#398ec7',
								borderRadius: '.8em .8em 0 0',
								marginBottom: '0',
								padding: '.2em .8em'
							}}
							className="fw-bold fs-4 text-white"
						>
							Campanha
						</p>
						<div style={{ backgroundColor: '#67BAF1', borderRadius: '0 0 .8em .8em', padding: '.8em 1.2em' }}>
							<label className="text-white">É cliente PLUS?</label>
							<br />
							<strong className="text-white">Até 31 Julho</strong>
							<br />
							<label className="text-white">Teste gratuitamente</label>
							<br />
							<label className="text-white">Envie até 5 emails certificados</label>
							<br /> <br />
							<p className="text-white" style={{ fontSize: '.8em' }}>
								*Campanha válida até dia 31 de julho de 2024
							</p>
						</div>
					</Col>
				</Row>
				<div style={{ marginTop: '1em' }}>
					<h5 style={{ margin: '.6em 0 0 0' }}>Quer saber mais sobre este serviço?</h5>
				</div>
			</>
		)
	},
	services: {
		default: (
			<>
				<h3 className="text-center">O que é um e-mail certificado?</h3>
				<p>Uma forma simples e económica de dar, às suas comunicações por email, uma validade similar ao correio registado. </p>
				<br />
				<h4>Reduza drasticamente o custo de envio de cartas registadas com o serviço de emails certificados integrado com o Gecond </h4>
				<p>
					O serviço de certificação de emails comprova a entrega de um email através da geração de um certificado digital que pode baixar e dar como prova confiável da
					sua receção.{' '}
				</p>
				<br />
				<h4>
					Valor unitário:{' '}
					<span className="fs-5 fw-normal" style={{ color: '#757575' }}>
						1,49€ + IVA (só na versão Auto Gestão)
					</span>
				</h4>
				<br />
			</>
		)
	},
	information: {
		default: (
			<Row className="mb-5">
				<Col className="d-flex align-items-center">
					<ul className="list-unstyled">
						<li>
							<label>1. Conta de e-mail de origem e do destinário </label>
						</li>
						<li>
							<label>2. Data e hora de envio do e-mail certificado</label>
						</li>
						<li>
							<label>3. Título e conteúdo do e-mail certificado</label>
						</li>
						<li>
							<label>4. Conteúdo dos anexos</label>
						</li>
					</ul>
				</Col>
				<Col style={{ border: '0.1875rem solid #ecf3f8', borderRadius: '1em', padding: '1em' }}>
					<label>
						O nosso parceiro, 3G Mobile Group, é um prestador qualificado de Serviços de Confiança e Emitente de Certificados Digitais e selos qualificados, em
						conformidade com o Regulamento (UE) 910/2014 (eIDAS).
					</label>
				</Col>
				<br />
			</Row>
		)
	},
	benefit: {
		default: {
			title: 'Vantagens do e-mail certificado',
			benefitList: [
				{
					title: 'Prova de entrega',
					desc: 'Fornece uma prova de comunicação, essencial em certos casos, como por exemplo cobranças em pré-contencioso.'
				},
				{
					title: 'Rápido e económico',
					desc: 'É enviado e recebido em poucos minutos. Mais económico versus o envio por correio registado simples.'
				},
				{
					title: 'Simples e compatível',
					desc: 'Paga por cada certificado gerado e não obriga a nenhuma instalação.'
				},
				{
					title: 'Integrado com o Gecond',
					desc: 'Registo, listagem e consulta dos certificados gerados. Possibilidade de descarregar o certificado e dá-lo como prova.'
				}
			]
		}
	},
	costs: {
		default: (
			<>
				<h4>Onde posso adquirir este serviço? </h4>
				<label>Na loja online da sua plataforma </label>
				<br /> <br />
				<h4>Quanto custa o serviço?</h4>
				<div>
					<label>
						<strong>Valor unitário: </strong> a partir de 0,57€
					</label>
					<br />
					<label>
						<strong>Pacote mínimo: </strong> 100 certificados
					</label>
					<br />
					<label>
						<strong>PVP recomendado ao público: </strong> 1,49€
					</label>
					<br /> <br />
					<ul className="mb-0">
						<li>
							<label>Aos valores apresentados acresce IVA á taxa</label>
						</li>
					</ul>
				</div>
			</>
		)
	},
	table: {
		default: (
			<>
				<thead>
					<tr>
						<th>Packs certificados</th>
						<th>Valor unitário</th>
						<th>Valor total</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>100</td>
						<td>0,95€</td>
						<td>95,00€</td>
					</tr>
					<tr>
						<td>500</td>
						<td>0,90€</td>
						<td>451,25€</td>
					</tr>
					<tr>
						<td>1000</td>
						<td>0,86€</td>
						<td>855,00€</td>
					</tr>
					<tr>
						<td>2000</td>
						<td>0,76€</td>
						<td>1 520,00€</td>
					</tr>
					<tr>
						<td>5000</td>
						<td>0,57€</td>
						<td>2 850,00€</td>
					</tr>
				</tbody>
			</>
		)
	},
	faq: {
		default: {
			title: 'Perguntas Frequentes',
			questionsList: [
				{
					title: 'Como posso confirmar se o e-mail certificado foi enviado?',
					desc: 'Depois de enviar o e-mail certificado, nosso servidor de certificação validará e notificará sua entrega. Assim que o envio for feito, você encontrará seu certificado na listagem de envios.'
				},
				{
					title: 'Como posso comprovar o envio de um e-mail certificado?',
					desc: 'Para cada e-mail certificado enviado, você pode baixar um documento PDF que atesta a origem e o destino, bem como a data e o conteúdo do mesmo.'
				},
				{
					title: 'Como posso enviar e-mail certificado?',
					desc: 'Para enviar e-mails certificados, só precisa de um serviço de envio de e-mails e adquirir packs de certificados na loja da plataforma Gecond.'
				},
				{
					title: 'Qual é a diferença entre e-mail normal e um e-mail certificado?',
					desc: 'A diferença entre um e-mail e um e-mail certificado é que existe um servidor de e-mail que certifica a data e hora da entrega, o remetente, o destino e o conteúdo do e-mail, gerando um certificado que pode ser usado para demonstrar a veracidade da comunicação.'
				}
			]
		}
	}
};
