export const hirepoweroptions = [
	{
		id: 1,
		label: '1,15'
	},
	{
		id: 2,
		label: '2,30'
	},
	{
		id: 3,
		label: '3,45'
	},
	{
		id: 4,
		label: '4,60'
	},
	{
		id: 5,
		label: '5,75'
	},
	{
		id: 6,
		label: '6,90'
	},
	{
		id: 7,
		label: '10,35'
	},
	{
		id: 8,
		label: '13,80'
	},
	{
		id: 9,
		label: '17,25'
	},
	{
		id: 10,
		label: '20,70'
	}
];

export const preferredsupplieroptions = [
	{
		id: 5,
		label: 'Endesa'
	},
	{
		id: 4,
		label: 'Iberdrola'
	}
	// {
	// 	id: 6,
	// 	label: 'Luzigás'
	// }
];

export const selectrateoptions = [
	{
		label: 'Tarifa simples',
		value: '1'
	},
	{
		label: 'Tarifa bi-horária',
		value: '2'
	}
];
