const sort_direction = {
	ASC: 'A',
	DESC: 'D'
};

export function sortList(data, key, order) {
	return sortData(data, order === 'asc' ? sort_direction.ASC : sort_direction.DESC, true, key, key);
}

function sortData(data, dir, block, keyActual, sortKey) {
	if (data && data.length) {
		if (!block && keyActual === sortKey) {
			dir = dir === sort_direction.ASC ? sort_direction.DESC : sort_direction.ASC;
		}

		if (sortKey.includes('.')) {
			let _SortKeys = sortKey.split('.');

			if (dir === sort_direction.ASC) {
				data.sort((b, a) => doSort(a, b, _SortKeys));
			} else {
				data.sort((a, b) => doSort(a, b, _SortKeys));
			}
		} else {
			if (dir === sort_direction.ASC) {
				data.sort((a, b) => (Number.isInteger(a[sortKey]) ? a[sortKey] - b[sortKey] : a[sortKey].toString().toLowerCase().localeCompare(b[sortKey].toString().toLowerCase())));
			} else {
				data.sort((a, b) => (Number.isInteger(a[sortKey]) ? b[sortKey] - a[sortKey] : b[sortKey].toString().toLowerCase().localeCompare(a[sortKey].toString().toLowerCase())));
			}
		}
	}

	return {
		data: data,
		dir: dir
	};
}

function doSort(a, b, s) {
	return Number.isInteger(a[s[0]][s[1]])
		? (b[s[0]] === null ? 0 : b[s[0]][s[1]]) - (a[s[0]] === null ? 0 : a[s[0]][s[1]] || 0)
		: (b[s[0]] === null ? '' : b[s[0]][s[1]])
				.toString()
				.toLowerCase()
				.localeCompare((a[s[0]] === null ? '' : a[s[0]][s[1]]).toString().toLowerCase());
}
