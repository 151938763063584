import { renderTableHead, renderTableTitle, renderTableIntrodution, renderTableSectionTitle, renderTableFooter, renderTableRow } from '../../../components/email/Email';

export const ContactRequestEmailTemplate = data => {
	const emailSubject = data.subject;
	const emailBody = `<!DOCTYPE html PUBLIC '-//W3C//DTD HTML 4.01 Transitional//EN' 'http://www.w3.org/TR/html4/loose.dtd'><html lang="en">
		${renderTableHead(data.subject)}
		<body style='margin: 0; padding: 0; background-color: #f2f2f2; font-family: ' Century Gothic', 'Verdana' , 'Tahoma' ; font-size: 14px'>
			<span style='display: block; width: 640px !important; max-width: 640px; height: 1px' class='mobileOff'></span>
			<center>
				<table width="100%" border="0" cellpadding="0" cellspacing="0" bgcolor="#F2F2F2">
					<tr>
						<td align="center" valign="top">
							${renderTableTitle(data.subject)}
							${renderTableIntrodution('Foi efetuado um pedido de contacto com os dados a seguir apresentados:')}
							<table width='640' cellpadding='0' cellspacing='0' border='0' class='wrapper' bgcolor='#FFFFFF'>
								${renderTableSectionTitle('Dados')}
								<tr>
									<td align='center' valign='top'>
										${renderTableRow('Nome', data.subsidiaryName)}
										${renderTableRow('NIF', data.subsidiaryVat)}
									</td>
								</tr>
								${renderTableSectionTitle('Gestor')}
								<tr>
									<td align='center' valign='top'>
										${renderTableRow('Nome', data.managerName)}
										${renderTableRow('Email', data.managerEmail)}
										${renderTableRow('Telefone', data.managerPhone)}
									</td>
								</tr>
							</table>
							${renderTableFooter(data.subsidiaryName)}
						</td>
					</tr>
				</table>
			</center>
		</body>
	</html>`;

	return {
		subject: emailSubject,
		body: emailBody
	};
};
