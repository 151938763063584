import React from 'react';
// MODULES
import Container from 'react-bootstrap/Container';
// COMPONENTS
import PageHeader from '../../components/header/PageHeader';
import CondominiumMultiriskInsuranceForm from './components/form/CondominiumMultiriskInsuranceForm';

export default function CondominiumMultiriskInsurance() {
	return (
		<Container>
			<PageHeader subtitle={'Seguros +'} title={'Multirriscos condomínios'} />
			<CondominiumMultiriskInsuranceForm />
		</Container>
	);
}
