export const userTypes = {
	CHARIB: 1,
	SELF_MANAGEMENT: 2
};

export const contactTypes = {
	PHONE: 1,
	CELLPHONE: 2,
	EMAIL: 4
};

export const serviceOrigin = {
	SERVICE_PLUS: 1,
	MANUAL: 2
};

export const serviceType = {
	ELECTRICITY: 1,
	INSURANCES: 2,
	OTHER: 3
};

export const servicePartner = {
	FAST_PATH_COMPANY: 1,
	FAST_PATH_CONDOMINIUM: 2,
	FAST_PATH_WORK_ACCIDENTS: 3,
	IBERDROLA: 4,
	ENDESA: 5,
	LUZIGAS: 6,
	CHARIB_ELECTRICITY: 7,
	CHARIB_INSURANCES: 8,
	MP: 9,
	CLOUDWARE: 10,
	LUSOPAY: 11,
	PORTALPRO: 12,
	NORDSTREET: 13,
	FAST_PATH_HEALTH: 15,
	FAST_PATH_PROFESSIONAL_LIABILITY: 17
};
