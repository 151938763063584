import Form from 'react-bootstrap/Form';
import Radio from '../../../components/form/inputs/components/Radio';

const DefaultRadio = ({ label, name, required, hasError = false, errorMessage, defaultValue, vertical = true, options, onChange }) => {
	return (
		<Form.Group className="mb-3">
			<Form.Label className={`${required ? 'form--required' : ''}`}> {label} </Form.Label>
			<Radio name={name} options={options} defaultValue={defaultValue} vertical={vertical} handleChange={onChange} />
			{hasError && errorMessage !== '' && <small className={'text-danger d-block mt-2'}>{errorMessage}</small>}
		</Form.Group>
	);
};

export default DefaultRadio;
