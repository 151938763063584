export const content = {
	title: {
		default: 'Plataforma Libax'
	},
	subtitle: {
		default: 'Libax'
	},
	desc: {
		default: (
			<div style={{ marginTop: '-1.2em' }}>
				<h5 style={{ color: '#757575' }}>Bem vindo à sua área de gestão de seguros</h5>
				<p>Aqui pode consultar e verificar o estado das suas apólices, recibos e participações.</p>
			</div>
		)
	}
};
