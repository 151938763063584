import { GetToken } from './Token';

export async function ApiRequest(method, data, url, headers, token) {
	if (!token) {
		const stoken = GetToken();
		token = JSON.parse(stoken).token;
	}

	const requestOptions = {
		method: method,
		headers: headers
			? headers
			: token
			? {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
			  }
			: undefined,
		body: data
	};

	return await fetch(url, requestOptions).then(handleErrors);
}

export async function ApiRequestNoJson(method, data, url, token) {
	if (!token) {
		const stoken = GetToken();
		token = JSON.parse(stoken).token;
	}

	const requestOptions = {
		method: method,
		headers: {
			Authorization: `Bearer ${token}`
		},
		body: data
	};

	return await fetch(url, requestOptions).then(handleErrors);
}

function handleErrors(response) {
	if (!response.ok) {
		if (response.status === 401) {
			window.location.replace('https://admin.improxy.com/');
		}
	}
	return response;
}

export async function ApiRequestLibax(method, data, url, headers, token) {
	const stoken = GetToken();
	const subsidiaryId = JSON.parse(stoken).subsidiaryId;

	if (!token) {
		token = JSON.parse(stoken).token;
	}

	const body = {
		categoryId: 'LIbax',
		key: 'DETAILS',
		subsidiaryId: subsidiaryId
	};

	const requestOptions = {
		method: method,
		headers: headers
			? headers
			: token
			? {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
			  }
			: undefined,
		body: JSON.stringify(body)
	};

	return await fetch(url, requestOptions).then(handleErrors);
}
