// ICONS
import { BsBoxSeamFill } from 'react-icons/bs';
import { FaCashRegister, FaFileInvoiceDollar } from 'react-icons/fa6';

export const content = {
	subtitle: {
		default: 'Vantagens'
	},
	title: {
		default: 'Programa de faturação integrado com o Gecond'
	},
	desc: {
		default: (
			<>
				<p>
					Subscreva o software de faturação online, Cloudware Business. Significa menos trabalho e rapidez de execução já que não tem de exportar documentos para
					diferentes ambientes digitais.
				</p>
				<p>
					Aproveite as condições especiais de subscrição: <span className="fw-bold pDark">2 meses de oferta na primeira subscrição</span>.
				</p>
			</>
		)
	},
	cardContent: {
		default: [
			{
				icon: FaFileInvoiceDollar,
				title: 'Faturação',
				desc: (
					<ul>
						<li>Pré-venda (orçamentos e proformas)</li>
						<li>Documentos de transporte</li>
						<li>Faturação</li>
						<li>Faturação recorrente (avenças)</li>
						<li>Faturação eletrónica</li>
						<li>Controlo de contas correntes de clientes</li>
					</ul>
				)
			},
			{
				icon: FaCashRegister,
				title: 'Compras e despesas',
				desc: (
					<ul>
						<li>Receção de produtos e matérias primas</li>
						<li>Faturas de compra</li>
						<li>Mapas de deslocações e despesas</li>
						<li>Auto-faturação</li>
						<li>Controlo de contas correntes de fornecedores</li>
					</ul>
				)
			},
			{
				icon: BsBoxSeamFill,
				title: 'Gestão de stocks',
				desc: (
					<ul>
						<li>Sugestão automática de stock com base nas vendas</li>
						<li>Possibilidade de imputação de custos de transporte, alfandegários, financeiros ou outros nas compras</li>
						<li>Cálculo automático do preço médio de custo e valorização de stock</li>
						<li>Análise de margens</li>
					</ul>
				)
			}
		]
	}
};
