import Form from 'react-bootstrap/Form';
import { default as ReactSelect } from 'react-select';
import useInput, { updateFormControl } from '../hooks/useInput';
// STYLES
import { defaultSelectStyles } from '../styles/defaultSelectStyles';

const Select = ({
	label,
	showLabel = true,
	instanceId,
	required,
	options,
	getOptionValue = option => option.id,
	getOptionLabel = option => option.label,
	defaultValue,
	placeholder = '',
	menuPlacement,
	isDisabled = false,
	isClearable = false,
	errorMessage,
	name,
	formControl,
	validations = () => true
}) => {
	const { value, isValid, hasError, hasErrorOnBlur, valueChangeHandler: onChange, inputBlurHandler: onBlur, reset } = useInput(validations);
	updateFormControl(formControl, name, value, isValid, hasError, hasErrorOnBlur, onChange, onBlur, reset);

	return (
		<Form.Group className={`${isDisabled ? 'form--disabled' : ''} mb-4`}>
			{showLabel && <Form.Label className={`${required ? 'form--required' : ''}`}> {label} </Form.Label>}
			<ReactSelect
				label={label}
				instanceId={instanceId}
				options={options}
				getOptionValue={getOptionValue}
				getOptionLabel={getOptionLabel}
				defaultValue={defaultValue}
				value={value}
				styles={defaultSelectStyles}
				noOptionsMessage={() => 'Sem resultados'}
				placeholder={placeholder}
				menuPlacement={menuPlacement}
				onChange={onChange}
				onBlur={onBlur}
				isDisabled={isDisabled}
				isClearable={isClearable}
			/>
			{hasError && errorMessage !== '' && <small className={'text-danger d-block mt-2'}>{errorMessage}</small>}
		</Form.Group>
	);
};

export default Select;
