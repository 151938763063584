import { renderTableHead, renderTableTitle, renderTableIntrodution, renderTableSectionTitle, renderTableFooter, renderTableRow } from '../../../components/email/Email';

export const CondominiumMultiriskInsuranceEmailTemplate = data => {
	const emailSubject = data.subject;
	const emailBody = `<!DOCTYPE html PUBLIC '-//W3C//DTD HTML 4.01 Transitional//EN' 'http://www.w3.org/TR/html4/loose.dtd'><html lang="en">
		${renderTableHead(data.subject)}
		<body style='margin: 0; padding: 0; background-color: #f2f2f2; font-family: ' Century Gothic', 'Verdana' , 'Tahoma' ; font-size: 14px'>
			<span style='display: block; width: 640px !important; max-width: 640px; height: 1px' class='mobileOff'></span>
			<center>
				<table width="100%" border="0" cellpadding="0" cellspacing="0" bgcolor="#F2F2F2">
					<tr>
						<td align="center" valign="top">
							${renderTableTitle(data.subject)}
							${renderTableIntrodution(data.message)}
							<table width='640' cellpadding='0' cellspacing='0' border='0' class='wrapper' bgcolor='#FFFFFF'>
								${renderTableSectionTitle('Empresa')}
								<tr>
									<td align='center' valign='top'>
										${renderTableRow('Nome', data.subsidiaryName)}
										${renderTableRow('NIF', data.subsidiaryVat)}
									</td>
								</tr>
								${renderTableSectionTitle('Condomínio')}
								<tr>
									<td align='center' valign='top'>
										${renderTableRow('Nome condomínio', data.condominiumName)}
										${renderTableRow('NIPC', data.condominiumVatNumber)}
										${renderTableRow('Morada', data.condominiumAddress)}
										${renderTableRow('Cód. postal', data.condominiumZipCode)}
										${renderTableRow('Localidade', data.condominiumZipLocation)}
									</td>
								</tr>
								${renderTableSectionTitle('Gestor')}
								<tr>
									<td align='center' valign='top'>
										${renderTableRow('Nome', data.managerName)}
										${renderTableRow('Email', data.managerEmail)}
										${renderTableRow('Telefone', data.managerPhone)}
									</td>
								</tr>
								${renderTableSectionTitle('Análise do local de risco')}
								<tr>
									<td align='center' valign='top'>
										${renderTableRow('Capital a segurar', data.realEstateCapital, '€')}
										${renderTableRow('Objeto do seguro', data.objectOfInsurance)}
										${renderTableRow('Data início do seguro', data.insuranceStartDate)}
										${renderTableRow('Ano de construção:', data.licenseEmissionDate)}
										${renderTableRow('Nº de frações do condomínio', data.numberOfFractions)}
										${renderTableRow('Nº de pisos acima do solo', data.numberOfFloorsAboveGround)}
										${renderTableRow('Nº de pisos abaixo do solo', data.numberOfFloorsBelowGround)}
										${renderTableRow('Tipo de construção', data.typeOfConstruction)}
										${renderTableRow('Obras de remodelação / recuperação', data.remodelingWorks)}
										${renderTableRow('Valor das obras', data.ifYes, '€')}
										${renderTableRow('Fenómenos sísmicos', data.seismicPhenomena)}
									</td>
								</tr>
							</table>
							${renderTableFooter(data.subsidiaryName)}
						</td>
					</tr>
				</table>
			</center>
		</body>
	</html>`;

	return {
		subject: emailSubject,
		body: emailBody
	};
};
