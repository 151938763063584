//Home
export const content = {
	intro: {
		title: {
			default: 'O programa de parcerias que lhe dá vantagens',
			selfManagement: 'O programa de parcerias que facilita a administração do seu condomínio'
		},
		subtitle: {
			default: (
				<>
					O programa de parcerias Gecond, especializado no setor de gestão de condomínios, foi criado para que encontre soluções com as maiores vantagens comerciais.
					<br />
					<br />
					Ganhe benefícios financeiros por cada subscrição e reduza custos na sua atividade.
				</>
			),
			selfManagement: (
				<>
					O programa de parcerias Gecond, especializado no setor de gestão de condomínios, foi criado para lhe dar soluções dedicadas que respondem aos seus desafios do
					seu condomínio.
					<br />
					<br />
					Especializado no setor de gestão de condomínios. Criado para encontrar as melhores soluções para o seu condomínio.
				</>
			)
		},
		scrollDown: {
			default: 'Ponha-nos à prova. Conheça as vantagens',
			selfManagement: 'Ponha-nos à prova. Conheça os serviços'
		}
	},
	insuranceHighlight: {
		link: {
			default: '/Insurance'
		},
		promo: { default: 'Ganhe 4%', selfManagement: '' },
		subtitle: {
			default: 'Seguros +'
		},
		title: {
			default: 'Está a pagar o preço justo pelos seguros?',
			selfManagement: 'É tempo de renovar o seguro do condomínio?'
		},
		desc: {
			default: (
				<>
					<p>Garanta a proteção dos condomínios com soluções dedicadas e condições especiais.</p>
					<p style={{ color: '#398ec7' }}>Não perca tempo nem dinheiro. Peça-nos cotação.</p>
					<p>Ganhe 4% sobre o valor do prémio comercial por cada seguro contratado.</p>
				</>
			),
			selfManagement: (
				<>
					Garanta a proteção do seu condomínio com soluções de seguro dedicadas, disponibilizadas pelo nosso parceiro <strong>Fast Path</strong>, que lhe apresenta
					condições preferenciais.
				</>
			)
		},
		descBold: {
			default: '',
			selfManagement: 'Renove o seguro do condomínio garantindo soluções dedicadas ao setor.'
		},
		btnLabel: {
			default: 'Ver soluções de seguros',
			selfManagement: 'Quero uma proposta'
		}
	},
	energyHighlight: {
		promo: {
			default: 'Ganhe 18€',
			selfManagement: ''
		},
		subtitle: {
			default: 'Eletricidade'
		},
		title: {
			default: 'Ganhe por cada contador...',
			selfManagement: 'Quer negociar o contrato de eletricidade do condomínio?'
		},
		desc: {
			default: 'Subscreva um contrato de eletricidade com os nossos parceiros e ganhe 18€ por contador.',
			selfManagement: 'Mudar de fornecedor nunca foi tão fácil – difícil é encontrar o fornecedor ideal, com as melhores condições. Mas não tem de ser assim.'
		},
		descBold: {
			default: 'Quanto mais subscrições mais o ganho. Já fez as contas?',
			selfManagement: 'Opte por uma resposta de fornecimento de eletricidade eficiente e financeiramente vantajosa para o seu condomínio.'
		},
		btnLabel: {
			default: 'QUERO UMA PROPOSTA'
		},
		link: {
			default: '/Energy'
		}
	},
	othersHighlightList: {
		default: [
			{
				img: '/img/mp.jpg',
				subtitle: 'Serviços postais para condomínios',
				title: 'Envio da correspondência: uma carga de trabalhos?',
				desc: (
					<>
						Não precisa de ser assim.
						<br />
						A nossa parceira MP Serviços Postais (MPSP) garante a expedição e o arquivo postal da correspondência dos seus condomínios.
						<br />
						Trate de tudo a partir do Gecond. Só tem de dar ordem de envio. E siga cada registo, quando quiser.
					</>
				),
				btnLabel: 'O que faz?',
				link: '/mp',
				promo: 'até 5% de cash back'
			},
			{
				img: '/img/logo-cloudware.jpg',
				subtitle: 'Cloudware',
				title: 'Emissão de faturas – empresa e condomínios',
				desc: (
					<>
						Rentabilize tempo e reduza procedimentos com a faturação, quer da sua empresa, quer para os seus condomínios no Gecond. <br />
						Com a integração do Cloudware Business, as faturas são criadas sem sair do Gecond.
					</>
				),
				btnLabel: 'Quais são as vantagens?',
				link: '/Cloudware',
				promo: '2 meses grátis'
			},
			{
				img: '/img/logo-lusopay.png',
				subtitle: 'Lusopay',
				title: 'Sistemas de pagamento seguros e fáceis?',
				desc: (
					<>
						Disponibilize sistemas de pagamento simples aos condóminos. <br /> A <span className="text-decoration-underline"> Lusopay</span> fornece vários
						<strong> sistemas de pagamento, integrados com o Gecond:</strong> referências Multibanco e Payshop, MB WAY e transferências simplificadas.
					</>
				),
				btnLabel: 'Como funciona',
				link: '/Lusopay',
				promo: 'Ganhe 40%'
			},
			{
				img: '/img/logo-portal-pro.jpg',
				subtitle: 'Serviço integrado com o Gecond',
				title: 'Precisa de um técnico ou de mais orçamentos?',
				desc: (
					<>
						A integração com o PortalPRO no Gecond dá às administrações de condomínios uma via direta para a resolução eficiente, em tempo útil, das ocorrências.
						<br />
						Sem custos, sem complicações.
					</>
				),
				btnLabel: 'O que faz?',
				link: '/PortalPro'
			},
			{
				img: '/img/logo-nordstreet.jpg',
				subtitle: 'Nordstreet',
				title: 'Financiamento de obras no condomínio',
				desc: (
					<>
						Este é um dos maiores problemas que pode enfrentar num condomínio.
						<br />
						Conseguir o valor necessário demora tempo e cria desconforto à administração.
					</>
				),
				btnLabel: 'Saber mais',
				link: '/Nordstreet'
			},
			{
				img: '/img/services_logo.png',
				subtitle: 'Emails certificados',
				title: 'Precisa de comprovar a entrega dos emails?',
				desc: (
					<>
						Envie emails certificados, um serviço que lhe fornece um registo digital de prova de entrega de cada email, integrado com a plataforma Gecond. <br />{' '}
						<br /> <strong>Reduza os elevadoa custos dos registos postais simples.</strong>
					</>
				),
				btnLabel: 'Como reduzir os custos?',
				link: '/CertifiedEmails'
			}
		],
		selfManagement: [
			{
				img: '/img/logo-lusopay.png',
				subtitle: 'Lusopay',
				title: 'Sistemas de pagamento seguros e fáceis?',
				desc: (
					<>
						Disponibilize sistemas de pagamento simples aos condóminos. <br /> A <span className="text-decoration-underline"> Lusopay</span> fornece vários
						<strong> sistemas de pagamento, integrados com o Gecond:</strong> referências Multibanco e Payshop, MB WAY e transferências simplificadas.
					</>
				),
				btnLabel: 'Ver preços e condições',
				link: '/Lusopay'
			},
			{
				img: '/img/logo-portal-pro.jpg',
				subtitle: 'Serviço integrado com o Gecond',
				title: 'Precisa de um técnico ou quer ter mais orçamentos?',
				desc: 'A integração com o PortalPRO no Gecond dá à sua administração uma via direta para a resolução eficiente, em tempo útil, das ocorrências no condomínio. Sem custos, sem complicações, encontre o técnico que procura para todo o tipo de obra.',
				btnLabel: 'O que faz?',
				link: '/PortalPro'
			},
			{
				img: '/img/logo-nordstreet.jpg',
				subtitle: 'Nordstreet',
				title: 'Financiamento de obras no condomínio',
				desc: (
					<>
						A dificuldade de financiamento é um dos maiores problemas que pode enfrentar um condomínio. Conseguir a verba necessária demora tempo e cria desconforto
						à administração.
						<br />
						Com a nossa parceira Nordstreet, obstáculo superado. Sem garantias solidárias. Sem hipotecas.
					</>
				),
				btnLabel: 'Ver preços e condições',
				link: '/Nordstreet'
			},
			{
				img: '/img/services_logo.png',
				subtitle: 'Emails certificados',
				title: 'Precisa de comprovar a entrega dos emails?',
				desc: (
					<>
						Envie emails certificados, um serviço que lhe fornece um registo digital de prova de entrega de cada email, integrado com a plataforma Gecond. <br />{' '}
						<br /> <strong>Reduza os elevadoa custos dos registos postais simples.</strong>
					</>
				),
				btnLabel: 'Como reduzir os custos?',
				link: '/CertifiedEmails'
			}
		]
	}
};
