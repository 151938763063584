import React from 'react';
// Components
import DefaultInput from './DefaultInput';
// Form
import { isMandatoryMessage, invalidNIPCMessage } from '../validator/ValidatorMessages';
import { vatNumberInputMask } from '../masks/InputMasks';

const DefaultVATInput = ({ label = 'NIPC', value, onChange, onBlur, hasError, hasErrorOnBlur }) => {
	return (
		<DefaultInput
			label={label}
			name={'vatNumber'}
			required={true}
			maxlength={9}
			type={'mask'}
			mask={vatNumberInputMask}
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			hasError={hasError || hasErrorOnBlur}
			errorMessage={hasErrorOnBlur ? invalidNIPCMessage : isMandatoryMessage}
		/>
	);
};

export default DefaultVATInput;
