import React from 'react';

export const LusopayInfo = ({ icon, title, description, isLastCard }) => {
	return (
		<div className="lusopay-info--content">
			<div className="d-flex flex-column justify-content-center align-items-center gap-3">
				<img className={`lusopay-info--content_img ${isLastCard ? 'lusopay-info--content_img-last' : ''}`} src={icon} alt={icon}></img>
				<h3 className="text-center mt-1">{title}</h3>
			</div>
			<label className="text-left">{description}</label>
		</div>
	);
};
