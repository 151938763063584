// MODULES
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// COMPONENTS
import CardBorders from '../../components/card/CardBorders';
import PageHeader from '../../components/header/PageHeader';
// CONTENT
import ContactRequest from '../../components/contactrequest/ContactRequest';
import { SelectContent } from '../../content/resources';
import EnergyForm from './components/form/EnergyForm';
import { content } from './content/resources';
// UTILS
import CardPopover from '../../components/CardPopover/CardPopover';
import { serviceType } from '../../utils/Enums';

export default function Energy({ userType }) {
	const EnergyPartnersList = SelectContent(content.energyPartnersList, userType);

	return (
		<Container className="energy-main">
			<PageHeader
				subtitle={SelectContent(content.subtitle, userType)}
				title={SelectContent(content.title, userType)}
				desc={SelectContent(content.desc, userType)}
				icon={'/img/servicos-mais-eletricidade-icon.png'}
				promo={SelectContent(content.promo, userType)}
			/>
			<ContactRequest serviceTypeId={serviceType.ELECTRICITY} />
			<Row className="gx-4 mt-5 mb-3 card-main">
				<Col md="6" xs="12">
					<CardPopover></CardPopover>
				</Col>
				{EnergyPartnersList.map((partner, index) => (
					<Col key={index} md="6" xs="12" className="card-main">
						<CardBorders content={partner} />
					</Col>
				))}
			</Row>
			<Row>
				<Col>
					<p>*Os preços da eletricidade podem sofrer alterações de acordo com o mercado. Por favor confirme os tarifários com o seu gestor de conta.</p>
				</Col>
			</Row>
			<EnergyForm />
		</Container>
	);
}
