import React, { useEffect, useState } from 'react';
import * as Utils from '../../../utils/Utils';
import { finance as financeDetail } from '../../../actions/Finance';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { FaAngleDown } from 'react-icons/fa6';
import { SelectContent } from '../../../content/resources';
import { content } from '../content/saldosResources';
import { userTypes } from '../../../utils/Enums';
import SaldosImage from '../content/homepage-saldos.png';
import Loading from '../../../components/loading/Loading';

const AccumulativeBalance = () => {
	// STATE
	const [financeData, setFinanceData] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const handleOpenModal = () => {
		setShowModal(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};

	const getBalances = async () => {
		await financeDetail({
			success: finance => {
				setFinanceData(finance);
				setIsLoading(false);
			},
			error: error => {}
		});
	};

	useEffect(() => {
		getBalances();
	}, []);

	// METHODS
	const formatDate = financeData => {
		const date = financeData.lastUpdatedOn;

		if (!Utils.isUndefinedOrNull(date)) {
			const originalDate = new Date(date);

			const day = originalDate.getDate();
			const month = originalDate.getMonth() + 1;
			const year = originalDate.getFullYear();

			const formattedDateString = `${day}/${month}/${year}`;

			return formattedDateString;
		} else {
			return '';
		}
	};

	function formatNumber(number) {
		const parts = number.toFixed(2).split('.');
		const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
		return integerPart + ',' + parts[1];
	}

	return (
		<div className="accumulated-balance">
			<div className="accumulated-balance-row d-flex">
				<div className="d-flex justify-content-center align-align-items-center flex-column ">
					<Row>
						<h3 className="accumulated-balance-title mb-0">{SelectContent(content.intro.title, userTypes)}</h3>
						<Col className="d-flex flex-row">
							<img className="accumulated-balance-img" src={SaldosImage} alt="Saldos-background" />
							<p className="accumulated-balance-description text-end">{SelectContent(content.intro.subtitle, userTypes)}</p>
						</Col>
						<div className="accumulated-balance-link mx-auto my-4 text-center ">
							<h5>{SelectContent(content.intro.scrollDown, userTypes)}</h5>
							<a href="#highlight">
								<FaAngleDown size={23} color="#398ec7" />
							</a>
						</div>
					</Row>
				</div>

				<div className="accumulated-balance-values d-flex justify-content-center align-align-items-center flex-column">
					<h5 className="accumulated-balance-values-title text-white mt-5">{SelectContent(content.accountBalance.title, userTypes)}</h5>
					<div className="accumulated-balance-values-box p-3" style={{ height: financeData?.accruedBalance !== 0 ? '13em' : '16em' }}>
						<div className="accumulated-balance-values-box-content d-flex justify-content-center flex-column text-center">
							<div className="accumulated-balance-values-box-content-balance d-flex align-items-center flex-column fw-bold">
								<div>{SelectContent(content.accountBalance.description, userTypes)}</div>
								<div>
									<span className="accumulated-balance-win fw-bold">{SelectContent(content.accountBalance.description.wonText, userTypes)}</span>
									<span className="fs-3">
										{!Utils.isUndefinedOrNull(financeData?.accruedBalance) ? `${formatNumber(financeData?.accruedBalance)} €` : ''}
									</span>
								</div>
							</div>
							<div className="text-center">
								{financeData?.accruedBalance === 0 && (
									<>
										<label className="accumulated-balance-nobalance py-3">
											{SelectContent(content.accountBalance.noBalanceDescription, userTypes)}
										</label>
										<div>
											<a className="accumulated-balance-nobalance-link mt-2" href="#highlight">
												Ver parcerias
											</a>
										</div>
									</>
								)}
								{financeData?.accruedBalance !== 0 && (
									<div className="text-center mt-3" onClick={handleOpenModal}>
										{SelectContent(content.accountBalance.btnText, userTypes)}
									</div>
								)}
							</div>
							<Modal size="lg" show={showModal} onHide={handleCloseModal}>
								<Modal.Header closeButton>
									<h2 className="accumulated-balance-modal-title">{SelectContent(content.accountBalance.modal.title, userTypes)}</h2>
								</Modal.Header>
								<Modal.Body>
									<div className="accumulated-balance-modal-body">{SelectContent(content.accountBalance.modal, userTypes)}</div>
								</Modal.Body>
								<Modal.Footer>
									<Button className="accumulated-balance-modal-btn" onClick={handleCloseModal}>
										Fechar
									</Button>
								</Modal.Footer>
							</Modal>
						</div>
					</div>
					<div className="accumulated-balance-date text-white">
						<small>
							{financeData?.lastUpdatedOn && (
								<>
									<span>{SelectContent(content.accountBalance.lastUpdated, userTypes)}</span>
									<span>{formatDate(financeData)}</span>
								</>
							)}
						</small>
					</div>
				</div>
				<div className="accumulated-balance-values-sm mx-auto my-4 text-center ">
					<h5>{SelectContent(content.intro.scrollDown, userTypes)}</h5>
					<a href="#highlight">
						<FaAngleDown size={23} color="#398ec7" />
					</a>
				</div>
			</div>
			{isLoading && <Loading label={'A carregar...'} />}
		</div>
	);
};

export default AccumulativeBalance;
