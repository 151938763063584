import Form from 'react-bootstrap/Form';
import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';
import useInput, { updateFormControl } from '../hooks/useInput';

const DefaultDatepicker = ({ label, showLabel = true, dateFormat = 'DD-MM-YYYY', timeFormat = false, required = false, errorMessage = '', name, formControl, validations = () => true }) => {
	const datetimeWorkaround = value => (value === '' ? { value: '' } : {});
	const { value, isValid, hasError, hasErrorOnBlur, valueChangeHandler: onChange, inputBlurHandler: onBlur, reset } = useInput(validations);
	updateFormControl(formControl, name, value, isValid, hasError, hasErrorOnBlur, onChange, onBlur, reset);

	return (
		<Form.Group className="mb-4">
			{showLabel && <Form.Label className={`${required ? 'form--required' : ''}`}> {label} </Form.Label>}
			<Datetime
				dateFormat={dateFormat}
				timeFormat={timeFormat}
				value={value}
				inputProps={{
					...datetimeWorkaround(value)
				}}
				onChange={onChange}
				onBlur={onBlur}
				className="datepicker"
			/>
			{hasError && errorMessage !== '' && <small className={'text-danger d-block mt-2'}>{errorMessage}</small>}
		</Form.Group>
	);
};

export default DefaultDatepicker;
