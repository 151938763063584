import Form from 'react-bootstrap/Form';

const DefaultFileInput = ({ label, required, hasError = false, errorMessage, onChange, onBlur }) => {
	return (
		<Form.Group className="mb-4">
			<Form.Label className={`${required ? 'form--required' : ''}`}>{label}</Form.Label>
			<Form.Control type="file" accept="image/*,.pdf" onChange={onChange} onBlur={onBlur} />
			{hasError && errorMessage !== '' && <small className={'text-danger d-block mt-2'}>{errorMessage}</small>}
		</Form.Group>
	);
};

export default DefaultFileInput;
