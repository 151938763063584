import React, { useCallback, useState } from 'react';
// MODULES
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// CONTEXT
import { useUserData } from '../../context/UserContext';
// COMPONENTS
import { alertError, alertSuccess } from '../../components/feedback/Feedback';
import Loading from '../../components/loading/Loading';
// ACTIONS
import { save as saveLog } from '../../actions/LogRegister';
import { send_notification } from '../../actions/Notifier';
import { detail as subsidiaryDetail } from '../../actions/Subsidiary';
// UTILS
import { GecondPartnershipEmail } from '../../utils/Constants';
import { serviceOrigin, serviceType, servicePartner } from '../../utils/Enums';
// RESOURCES
import { ContactRequestEmailTemplate } from './emailtemplates/ContactRequestEmailTemplate';

export default function ContactRequest({ serviceTypeId, partnerId }) {
	// CONTEXT
	const { state } = useUserData();

	// STATE
	const [isLoading, setIsLoading] = useState(false);
	const [log, setLog] = useState({
		id: 0,
		subsidiaryId: state.user.subsidiaryId,
		userId: state.user.id,
		originId: serviceOrigin.SERVICE_PLUS,
		startDate: new Date(),
		serviceTypeId: serviceTypeId,
		partnerId: partnerId
	});

	// METHODS
	// Log
	const saveFormLog = useCallback(
		async () => {
			await saveLog({
				data: { ...log },
				success: logId => {
					setLog({ ...log, id: logId });
				},
				error: error => {}
			});
		}, // eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	// Subsidiary
	const getSubsidiaryDetail = async () => {
		setIsLoading(true);

		await subsidiaryDetail({
			success: subsidiary => {
				sendNotification(subsidiary);
			},
			error: error => {
				setIsLoading(false);
				alertError('Ocorreu um erro na submissão do formulário. Por favor, tente novamente.');
			}
		});
	};

	// Notification
	const getEmailTemplate = subsidiary => {
		let emailsubject = 'Pedido contacto';

		switch (serviceTypeId.toString()) {
			case serviceType.ELECTRICITY.toString():
				emailsubject = 'Energia - Pedido contacto';
				break;
			case serviceType.INSURANCES.toString():
				emailsubject = 'Seguros - Pedido contacto';
				break;
			case serviceType.OTHER.toString():
				switch (partnerId.toString()) {
					case servicePartner.NORDSTREET.toString():
						emailsubject = 'NORDSTREET - Pedido contacto';
						break;
					default:
						break;
				}
				break;
			default:
				break;
		}

		return ContactRequestEmailTemplate({
			subject: emailsubject,
			subsidiaryName: subsidiary.name,
			subsidiaryId: state.user.subsidiaryId,
			subsidiaryVat: state.user.subsidiaryVat,
			managerName: state.user.name,
			managerEmail: state.user.email,
			managerPhone: state.user.cellphone ? state.user.cellphone : state.user.phone ? state.user.phone : ''
		});
	};

	const sendNotification = async subsidiary => {
		const emailtemplate = getEmailTemplate(subsidiary);
		let _formData = new FormData();

		_formData.append('body', emailtemplate.body);
		_formData.append('subject', emailtemplate.subject);
		_formData.append('toAddresses', GecondPartnershipEmail);
		_formData.append('isHTML', true);
		_formData.append('priority', 0);

		await send_notification({
			data: _formData,
			success: data => {
				alertSuccess('Pedido de contacto submetido com sucesso');
				saveFormLog();
				setIsLoading(false);
			},
			error: error => {
				alertError('Ocorreu um erro no envio do pedido de contacto. Por favor, tente novamente.');
				setIsLoading(false);
			}
		});
	};

	const handleSendContactRequest = event => {
		event.preventDefault();

		getSubsidiaryDetail();
	};

	return (
		<>
			<Row className="my-4">
				<Col>
					<h2 className="mb-3">Fale connosco</h2>
					<h5 style={{ marginBottom: '1rem' }}>Tirámos as suas dúvidas</h5>
					<Button className={'service-btn ms-0 mt-0'} onClick={handleSendContactRequest}>
						QUERO SER CONTACTADO
					</Button>
				</Col>
			</Row>
			{isLoading && <Loading label={'A carregar...'} />}
		</>
	);
}
