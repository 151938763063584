import React from 'react';
// MODULES
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { HashLink as Link } from 'react-router-hash-link';
// COMPONENTS
import Highlight from './components/HighlightHome';
import Others from './components/OthersHighlightHome';
import AccumulativeBalance from './components/AccumulativeBalance';
// FONTAWESOME
import { FaAngleDown } from 'react-icons/fa6';
//CONTENT
import { content } from './content/resources';
import { SelectContent } from '../../content/resources';
import { useUserData } from '../../context/UserContext';

export default function Home({ userType }) {
	// STATE
	const OthersHighlightList = SelectContent(content.othersHighlightList, userType);

	// CONTEXT
	const { state } = useUserData();

	// CONSTANTS
	const showSaldos = state.user.functionId === 1 ? true : false;

	return (
		<div className="home-main">
			{showSaldos && <AccumulativeBalance />}
			{!showSaldos && (
				<>
					<Container fluid className="home-main--cover">
						<div className="home-main--cover__title__bubbles-left"></div>
						<Row className="home-main--cover__title">
							<h1 className="my-3">{SelectContent(content.intro.title, userType)}</h1>
							<p className="my-3 fs-5">{SelectContent(content.intro.subtitle, userType)}</p>

							<Col className="home-main--cover__anchor mx-auto mt-5 text-center">
								<h5>{SelectContent(content.intro.scrollDown, userType)}</h5>
								<Link to="#highlight" preventScrollReset={true}>
									<FaAngleDown />
								</Link>
							</Col>
						</Row>
						<div className="home-main--cover__img"></div>
					</Container>
				</>
			)}
			<div className={`home-main--highlight ${showSaldos ? 'home-main--highlight-saldos' : 'home-main--highlight'}`}>
				<Container id="highlight">
					<Row className="gx-5">
						<Highlight
							link={SelectContent(content.insuranceHighlight.link, userType)}
							promo={SelectContent(content.insuranceHighlight.promo, userType)}
							subtitle={SelectContent(content.insuranceHighlight.subtitle, userType)}
							title={SelectContent(content.insuranceHighlight.title, userType)}
							desc={SelectContent(content.insuranceHighlight.desc, userType)}
							descBold={SelectContent(content.insuranceHighlight.descBold, userType)}
							btnLabel={SelectContent(content.insuranceHighlight.btnLabel, userType)}
						/>
						<Highlight
							link={SelectContent(content.energyHighlight.link, userType)}
							promo={SelectContent(content.energyHighlight.promo, userType)}
							subtitle={SelectContent(content.energyHighlight.subtitle, userType)}
							title={SelectContent(content.energyHighlight.title, userType)}
							desc={SelectContent(content.energyHighlight.desc, userType)}
							descBold={SelectContent(content.energyHighlight.descBold, userType)}
							btnLabel={SelectContent(content.energyHighlight.btnLabel, userType)}
						/>
					</Row>
				</Container>
			</div>
			<div
				id="partnerships"
				className={`${OthersHighlightList.length < 5 ? 'other-services--2Cols' : ''} ${
					showSaldos ? 'home-main--partnerships-saldos' : 'home-main--partnerships'
				} home-main--partnerships mx-auto`}
			>
				<Container>
					<Row className="home-main-other--partnerships gx-5">
						{OthersHighlightList.map((item, index) => (
							<Others
								key={index}
								link={item.link}
								img={item.img}
								subtitle={item.subtitle}
								title={item.title}
								desc={item.desc}
								promo={item.promo}
								btnLabel={item.btnLabel}
							/>
						))}
					</Row>
				</Container>
			</div>
		</div>
	);
}
