import { ApiRequest } from './Helper';

export async function detail(a) {
	try {
		const response = await ApiRequest('GET', null, `${process.env.REACT_APP_API_URL}/api/Subsidiaries`);

		if (response.ok) {
			const data = await response.json();
			a.success(data);
		} else {
			a.error(response.status);
		}
	} catch (error) {
		a.error();
	}
}
