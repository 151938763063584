import { useState } from 'react';
// COMPONENTS
import RadioInput from './RadioInput';

const Radio = ({
	name,
	options = [
		{
			label: 'Sim',
			value: 'true'
		},
		{
			label: 'Não',
			value: 'false'
		}
	],
	defaultValue,
	vertical,
	handleChange,
	readOnly = false
}) => {
	const [value, setValue] = useState(defaultValue);

	const handleRadioChange = event => {
		if (readOnly) {
			return;
		}

		setValue(event.target.value);
		handleChange(event.target.value);
	};

	return (
		<div className={`d-flex ${vertical ? 'flex-column' : ''}`}>
			{options.map(option => {
				return <RadioInput key={name + option.value} name={name} value={option.value} label={option.label} checked={value === option.value} onChange={handleRadioChange} />;
			})}
		</div>
	);
};

export default Radio;
