import React from 'react';
// ICONS
import { FaBuilding, FaUserTie, FaHelmetSafety, FaHeartCirclePlus, FaCar } from 'react-icons/fa6';

export const content = {
	subtitle: {
		default: 'Seguros +'
	},
	title: {
		default: <>Receber os comprovativos do seguro é uma dor de cabeça? Tem um prédio com mais de 35 anos e paga franquia?</>,
		selfManagement: 'Está a pagar o valor justo pelo seguro do seu condomínio?'
	},
	desc: {
		default: (
			<>
				<p>
					<strong>Conheça as nossas soluções:</strong> o nosso parceiro Fast Path em co-mediação com a Portinsurance, um grupo com 30 anos de experiência, oferece
					diversas opções de cobertura adaptadas às características do seu condomínio.
				</p>
				<p>
					<strong>Simplificamos o seu dia-a-dia:</strong> pode contar com apoio contínuo nas simulações, renovações e aconselhamento técnico para lidar com os sinistros.​
				</p>
				<p style={{ color: '#398ec7' }}>
					<strong>Ainda não tem seguros connosco?</strong> Não perca tempo nem dinheiro. Peça-nos cotação.
				</p>
				<p>
					<strong>
						<span className="highlight">Ganhe 4%</span> sobre o valor do prémio comercial
					</strong>{' '}
					por cada seguro contratado.
				</p>
			</>
		),
		selfManagement: (
			<>Garanta a proteção do seu condomínio. Conheça as soluções com condições especiais do nosso parceiro Fastpath em co-mediação com a Portinsurance. Experimente connosco!</>
		)
	},
	modalTitle: {
		default: <h2 style={{ color: '#398ec7', paddingLeft: 0 }}>Ainda não tem seguros connosco? </h2>
	},
	modalDescription: {
		default: <>Após a subscrição de seguros connosco poderá fazer a gestão de todas as apólices na plataforma Libax.</>
	},
	insuranceContent: {
		default: [
			{
				icon: FaBuilding,
				title: 'Multirriscos condomínio',
				desc: (
					<span>
						Subscreva um seguro coletivo abrangente, que cubra múltiplas situações e despesas, totalmente adaptado à realidade do seu condomínio e, como tal,
						facilitador da resolução de sinistros. <strong>A solução ideal para o condomínio</strong>.
					</span>
				),
				btn: {
					label: 'Quero uma proposta',
					link: '/CondominiumMultiriskInsurance'
				}
			},
			{
				icon: FaUserTie,
				title: 'Multirriscos empresa',
				desc: (
					<span>
						A administração profissional de condomínios exige atuar em várias frentes, agregar diferentes recursos. A solução multirriscos cobre os riscos gerais da
						empresa e os riscos particulares da sua atividade. <strong>Proteja o seu negócio e a si</strong>.
					</span>
				),
				btn: {
					label: 'Quero uma proposta',
					link: '/CompanyMultiriskInsurance'
				}
			},
			{
				icon: FaHelmetSafety,
				title: 'Acidentes de trabalho',
				desc: (
					<span>
						Garanta a melhor cobertura de acidentes de trabalho para os seus colaboradores e os seus condomínios. Numa atividade que obedece a intervenções no
						terreno, há riscos humanos e materiais que não podem ser ignorados. <strong>Proteja o bem mais importante, as pessoas</strong>.
					</span>
				),
				btn: {
					label: 'Quero uma proposta',
					link: '/WorkplaceAccident'
				}
			},
			{
				icon: FaUserTie,
				title: 'Seguro responsabilidade civil profissional',
				desc: (
					<span>
						Obrigatório conforme o tipo de atividade empresarial e riscos associados, o seguro de responsabilidade civil cobre situações em que é necessário
						indemnizar terceiros por danos causados. <strong>Proteja-se do imprevisto, ele acontece</strong>!
					</span>
				),
				btn: {
					label: 'Quero uma proposta',
					link: '/ProfessionalCivilLiabilityInsurance'
				}
			},
			{
				icon: FaHeartCirclePlus,
				title: 'Seguro de saúde',
				desc: <span>Não é obrigatório, mas esta opção, a par de outros seguros complementares, é valorizada no mercado de trabalho.</span>,
				btn: {
					label: 'Quero uma proposta',
					link: '/HealthInsurance'
				}
			},
			{
				icon: FaCar,
				title: 'Seguro automóvel',
				desc: (
					<span>
						Uma solução automóvel, com vantagens contratuais. Faça a simulação online do seguro das viaturas da empresa de forma rápida e simples.{' '}
						<strong>Compare soluções, tome decisões</strong>!
					</span>
				),
				comingsoon: true
			}
		],
		selfManagement: [
			{
				icon: FaBuilding,
				title: 'Multirriscos condomínio',
				desc: (
					<>
						A opção multirriscos é a ideal para o condomínio. <br />
						Subscreva um seguro coletivo abrangente, que cubra múltiplas situações e despesas, totalmente adaptado à realidade do seu condomínio e, como tal,
						facilitador da resolução de sinistros.
					</>
				),
				link: '/CondominiumMultiriskInsurance'
			},
			{
				icon: FaHelmetSafety,
				title: 'Acidentes de trabalho',
				desc: (
					<>
						Garanta a melhor cobertura de acidentes de trabalho para os seus colaboradores e os seus condomínios. <br />
						Numa atividade que obedece a intervenções no terreno, há riscos humanos e materiais que não podem ser ignorados.
					</>
				),
				link: '/WorkplaceAccident'
			}
		]
	},
	libaxPlatform: {
		default: 'Plataforma Libax'
	},
	libaxPlatformDescription: {
		default: <h5 style={{ marginBottom: '1rem' }}>Consulte e faça a gestão de todas as apólices no mesmo sítio.</h5>
	}
};

export const SeismicPhenomenaTooltipBody = () => (
	<>
		<p>
			Cobre os danos causados aos bens seguros (imóvel e / ou conteúdos) em consequência da ação direta de tremores de terra, terramotos, erupções vulcânicas, maremotos e fogo
			subterrâneo e ainda incêndio resultante destes fenómenos. Considerar-se-ão como um único sinistro os fenómenos ocorridos dentro de um período de 72 horas após a constatação dos
			primeiros prejuízos verificados nos bens seguros.
		</p>
		<p>Confirme as condições pré-contratuais na subscrição.</p>
	</>
);

export const RealEstateCapitalBelowTooltipBody = () => <p>O valor do capital a segurar proposto pode ser diferente do pedido. Fazemos a correta avaliação do imóvel.</p>;

export const RealEstateCapitalAboveTooltipBody = () => <p>Pedidos de cotação para condomínios com capital a segurar igual ou superior a 10.000.000€ podem demorar até 72h a serem respondidos.</p>;
