export const content = {
	privacypolicy: {
		default: 'Política de Privacidade'
	},
	developBuy: {
		default: 'Desenvolvido por:'
	},
	terms: {
		default: 'Termos e condições'
	},
	urlTerms: {
		default: 'https://admin.improxy.com/App_Files/Termos%20e%20Condi%C3%A7%C3%B5es%20Gecond.pdf'
	}
};
