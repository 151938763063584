import { Link } from 'react-router-dom';

// MODULES
import Col from 'react-bootstrap/Col';

export default function HighlightHome({ subtitle, title, desc, promo, descBold, link, btnLabel }) {
	return (
		<Col className="pb-5">
			<div className="home-main--highlight__section">
				{promo && <div className="home-main--highlight__section-promo">{promo}</div>}
				<h6>{subtitle}</h6>
				<h2>{title}</h2>
				<label className="mb-3">{desc}</label>
				<p className="fw-bold">{descBold}</p>
				<div className="d-flex justify-content-center">
					<Link to={link} className="service-btn mt-4">
						{btnLabel}
					</Link>
				</div>
			</div>
		</Col>
	);
}
