export const content = {
	intro: {
		title: {
			default: (
				<>
					O programa de parcerias que lhe dá <div className="accumulated-balance-advantages">vantagens</div>
				</>
			)
		},
		subtitle: {
			default: (
				<>
					<br />
					<br />
					<span style={{ color: '#757575', fontWeight: '500' }}>Ganhe benefícios financeiros por cada subscrição e reduza custos na sua atividade</span>
					<br />
					<br />
					<br />
					<span>
						O programa de parcerias Gecond, especializado no setor de gestão de condomínios, foi criado para que encontre soluções com as maiores vantagens
						comerciais
					</span>
				</>
			)
		},
		scrollDown: {
			default: 'Ponha-nos à prova. Conheça as vantagens'
		}
	},
	accountBalance: {
		title: {
			default: 'Saldo acumulado'
		},
		description: {
			default: (
				<>
					<span className="fs-6 fw-medium">
						Com os <b>serviços +</b> já
					</span>
				</>
			),
			wonText: {
				default: <b>Ganhou</b>
			}
		},
		btnText: {
			default: (
				<>
					Veja como resgatar <u style={{ cursor: 'pointer' }}>aqui</u>
				</>
			)
		},
		lastUpdated: {
			default: 'Última atualização: '
		},
		noBalanceDescription: {
			default: 'Maximize os benefícios do ecossistema Gecond: Junte-se à área Serviços+ e beneficie de vantagens exclusivas.'
		},
		modal: {
			title: {
				default: <>Como resgatar o seu saldo acumulado</>
			},
			default: (
				<>
					<div>Para resgatar este saldo, solicitamos que emita uma fatura com os seguintes detalhes:</div>
					<br />
					<ul>
						<li>Nome: Improxy Tecnologias, Lda</li>
						<li>NIF: 504097750</li>
						<li>Morada: Rua Dr. Ilídio Sardoeira, 28C, sala 1.1, 4400-107 V.N.Gaia</li>
						<li>Descritivo a colocar na fatura: "Utilização da área Serviços+"</li>
						<li>Valor: Ao valor indicado acima no saldo, acrescer IVA à taxa legal em vigor (23%)</li>
					</ul>
					<br />
					<div>
						Pedimos que envie estas informações para o e-mail{' '}
						<a className="text-decoration-none" href="mailto:financeiro@improxy.com">
							financeiro@improxy.com,
						</a>{' '}
						indicando o IBAN para o qual deseja receber o pagamento, e que anexe o comprovativo do mesmo.
					</div>
					<br />
					<div>
						Caso prefira poderá manter o saldo acumulado para resgatar posteriormente. Esta informação será atualizada mensalmente via email e estará em breve
						disponivel na sua área reservada dos Serviços+
					</div>
				</>
			)
		}
	}
};
