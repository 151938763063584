import { useReducer, useState } from 'react';

const initialInputState = {
	value: '',
	isTouched: false
};

export const updateFormControl = (formControl, name, value, isValid, hasError, hasErrorOnBlur, onChange, onBlur, reset) => {
	if (!formControl[name]) {
		formControl[name] = {
			value: value,
			isValid: isValid,
			hasError: hasError,
			hasErrorOnBlur: hasErrorOnBlur,
			onChange: onChange,
			onBlur: onBlur,
			reset: reset
		};
		if (!formControl.valid)
			formControl.valid = (handleBlur = true) => {
				let valid = true;
				for (let i in formControl) {
					if (typeof formControl[i] === 'object' && !formControl[i].isValid) {
						if (handleBlur) {
							formControl[i].onBlur();
							formControl[i].onChange(value);
						}
						valid = false;
					}
				}
				return valid;
			};

		if (!formControl.reset)
			formControl.reset = () => {
				for (let i in formControl) {
					if (typeof formControl[i] === 'object') formControl[i].reset();
				}
			};
	} else {
		formControl[name] = {
			...formControl[name],
			value: value,
			isValid: isValid,
			hasError: hasError,
			hasErrorOnBlur: hasErrorOnBlur
		};
	}
};

const inputStateReducer = (state, action) => {
	if (action.type === 'INPUT') {
		return { value: action.value, isTouched: state.isTouched };
	}
	if (action.type === 'BLUR') {
		return { isTouched: true, value: state.value };
	}
	if (action.type === 'RESET') {
		return { isTouched: false, value: '' };
	}
	return state;
};

const useInput = (validateValue, validateValueOnBlur) => {
	const [inputState, dispatch] = useReducer(inputStateReducer, initialInputState);
	const valueIsValid = validateValue(inputState.value);
	const hasError = !valueIsValid && inputState.isTouched;

	const [hasErrorOnBlur, setHasErrorOnBlur] = useState(false);

	const valueChangeHandler = event => {
		let eventValue;

		if (event && event.target) {
			switch (event.target.type) {
				case 'checkbox':
					eventValue = event.target.checked;
					break;
				case 'file':
					eventValue = event.target.files;
					break;
				default:
					eventValue = event.target.value;
					break;
			}
		} else {
			eventValue = event;
		}

		dispatch({ type: 'INPUT', value: eventValue });
	};

	const inputBlurHandler = async event => {
		if (validateValueOnBlur) {
			const valueIsValidOnBLur = await validateValueOnBlur(inputState.value);
			setHasErrorOnBlur(!valueIsValidOnBLur && inputState.isTouched);
		}
		dispatch({ type: 'BLUR' });
	};

	const reset = () => {
		dispatch({ type: 'RESET' });
	};

	return {
		value: inputState.value,
		isValid: valueIsValid,
		hasError,
		hasErrorOnBlur,
		valueChangeHandler,
		inputBlurHandler,
		reset
	};
};

export default useInput;
