// MODULES
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function CardMini({ content }) {
	return (
		<div className="card-blue">
			<Container>
				<h3 className="text-center">{content.title}</h3>
				<Row className="g-4 mt-4">
					{content.list.map((item, index) => (
						<Col key={index} className="card-blue--box">
							<div className="card-blue--box-background">
								{item.icon}
								<p className="fw-bold text-center">{item.desc}</p>
							</div>
						</Col>
					))}
				</Row>
			</Container>
		</div>
	);
}
