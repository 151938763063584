import React from 'react';
// MODULES
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// COMPONENTS
import PageHeader from '../../components/header/PageHeader';
import CardBorders from '../../components/card/CardBorders';
//CONTENT
import { content } from './content/resources';
import { SelectContent } from '../../content/resources';
import ContactRequest from '../../components/contactrequest/ContactRequest';
import LibaxLink from './components/LibaxLink';
// UTILS
import { serviceType } from '../../utils/Enums';

export default function Insurance({ userType }) {
	const InsuranceContent = SelectContent(content.insuranceContent, userType);

	return (
		<div className="insurance-main">
			<Container className="position-relative">
				<PageHeader
					subtitle={SelectContent(content.subtitle, userType)}
					title={SelectContent(content.title, userType)}
					desc={SelectContent(content.desc, userType)}
					icon={'/img/servicos-mais-seguros-icon.png'}
				/>
				<Row className="p-0 m-0">
					<Col className="p-0 m-0">
						<ContactRequest serviceTypeId={serviceType.INSURANCES} />
					</Col>
					<Col lg={7} className="d-flex justify-content-center align-items-end p-0 m-0">
						<LibaxLink />
					</Col>
				</Row>

				<Row className="g-4 my-5">
					{InsuranceContent.map((item, index) => (
						<Col id="insurances" key={index} xl="4" md="6" xs="12" className="card-main">
							<CardBorders content={item} />
						</Col>
					))}
				</Row>
			</Container>
		</div>
	);
}
