import React from 'react';
// MODULES
import Container from 'react-bootstrap/Container';
// COMPONENTS
import PageHeader from '../../components/header/PageHeader';
import CompanyMultiriskInsuranceForm from './components/form/CompanyMultiriskInsuranceForm';

export default function CompanyMultiriskInsurance() {
	return (
		<Container>
			<PageHeader subtitle={'Seguros +'} title={'Multirriscos empresas'} />
			<CompanyMultiriskInsuranceForm />
		</Container>
	);
}
