import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
// COMPONENTS
import PageHeader from '../../components/header/PageHeader';
import { LusopayInfo } from './components/LusopayInfo';
// CONTENT
import { content } from './content/resource';
import { SelectContent } from '../../content/resources';
// UTILS
import { serviceType, servicePartner } from '../../utils/Enums';

export const Lusopay = ({ userType }) => {
	const paymentData = SelectContent(content.paymentSection.payment, userType);

	return (
		<Container className="lusopay-info">
			<PageHeader
				logo={'/img/logo-lusopay.png'}
				title={SelectContent(content.title, userType)}
				desc={SelectContent(content.desc, userType)}
				img={'/img/lusopay-image.png'}
				buttons={[
					{
						label: 'ADERIR',
						link: 'https://subscribe.lusopay.com/subscribe_request.aspx?culture=pt-PT',
						log: {
							serviceTypeId: serviceType.OTHER,
							partnerId: servicePartner.LUSOPAY
						}
					}
				]}
			/>
			<Row>
				<Col>
					<h2>
						<strong>{SelectContent(content.details.title, userType)}</strong>
					</h2>
					<ul>
						{SelectContent(content.details.list, userType).map(item => (
							<li>{item}</li>
						))}
					</ul>
					<label>{SelectContent(content.details.desc, userType)}</label>
					<label className="mt-4">
						<strong>{SelectContent(content.details.descBold, userType)}</strong>
					</label>
				</Col>
			</Row>
			<Row className="pb-5 mt-5">
				<h2>{SelectContent(content.paymentSection.title, userType)}</h2>
				<label className="pb-4">{SelectContent(content.paymentSection.desc, userType)}</label>
				<label className="pb-4"></label>
				{paymentData.map((card, index) => (
					<Col md={12} lg={4} key={index} className="pb-3">
						<LusopayInfo icon={card.icon} title={card.title} description={card.description} isLastCard={index === paymentData.length - 1} />
					</Col>
				))}
			</Row>
		</Container>
	);
};
