export const isUndefined = obj => {
	return typeof obj == 'undefined';
};

export const isUndefinedOrNull = obj => {
	if (isUndefined(obj)) return true;
	return obj == null;
};

export const isStringVoid = obj => {
	if (isUndefinedOrNull(obj)) return true;
	if (typeof obj !== 'string') return true;
	return obj.length === 0;
};
