import React from 'react';
import { useState } from 'react';
// MODULES
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// CONTENT
import { content } from './content/resources';
import { SelectContent } from '../../content/resources';
// COMPONENTS
import PrivacyPolicy from './../../pages/privacypolicy/PrivacyPolicy';

export default function Footer({ userType }) {
	// STATE
	const [show, setShow] = useState(false);

	// METHODS
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<Container className="footer-main my-4">
			<Row xs={1} md={2}>
				<Col md={8}>
					<Button className="footer-main--link" onClick={handleShow}>
						{SelectContent(content.privacypolicy, userType)}
					</Button>
					<span> | </span>
					<a className="footer-main--link" href={SelectContent(content.urlTerms, userType)} target="_blank" rel="noreferrer">
						{SelectContent(content.terms, userType)}
					</a>
				</Col>
				<Col md={4} className="text-end">
					<span>{SelectContent(content.developBuy, userType)}</span>
					<a className="footer-main--link" href="https://www.improxy.com/" target="_blank" rel="noreferrer">
						Improxy
					</a>
				</Col>
			</Row>
			<Modal show={show} size="xl" scrollable onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{SelectContent(content.privacypolicy, userType)}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<PrivacyPolicy />
				</Modal.Body>
			</Modal>
		</Container>
	);
}
