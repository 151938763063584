import React from 'react';
// MODULES
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// CONTEXT
import { useUserData } from '../../context/UserContext';
// COMPONENTS
import PageHeader from '../../components/header/PageHeader';
// CONTENT
import ContactRequest from '../../components/contactrequest/ContactRequest';
import { content } from './content/resources';
import { SelectContent } from '../../content/resources';
// ACTIONS
import { save as saveLog } from '../../actions/LogRegister';
// UTILS
import { serviceOrigin, servicePartner, serviceType } from '../../utils/Enums';

export default function Nordstreet() {
	// CONTEXT
	const { state } = useUserData();

	// Log
	const saveFormLog = async () => {
		await saveLog({
			data: {
				id: 0,
				subsidiaryId: state.user.subsidiaryId,
				userId: state.user.id,
				originId: serviceOrigin.SERVICE_PLUS,
				startDate: new Date(),
				serviceTypeId: serviceType.OTHER,
				partnerId: servicePartner.NORDSTREET
			},
			success: data => {},
			error: error => {}
		});
	};

	return (
		<Container className="nordstreet-main">
			<PageHeader
				logo={'img/logo-nordstreet.jpg'}
				title={SelectContent(content.title, state.user.type)}
				desc={SelectContent(content.desc, state.user.type)}
				img={'/img/financiamento.png'}
			/>
			<ContactRequest serviceTypeId={serviceType.OTHER} partnerId={servicePartner.NORDSTREET} />

			<Row className="g-4 my-5">
				{SelectContent(content.steps, state.user.type).list.map((item, index) => (
					<Col key={index}>
						<div className="nordstreet-custom-card">
							{item.icon}
							<h4>{item.title}</h4>
							<p>{item.desc}</p>
						</div>
					</Col>
				))}
			</Row>
			<div className="d-flex justify-content-center">
				<a className="service-btn" href="https://forms.monday.com/forms/ad1ce175fbe9c9633850ae1aa2d514fd?r=euc1" onClick={saveFormLog} target="_blank" rel="noreferrer">
					{SelectContent(content.button, state.user.type)}
				</a>
			</div>
		</Container>
	);
}
