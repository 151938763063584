import { servicePartner } from '../../../utils/Enums';

export const content = {
	promo: {
		default: true,
		selfManagement: false
	},
	subtitle: {
		default: 'Eletricidade'
	},
	title: {
		default: (
			<>
				Mude de fornecedor de eletricidade e <span className="promo-text">ganhe 18€</span> por cada contador do condomínio
			</>
		),
		selfManagement: 'Renove o contrato de eletricidade'
	},
	desc: {
		default: (
			<p>
				Está a negociar os contratos de eletricidade de um dos seus condomínios? Mudar de fornecedor nunca foi tão simples como agora. Opte por uma solução de energia eficiente
				e financeiramente vantajosa.
				<br />
				<br />
				Subscreva um contrato de eletricidade com os nossos parceiros e <span className="highlight">ganhe 18€ por</span> contador.
			</p>
		),
		selfManagement: (
			<p>
				Está a negociar o contrato de eletricidade do seu condomínio? Mudar de fornecedor nunca foi tão simples como agora. Opte por uma solução de energia eficiente e
				financeiramente vantajosa.
				<br />
				<br />
				Subscreva um contrato de eletricidade com os nossos parceiros.
			</p>
		)
	},
	energyPartnersList: {
		default: [
			{
				img: '/img/logo-iberdrola.jpg',
				title: 'Iberdrola',
				desc: (
					<>
						Cada condomínio é diferente. <br />
						Planos de eletricidade com tarifa fixa competitiva que se adaptam ao consumo de cada condomínio. <br />
						Vantagens para potências iguais ou superiores a 3,45kVA e fidelização 12 meses.
					</>
				),
				link: '/CompanyEnergy',
				partner: servicePartner.IBERDROLA,
				files: [{ buttonTitle: 'TARIFÁRIOS E CONDIÇÕES', pdf: '/docs/iberdrola/MNEGOCIO241.pdf' }]
			}
			// {
			// 	img: '/img/logo-luzigas.jpg',
			// 	title: 'Luzigás',
			// 	desc: (
			// 		<>
			// 			Para uma gestão energética mais eficiente dos condomínios. <br />
			// 			Solução de eletricidade com tarifa indexada dedicada a condomínios. <br />
			// 			Livre de permanência.
			// 		</>
			// 	),
			// 	link: '/CompanyEnergy',
			// 	partner: servicePartner.LUZIGAS,
			// 	files: [{ buttonTitle: 'TARIFÁRIOS E CONDIÇÕES', pdf: '/docs/luzigas/Tarifas Luzigás Improxy.pdf' }]
			// }
		]
	}
};
