import Table from 'react-bootstrap/Table';

export default function PrivacyPolicy() {
	return (
		<div className="politica-privacidade-main">
			<h5>ACORDO DE SUBCONTRATAÇÃO DO TRATAMENTO DE DADOS PESSOAIS</h5>
			<p>
				Entre, CLIENTE, na qualidade de Responsável pelo Tratamento (doravante “CLIENTE” ou “Responsável pelo Tratamento”) e IMPROXY na qualidade de Subcontratante (doravante
				“IMPROXY” ou “Subcontratante”). O CLIENTE e a IMPROXY serão, por vezes, referidas em conjunto como as "Partes" ou cada uma individualmente como "Parte".
			</p>
			<h5>ACORDAM O SEGUINTE: </h5>
			<ol type="I">
				<h6>1. Objeto do Acordo e Prazo</h6>
				<li>
					O Subcontratante irá executar os serviços ao Responsável pelo Tratamento, conforme descrito no Apêndice 1. O Apêndice 1 especifica o objeto, tipo e finalidade
					do tratamento, os tipos de dados e as categorias de titulares dos dados.
				</li>
				<li>
					O presente Acordo será aplicável a partir do momento em que os Termos e Condições sejam subscritos pelo CLIENTE ou a partir do momento em que o CLIENTE utilize
					a plataforma de gestão Gecond, Gesar ou Gimob da Improxy.
				</li>
			</ol>
			<ol type="I">
				<h6>2. Instruções do Responsável pelo Tratamento</h6>
				<li>
					O Responsável pelo Tratamento deve assegurar o cumprimento das disposições relevantes em matéria de proteção de dados, em particular pela admissibilidade do
					tratamento dos dados e pela salvaguarda dos direitos legais dos titulares dos dados, previstos no Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho
					de 27 de abril de 2016 relativo à proteção das pessoas singulares no que diz respeito ao tratamento de dados pessoais e à livre circulação desses dados e que
					revoga a Diretiva 95/46/CE (“RGPD”).
				</li>
				<li>
					O Subcontratante trata os dados pessoais disponibilizados pelo Responsável pelo Tratamento apenas sob as instruções do Responsável pelo Tratamento, nomeadamente
					no âmbito do que seja estabelecido nos presentes Termos e Condições.{' '}
				</li>
				<li>
					A menos que o tratamento de determinados dados pessoais seja exigido pela legislação da União Europeia ou de um Estado-Membro às quais o Subcontratante esteja
					sujeito, o Subcontratante só deve tratar os dados sob as instruções do Responsável pelo Tratamento. Nessa sequência, o Subcontratante deve informar o
					Responsável pelo Tratamento de qualquer requisito legal antes de proceder com o tratamento, a menos que a lei aplicável proíba tal informação por motivos
					importantes de interesse público.{' '}
				</li>
				<li>Quaisquer instruções adicionais do Responsável pelo Tratamento devem ser dadas por escrito através do envio de um e-mail para comercial@improxy.com. </li>
				<li>
					Se o Subcontratante considerar que uma instrução dada pelo Responsável pelo Tratamento infringe as leis de proteção de dados, aquele deve informar o Responsável
					pelo Tratamento deste facto sem demora injustificada.{' '}
				</li>
			</ol>
			<ol type="I">
				<h6>3. Medidas Técnicas e Organizativas</h6>
				<li>
					O Subcontratante implementará as medidas e mecanismos de segurança previstos no artigo 32º do GDPR para: (i) garantir a permanente confidencialidade,
					integridade, disponibilidade e resiliência dos sistemas e serviços de tratamento; (ii) restaurar atempadamente a disponibilidade e acesso aos dados pessoais em
					caso de incidente físico ou técnico; (iii) verificar, apreciar e avaliar regularmente os mecanismos técnicos e organizativos implementados para garantir a
					segurança do tratamento; e (iv) a pseudonimização e cifragem dos dados pessoais, conforme necessário.{' '}
				</li>
				<li>
					O Subcontratante deve apoiar o Responsável pelo Tratamento no cumprimento de todas as obrigações legais no que diz respeito às medidas técnicas e organizativas.
					O Subcontratante deve cooperar para a elaboração de uma avaliação de impacto sobre a proteção de dados e, se necessário, na realização de consultas prévias às
					autoridades de controlo. Mediante pedido, o Subcontratante deverá disponibilizar as informações e documentos necessários ao Responsável pelo tratamento.{' '}
				</li>
			</ol>
			<ol type="I">
				<h6>4. Obrigações do Subcontratante</h6>
				<li>
					O Subcontratante confirma que está ciente da regulamentação relevante aplicável à proteção de dados. Os procedimentos operacionais internos do Subcontratante
					devem cumprir os requisitos específicos de uma gestão eficaz da proteção de dados.
				</li>
				<li>
					O Subcontratante garante e compromete-se a que todos os trabalhadores envolvidos nos procedimentos de tratamento de dados pessoais estão familiarizados com a
					regulamentação que seja relevante quanto à proteção de dados. O Subcontratante assegura que esses trabalhadores são obrigados a manter a confidencialidade, ou
					estão sujeitos a adequadas obrigações legais de confidencialidade. O Subcontratante controla o cumprimento da regulamentação aplicável em matéria de proteção de
					dados.
				</li>
				<li>
					O Subcontratante só pode aceder aos dados pessoais do Responsável pelo Tratamento quando tal seja necessário no âmbito da prestação do Serviço subscrito pelo
					Cliente.
				</li>
				<li>
					Na medida em que tal seja legalmente exigido, o Subcontratante nomeará um Encarregado da Proteção de Dados. Os dados de contacto do Encarregado da Proteção de
					Dados do Subcontratante devem ser partilhados com o Responsável pelo Tratamento, para efeitos de contacto direto.
				</li>
				<li>
					O Subcontratante só pode tratar dados pessoais que lhe sejam fornecidos exclusivamente no território Português ou, quando tal seja necessário, num Estado-Membro
					da União Europeia. O tratamento de dados pessoais num país terceiro requer a aprovação prévia explícita do Responsável pelo Tratamento e deve cumprir os
					requisitos legais aplicáveis.
				</li>
				<li>
					O Subcontratante apoia na medida do possível, o Responsável pelo Tratamento com medidas técnicas e organizacionais adequadas para assegurar que o Responsável
					pelo Tratamento consegue cumprir as suas obrigações de responder aos pedidos de exercício dos direitos dos titulares dos dados. Nesse sentido, o Subcontratante
					compromete-se a remeter para o e-mail indicado pelo Responsável pelo Tratamento sem demora injustificada e, em qualquer caso, o mais tardar sete dias úteis após
					a sua receção, quaisquer solicitações dos titulares dos dados pessoais que lhe sejam endereçadas.
				</li>
			</ol>
			<ol type="I">
				<h6>5. Legitimidade para celebrar um acordo de subcontratação subsequente</h6>
				<li>O Subcontratante encontra-se autorizado a designar subcontratantes subsequentes, constituindo a presente cláusula uma autorização geral para tal. </li>
				<li>
					Os subcontratantes subsequentes a que a Subcontratante recorre são os seguintes: Amazon Web Services EMEA SARL, Sucursal em Portugal (Avenida D. João II, 50,
					andar 4, Edificio Mar, Vermelho, Parque das Nações, 1990-095 Lisbon, Portugal e IVA: PT980596181); Microsoft Ireland Operations Limited (One Microsoft Place,
					South County Business Park, Leopardstown, Dublin 18 e VAT ID IE 8256796U); Almouroltec, Lda. (Rua Conselheiro Lopo Vaz 44ª, 1800-142 Lisboa, contribuinte n.º
					502665696). Qualquer alteração à referida lista, ainda que se encontre previamente autorizada, deverá ser comunicada ao Responsável pelo Tratamento.{' '}
				</li>
				<li>
					Uma relação será considerada como a de um Subcontratante subsequente quando o Subcontratante encarregar Subcontratantes subsequentes de parte ou da totalidade
					dos serviços acordados no presente contrato. Os serviços auxiliares que sejam prestados ao Subcontratante e em nome deste por terceiros prestadores de serviços
					e que estejam determinados a apoiar o Subcontratante na execução dos serviços designados, não serão considerados como Subcontratantes subsequentes na aceção do
					presente Acordo. Tais serviços podem incluir, por exemplo, a prestação de serviços de telecomunicações ou de gestão de instalações. Contudo, o Subcontratante é
					obrigado a garantir a proteção e a segurança dos dados do Responsável pelo Tratamento em relação a prestadores de serviços terceiros, e a assegurar a existência
					de acordos contratuais e medidas de supervisão adequadas e conformes com a lei.{' '}
				</li>
				<li>
					Um Subcontratante subsequente só pode ter acesso aos dados quando o Subcontratante tiver assegurado, através de um contrato escrito, que as disposições deste
					contrato são também vinculativas contra o Subcontratante subsequente, e, em particular, são fornecidas garantias adequadas de que as medidas técnicas e
					organizacionais adequadas são executadas para que o tratamento seja conforme com os regulamentos de proteção de dados.{' '}
				</li>
			</ol>
			<ol type="I">
				<h6>6. Direito de inspeção do Responsável pelo Tratamento</h6>O Subcontratante concorda que o Responsável pelo Tratamento ou uma pessoa por ele autorizada terá o
				direito de controlar o cumprimento das disposições de proteção de dados, na medida do necessário, nomeadamente solicitando informações e pedidos de documentos
				relevantes. O Responsável pelo Tratamento também terá o direito a realizar auditorias relativas às práticas de proteção de dados realizadas pelo Subcontratante. A
				realização de quaisquer auditorias deverá ser acordada em momento prévio à sua realização por ambas as Partes. A prova de um tratamento de dados adequado pode também
				ser fornecida por certificados apropriados e válidos para a segurança informática, desde que o objeto específico da certificação se aplique ao tratamento de dados
				encomendado no caso específico. Contudo, a apresentação de um certificado relevante não substitui o dever do Subcontratante de implementar as medidas de segurança
				referidas Cláusula 3 do presente Acordo.
			</ol>
			<ol type="I">
				<h6>7. Obrigação de comunicar violações de Proteção de Dados pelo Subcontratante</h6>O Subcontratante notificará sem demora o Responsável pelo Tratamento sobre qualquer
				perturbação no funcionamento que implique ameaça aos dados pessoais fornecidos pelo Responsável pelo Tratamento . O Subcontratante está ciente de que o Responsável pelo
				Tratamento é obrigado a documentar todas as violações da segurança dos dados pessoais e, se necessário, a informar a autoridade de controlo e/ou os titulares dos dados.
				O Subcontratante comunicará as violações ao Responsável pelo Tratamento sem demora indevida, e em qualquer caso, no prazo de 48 horas, e fornecerá, no mínimo, as
				seguintes informações:
				<li>Uma descrição da natureza da violação, as categorias e do número aproximado de titulares dos dados em causa e dos registos de dados pessoais em questão</li>
				<li>Nome e dados de contacto de uma pessoa de contacto para mais informações</li>
				<li>Uma descrição das prováveis consequências da violação</li>
				<li>Uma descrição das medidas tomadas para a reparação ou mitigação da violação</li>
			</ol>
			<ol type="I">
				<h6>8. Cessação da Subscrição</h6>
				<li>
					Caso a Subscrição termine o Subcontratante irá manter os dados pessoais conservados durante o período indicado na cláusula IV ponto 5. Findo esse prazo o
					Subcontratante irá proceder à eliminação de todos os dados pessoais, excetuando as seguintes situações:
				</li>
				<li>A conservação dos dados decorra de uma obrigação legal que impende sobre o Subcontratante;</li>
				<li>O Responsável pelo Tratamento indique, nos termos da Cláusula IV ponto 8, que pretende ativar a função de arquivo.</li>
			</ol>

			<Table bordered>
				<thead>
					<tr>
						<th colSpan={2}>Apêndice 1: Lista dos Serviços Contratados e detalhes de contacto dos Encarregados da Proteção de Dados </th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Objeto do tratamento</td>
						<td>Prestação de Serviço de utilização de software Gecond, Gesar e ou Gimob.</td>
					</tr>
					<tr>
						<td>Natureza e finalidade do Tratamento</td>
						<td>
							O tratamento, no âmbito dos serviços a serem prestados pelo Subcontratante, irá abranger as seguintes finalidades: Gestão de condomínios.
							Arrendamentos e/ou mediação imobiliária.
						</td>
					</tr>
					<tr>
						<td>Tipos de dados pessoais</td>
						<td>
							<p>Dados de contacto e identificação</p>
							<p>Dados contratuais</p>
							<p>Dados relacionados com circunstâncias pessoais</p>
							<p>Dados de pagamento e gestão de pagamentos</p>
							<p>Dados administrativos</p>
							<p>Dados fiscais</p>
						</td>
					</tr>
					<tr>
						<td>Categorias de titulares dos dados (indicar os titulares dos dados que sejam aplicáveis)</td>
						<td>Proprietários, inquilinos, condóminos, potenciais clientes, colaboradores e utilizadores.</td>
					</tr>
				</tbody>
			</Table>
		</div>
	);
}
