// ICONS
import { VscChecklist } from 'react-icons/vsc';
import { TbMailCheck, TbPrinter, TbSend } from 'react-icons/tb';
import { FaWifi } from 'react-icons/fa';
import { Col, Row } from 'react-bootstrap';
import WaveImg from '../Wave.png';
import WaveMobileImg from '../Wave-mobile.png';

export const content = {
	subtitle: {
		default: 'Vantagens'
	},
	title: {
		default: 'Ainda "anda aos papéis" com a correspondência registada?'
	},
	desc: {
		default: (
			<>
				<p className="fs-5 mb-2">Ainda vai entregar a correspondência ao posto dos correios?</p>
				<p className="fs-5 mb-3">Ainda perde tempo a imprimir e envelopar a sua correspondência?</p>
				<p>
					Deixe a correspondência nas mãos de quem sabe, sem perder o controlo de tudo. A operadora MP Serviços Postais (MPSP), proporciona-lhe duas soluções
					independentes, que se complementam: a expedição de correio e o arquivo digital dos registos.
				</p>
				<Row style={{ border: '.3rem solid transparent' }}>
					<Col
						md="12"
						lg="6"
						className="mp-campaign-left p-4"
						style={{
							backgroundColor: '#398ec7',
							color: 'fff',
							position: 'relative',
							borderRadius: '1em'
						}}
					>
						<p className="fw-bold fs-5 text-white">Campanha válida para adesões realizadas até 31 de maio de 2024</p>
						<p className="text-white">
							Ao aderir ao serviço, usufrui até <strong>5% de cash back</strong> em saldo Serviço+ durante 12 meses sobre o envio de correio registado
							(simples e com aviso de receção) em áreas distribuição MPSP.
						</p>
						<img className="wave-img" style={{ position: 'absolute', top: 0, height: '100.5%' }} src={WaveImg} alt="img" />
						<img className="wave-mobile-img" style={{ position: 'absolute', display: 'none' }} src={WaveMobileImg} alt="img" />
					</Col>

					<Col className="mp-campaign-right d-flex flex-column justify-content-center p-3" style={{ backgroundColor: '#EAF1F9', borderRadius: '1em' }}>
						<div className="d-flex align-items-center" style={{ transform: 'translateX(-22px)' }}>
							<span
								className="d-flex align-content-center fw-bold p-2 text-white border-white border-5 fs-5"
								style={{ backgroundColor: '#41B14D', border: '1px solid', transform: 'translateX(30px)' }}
							>
								2%
							</span>
							<p
								className="text-white mb-0"
								style={{ backgroundColor: '#41B14D', border: '1px solid', borderRadius: '.5em', padding: '1.2em 1em 1.2em 3em' }}
							>
								na adesão ao serviço expedição e distribuição de correio.
							</p>
						</div>

						<div className="d-flex align-items-center mt-3" style={{ transform: 'translateX(-22px)' }}>
							<span
								className="d-flex align-content-center fw-bold p-2 text-white border-white border-5 fs-5"
								style={{ backgroundColor: '#41B14D', border: '1px solid', transform: 'translateX(30px)' }}
							>
								3%
							</span>
							<p className="text-white mb-0" style={{ backgroundColor: '#41B14D', borderRadius: '.5em', padding: '1.2em 1em 1.2em 3em' }}>
								adicional se aderir ao serviço Arquivo Digital MPSP.
							</p>
						</div>
					</Col>
				</Row>
			</>
		)
	},
	services: {
		default: {
			title: 'Serviços',
			list: [
				{
					icon: <TbMailCheck />,
					desc: 'Receção da correspondência a tratar'
				},
				{
					icon: <TbPrinter />,
					desc: 'Impressão e envelopagem'
				},
				{
					icon: <TbSend />,
					desc: 'Expedição normal, registada e com aviso de receção'
				},
				{
					icon: <VscChecklist />,
					desc: 'Track & Trace: rastreamento de todos os envios'
				},
				{
					icon: <FaWifi />,
					desc: (
						<span className="d-flex align-items-center flex-column">
							Arquivo digital online
							<strong className="d-inline-block text-white rounded-3 mt-2 w-50" style={{ padding: '.5em', backgroundColor: '#2db24a' }}>
								NOVO
							</strong>
						</span>
					)
				}
			]
		}
	},
	servicesDescription: {
		default: {
			title: (
				<h3 style={{ color: '#6195bc' }} className="fs-5 text-left">
					Expedição e Distribuição do Correio
				</h3>
			),
			servicesList: (
				<>
					<p>Sem sair da plataforma Gecond, este serviço permite-lhe</p>
					<ul>
						<li>Enviar a correspondência em lote</li>
						<li>Preencher automaticamente os códigos do registo de receção das convocatórias e atas;</li>
						<li>Acompanhar o estado dos registos.</li>
					</ul>
					<p>Sendo operadora licenciada em serviços postais pela ANACOM, a MP – Serviços Postais trata de todas as fases da correspondência: </p>
					<ul className="mb-4">
						<li>Receção da correspondência a tratar através de meios eletrónicos.</li>
						<li>Impressão e envelopagem da correspondência.</li>
						<li>Expedição normal, registada e com aviso de receção.</li>
						<li>Track & Trace: serviço de seguimento e localização de todos os envios.</li>
					</ul>
					<div className="d-flex flex-row align-items-center">
						<h3 style={{ color: '#6195bc' }} className="fs-5 text-left">
							Arquivo Digital de Documentos
						</h3>
						<strong className="text-white rounded-3 my-3" style={{ padding: '.5em', backgroundColor: '#2db24a', marginLeft: '.8em' }}>
							NOVO
						</strong>
					</div>

					<p>Acesso online das guias de registo e avisos de receção digitalizados </p>
					<ul>
						<li>Guias de Registo Coletivo do envio de Convocatórias e Atas </li>
						<li>Avisos de Receção assinados e carimbados.</li>
					</ul>
					<p>
						Pode visualizar ou fazer o download e estão classificados por edifício. A edição ou eliminação dos documentos está bloqueada. O serviço está alojado em
						servidor seguro com múltiplos níveis de segurança.
					</p>
					<p>
						As Guias de Registo Coletivo ficam disponíveis 48 a 72 horas depois do envio. Os AR´s ficam disponíveis 48 a 72 horas depois da entrega do envio ao
						destinatário.
					</p>
				</>
			)
		}
	},
	benefit: {
		default: {
			title: 'Vantagens',
			benefitList: [
				{
					title: 'Rapidez',
					desc: 'O que antes demorava horas ou dias, dependendo do volume da correspondência, agora poderá fazer em minutos.'
				},
				{
					title: 'Maior comodidade',
					desc: 'Envio da correspondência sem sair da plataforma.'
				},
				{
					title: 'Maior rentabilidade',
					desc: 'Processos mais ágeis, mais tempo disponível.'
				},
				{
					title: 'Sem filas de espera',
					desc: 'Basta enviar eletronicamente a ordem de expedição, nos moldes que desejar e a correspondência é processada para expedição.'
				},
				{
					title: 'Personalização da faturação',
					desc: 'À sua empresa ou aos condomínios respetivos.'
				},
				{
					title: 'Códigos do registo',
					desc: 'Preenchimento automático pelo sistema e área de consulta do seu estado.'
				},
				{
					title: 'Online, disponível 24/7',
					desc: 'Consulta online do arquivo das guias e registos organizados por edifício.'
				},
				{
					title: 'Impressão e Envelopagem',
					desc: (
						<>
							<label>Realizados pelo parceiro a custos reduzidos:</label>
							<br />
							<label>Envelopagem: 0,07€</label>
							<br />
							<label>Impressão p/página: 0,03€</label>
						</>
					)
				}
			]
		}
	}
};
