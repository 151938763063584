// ICONS
import { LiaToolsSolid } from 'react-icons/lia';
import { TbReportSearch, TbClipboardText } from 'react-icons/tb';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';

export const content = {
	title: {
		default: 'Uma avaria elétrica, uma porta estroncada? Obras que têm de avançar, o jardim para tratar?'
	},
	desc: {
		default: (
			<>
				<p className="fw-bold pDark">O PortalPRO tem os técnicos especializados. Basta escolher.</p>
				<p>Problema resolvido com o PortalPRO. Peça orçamentos de forma rápida e contrate técnicos. Basta dizer o que procura no Gecond. O PortalPRO faz o resto.</p>
				<p>
					Nem sempre é fácil contar com o técnico certo e pelo melhor orçamento. O Gecond resolve o problema com a integração do PortalPRO, onde pode pedir orçamentos de
					forma rápida e consultar o mercado.
				</p>
				<p>
					Este serviço está a ser gradualmente disponibilizado a todos os clientes Gecond e encontra-se disponível nas regiões de{' '}
					<span className="highlight-portalpro">
						<strong>Braga, Porto, Lisboa e Setúbal</strong>
					</span>
					. Brevemente será alargado a mais zonas.
				</p>
			</>
		)
	},
	steps: {
		default: {
			title: 'O que pode fazer?',
			list: [
				{
					icon: <LiaToolsSolid />,
					desc: 'Encontrar técnicos especializados, na proximidade'
				},
				{
					icon: <TbReportSearch />,
					desc: 'Recolher e comparar orçamentos'
				},
				{
					icon: <IoMdCheckmarkCircleOutline />,
					desc: 'Adjudicar o serviço'
				},
				{
					icon: <TbClipboardText />,
					desc: 'Fazer a gestão de todas as intervenções, internas e externas, no mesmo sítio'
				}
			]
		}
	},
	bottomSection: {
		title: {
			default: 'Uma parceria internacional que lhe dá múltiplas soluções'
		},
		desc: {
			default: (
				<>
					Reconhecido no mercado europeu, o PortalPRO chega a Portugal fruto da parceria com a Improxy. Para os clientes Gecond, representa o acesso exclusivo, totalmente
					gratuito, a um serviço de resposta multidisciplinar.
					<br />
					<br />
					PortalPRO é uma plataforma que liga gestores do setor imobiliário a empresas e profissionais independentes dos mais diversos ofícios de construção e manutenção.
				</>
			)
		}
	}
};
