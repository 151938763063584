export const renderTableHead = title =>
	`<head>
		<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
		<meta name="viewport" content="width=device-width, initial-scale=1" />
		<meta http-equiv="X-UA-Compatible" content="IE=edge" />
		<title>${title}</title>
		<style type='text/css'>
		  /* Outlines the grids, remove when sending */
		  /* table td { border: 1px solid #F5F5F5; } */
		  /* CLIENT-SPECIFIC STYLES */
		  body,
		  table,
		  td,
		  a {
			-webkit-text-size-adjust: 100%;
			-ms-text-size-adjust: 100%;
		  }
	
		  table,
		  td {
			mso-table-lspace: 0pt;
			mso-table-rspace: 0pt;
		  }
	
		  img {
			-ms-interpolation-mode: bicubic;
		  }
	
		  /* RESET STYLES */
		  img {
			border: 0;
			outline: none;
			text-decoration: none;
		  }
	
		  table {
			border-collapse: collapse !important;
		  }
	
		  body {
			margin: 0 !important;
			padding: 0 !important;
			width: 100% !important;
			font-family: 'Century Gothic', 'Verdana', 'Tahoma';
			font-size: 14px;
		  }
	
		  /* iOS BLUE LINKS */
		  a[x-apple-data-detectors] {
			color: inherit !important;
			text-decoration: none !important;
			font-size: inherit !important;
			font-family: inherit !important;
			font-weight: inherit !important;
			line-height: inherit !important;
		  }
	
		  /* ANDROID CENTER FIX */
		  div[style*='margin: 16px 0;'] {
			margin: 0 !important;
		  }
	
		  /* EMAIL LINKS FIX */
		  font {
			display: none;
		  }
		</style>
	</head>`;

const renderTableSeparator = () =>
	`<tr>
		<td height='15' style='font-size: 10px; line-height: 10px'>&nbsp;</td>
	</tr>`;

export const renderTableTitle = title =>
	`<table width='640' cellpadding='0' cellspacing='0' border='0' class='wrapper' bgcolor='#FFFFFF' style='background: #ffffff'>
		<tr>
			<td align='center' valign='top'>
				<table width='600' cellpadding='0' cellspacing='0' border='0' class='container'>
					<tr>
						<td height='15' style='font-size: 10px; line-height: 10px'>&nbsp;</td>
					</tr>
					<tr>
						<td align='center' valign='top'>
							<span style='display: block; font-size: 32px'>${title}</span>
						</td>
					</tr>
					<tr>
						<td height='15' style='font-size: 10px; line-height: 10px'>&nbsp;</td>
					</tr>
				</table>
			</td>
		</tr>
	</table>`;

export const renderTableIntrodution = text =>
	`<table width='640' cellpadding='0' cellspacing='0' border='0' class='wrapper' bgcolor='#FFFFFF' style='background: #ffffff'>
		${renderTableSeparator()}
		<tr>
			<td width='600' align='center' valign='top'>
				<table width='600' cellpadding='0' cellspacing='0' border='0' class='container'>
					<tr>
						<td width='600' align='left' valign='top'>
							<p>${text}</p>
						</td>
					</tr>
				</table>
			</td>
		</tr>
		${renderTableSeparator()}
  </table>`;

export const renderTableSectionTitle = title =>
	`<tr>
		<td>
			<table width='640' cellpadding='0' cellspacing='0' border='0' class='wrapper' bgcolor='#FFFFFF' style='background: #ffffff'>
			${renderTableSeparator()}
				<tr>
					<td width='600' align='center' valign='top'>
						<table width='600' cellpadding='0' cellspacing='0' border='0' class='container'>
							<tr>
								<td width='600' align='left' valign='top'>
									<span style='display: block; font-size: 16px; font-weight: bold'>${title}</span>
								</td>
							</tr>
						</table>
					</td>
				</tr>
				${renderTableSeparator()}
			</table>
		</td>
	</tr>`;

export const renderTableFooter = subsidiaryName =>
	`<table width='640' cellpadding='0' cellspacing='0' border='0' class='wrapper' bgcolor='#474a59' style='background: #474a59'>
		${renderTableSeparator()}
		<tr>
			<td width='600' align='center' valign='top'>
				<table width='600' cellpadding='0' cellspacing='0' border='0' class='container'>
					<tr>
						<td align='center' valign='top' style='color: #ffffff; font-size: 12px'>
							<p>
								<strong><span style='font-size: 16px'>${subsidiaryName}</span></strong>
								<br />Todos os direitos reservados <br />Enviado a ${new Date().toLocaleDateString()} <br />
							</p>
						</td>
					</tr>
				</table>
			</td>
		</tr>
		${renderTableSeparator()}
	</table>`;

export const renderTableRow = (label, value, extraChar = '') =>
	value
		? `<table width='600' cellpadding='0' cellspacing='0' border='0' class='container'>
		<tr>
			<td width='150' class='mobile' align='right' valign='top'>${label}:</td>
			<td width='25' class='mobile' align='center' valign='top'></td>
			<td width='425' class='mobile' align='left' valign='top'>${value} ${extraChar}</td>
		</tr>
		<tr>
			<td height='10' style='font-size: 10px; line-height: 10px'>&nbsp;</td>
		</tr>
	</table>`
		: '';
