export const content = {
	title: {
		default: 'Saldos'
	},
	subtitle: {
		default: 'Serviços +'
	},
	desc: {
		default: 'Nesta página irá ser possível consultar o valor ganho com as vantagens dos Serviços +, acompanhar o total acumulado, carregar e consultar o estado das faturas emitidas e recebimentos.'
	},
	info: {
		title: {
			default: 'Prezado cliente,'
		},
		default: (
			<>
				<br />
				<div>Enviamos em anexo os contratos efetuados para sua verificação.</div>
				<br />
				<div>Caso pretenda resgatar este valor, necessitamos que emita fatura com os seguintes dados:</div>
				<br />
				<ul>
					<li>Nome: Improxy Tecnologias, Lda</li>
					<li>NIF: 504097750</li>
					<li>Morada: Rua Dr. Ilídio Sardoeira, 28C, sala 1.1, 4400-107 V.N.Gaia</li>
					<li>Descritivo da fatura: Bonificação por utilização dos Serviços+</li>
					<li>
						Valor: deverá ser o valor que lhe indicamos acima no saldo <strong>acrescido de IVA a taxa legal em vigor (23%) </strong>
					</li>
				</ul>
				<br />
				<div>
					Deverá enviar esta informação para o email{' '}
					<a className="text-decoration-none" href="mailto:parcerias@gecond.com">
						parcerias@gecond.com
					</a>{' '}
					indicando o IBAN para o qual deseja que seja feito o pagamento e anexar comprovativo do mesmo
				</div>
			</>
		)
	}
};
