// FONTAWESOME
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { TbClipboardText, TbReportSearch } from 'react-icons/tb';

export const content = {
	title: {
		default: 'Um dos condomínios precisa de obras urgentes de conservação e não tem como pagar?'
	},
	desc: {
		default: (
			<>
				<p>Este é um dos maiores problemas que pode enfrentar num condomínio. Conseguir o valor necessário demora tempo e cria desconforto à administração.</p>
				<p className="fw-bold pDark my-3">Nós temos a solução.</p>
				<h4 className="mt-4">Financiamento de obras no condomínio com um prazo de pagamento alargado.</h4>
				<p className="fw-bold pDark my-3">Sem garantias solidárias. Sem hipotecas.</p>
				<p>
					Em parceria com a Nordstreet, uma empresa financeira internacional, apresentamos uma solução de financiamento de projetos de conservação ou renovação de
					edifícios residenciais em condomínio através de empréstimos até 50.000€ e com um prazo alargado até 24 meses.
				</p>
				<p className="fw-bold pDark my-3">Candidate-se e receba confirmação da candidatura em 5 dias úteis.</p>
			</>
		)
	},
	steps: {
		default: {
			list: [
				{
					icon: <TbClipboardText />,
					title: '1 - Pedido',
					desc: 'Preencha o formulário de pedido de financiamento para as obras do condomínio'
				},
				{
					icon: <TbReportSearch />,
					title: '2 - Avaliação',
					desc: 'A Nordstreet irá analisar os dados e apresentar a proposta de financiamento'
				},
				{
					icon: <IoMdCheckmarkCircleOutline />,
					title: '3 - Aprovação',
					desc: 'O projeto é submetido e depois de estar totalmente financiado, será assinado o contrato'
				}
			]
		}
	},
	button: {
		default: 'Quero candidatar-me'
	}
};
