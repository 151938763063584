import React from 'react';
// CONTEXT
import { useUserData } from '../../context/UserContext';
// MODULES
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// UTILS
import { serviceOrigin } from '../../utils/Enums';
// ACTIONS
import { save as saveLog } from '../../actions/LogRegister';

export default function PageHeader({ subtitle, logo, title, desc, img, icon, buttons, promo, marginsAndBorders = 'my-5 gx-5' }) {
	// CONTEXT
	const { state } = useUserData();

	// CONSTANTS
	const log = {
		id: 0,
		subsidiaryId: state.user.subsidiaryId,
		userId: state.user.id,
		originId: serviceOrigin.SERVICE_PLUS,
		startDate: new Date()
	};

	// METHODS
	const saveFormLog = async btn => {
		if (btn.log)
			await saveLog({
				data: { ...log, ...btn.log },
				success: data => {},
				error: error => {}
			});
	};

	const renderButtons = () => {
		return (
			<div className="d-flex justify-content-center">
				{buttons.map((btn, index) => {
					return (
						<a key={index} href={btn.link} onClick={() => saveFormLog(btn)} className="service-btn" target="_blank" rel="noreferrer">
							{btn.label}
						</a>
					);
				})}
			</div>
		);
	};

	return (
		<Row className={`page-header ${marginsAndBorders}`}>
			<Col>
				<img className="page-header-logo" src={logo} alt={logo} />
				{subtitle && <h6>{subtitle}</h6>}
				<h2>{title}</h2>
				<div>{desc}</div>
				{buttons && buttons.length > 0 && renderButtons()}
			</Col>
			{img && (
				<Col md={4} className="title-img">
					<img src={img} alt={subtitle} />
				</Col>
			)}
			{icon && (
				<Col md={3} className={`${promo ? 'page-header--img-promo' : ''} title-icon`}>
					<img src={icon} alt={subtitle} />
				</Col>
			)}
		</Row>
	);
}
