import React from 'react';
// MODULES
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

export default function Loading({ label }) {
	return (
		<div className="loading-wrapper">
			<Row className="loading--content">
				<Col xs="auto">
					<Spinner animation="border" role="status">
						<span className="visually-hidden"></span>
					</Spinner>
					<h6>{label}</h6>
				</Col>
			</Row>
		</div>
	);
}
