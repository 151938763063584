// MODULES
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
//COMPONENTE
import PageHeader from '../../components/header/PageHeader';
import CardMini from '../../components/card/CardMini';
// CONTENT
import { content } from './content/resources';
import { SelectContent } from '../../content/resources';
// UTILS
import { serviceType, servicePartner } from '../../utils/Enums';

export default function PortalPro({ userType }) {
	return (
		<>
			<Container className="portalPro-main page-bubble-main">
				<PageHeader
					logo={'img/portal-pro.png'}
					title={SelectContent(content.title, userType)}
					btn={false}
					desc={SelectContent(content.desc, userType)}
					img={'img/portalpro.png'}
					buttons={[
						{
							label: 'ADERIR',
							link: 'https://portalpro.pt/empresas/',
							log: {
								serviceTypeId: serviceType.OTHER,
								partnerId: servicePartner.PORTALPRO
							}
						}
					]}
				/>
			</Container>
			<CardMini content={SelectContent(content.steps, userType)} />
			<Container>
				<Row className="page-header my-5">
					<Col md={3} className="title-icon">
						<img src="img/servicos-mais-financiamento-icon.png" alt="Parceria internacional" />
					</Col>
					<Col>
						<h2>{SelectContent(content.bottomSection.title, userType)}</h2>
						<p>{SelectContent(content.bottomSection.desc, userType)}</p>
					</Col>
				</Row>
			</Container>
		</>
	);
}
