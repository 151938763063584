import React, { useEffect } from 'react';
// MODULES
import Col from 'react-bootstrap/Col';
import _ from 'lodash';

const RadioInput = ({ name, value, label, checked, onChange }) => {
	const inputElement = React.useRef(null);
	const labelElement = React.useRef(null);

	useEffect(() => {
		const id = _.uniqueId('_');
		inputElement.current.id = name + value + id;
		labelElement.current.htmlFor = name + value + id;
	}, [name, value]);

	return (
		<Col xs="auto">
			<div className="d-flex me-3">
				<input type="radio" name={name} value={value} checked={checked} onChange={onChange} className="form-check-input" ref={inputElement} />
				<label ref={labelElement} className={`ps-2`}>
					{label}
				</label>
			</div>
		</Col>
	);
};

export default RadioInput;
