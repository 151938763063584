// ACTIONS
import { validateVatNumber } from '../../../actions/Validations';
// UTILS
import { isUndefinedOrNull } from '../../../utils/Utils';

export const isNotEmpty = value => (value ? true : false);

export const isNotNullOrUndefined = value => !isUndefinedOrNull(value);

export const isNotNullOrUndefinedOrEmpty = value => isNotEmpty(value) && isNotNullOrUndefined(value);

export const isNumber = value => {
	if (value.trim() === '') return false;
	return !isNaN(value);
};

export const isEmail = value => value.includes('@');

export const isChecked = value => (value ? true : false);

export const isNotEmptyFile = value => (value && value.length > 0 ? true : false);

export const isNIPCValid = async nipc => {
	let isValid = true;

	if (nipc) {
		await validateVatNumber({
			value: nipc,
			success: data => {
				isValid = true;
			},
			error: error => {
				isValid = false;
			}
		});

		return isValid;
	} else {
		return isValid;
	}
};
