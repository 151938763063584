import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// CONTEXT
import { useUserData } from './context/UserContext';
// STYLES
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/style.scss';
// COMPONENTS
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Balance from './pages/balance/Balance';
import Cloudware from './pages/cloudware/Cloudware';
import Energy from './pages/energy/Energy';
import Home from './pages/home/Home';
import CompanyMultiriskInsurance from './pages/insurance/CompanyMultiriskInsurance';
import CondominiumMultiriskInsurance from './pages/insurance/CondominiumMultiriskInsurance';
import Insurance from './pages/insurance/Insurance';
import WorkplaceAccident from './pages/insurance/WorkplaceAccident';
import HealthInsurance from './pages/insurance/HealthInsurance';
import ProfessionalCivilLiabilityInsurance from './pages/insurance/ProfessionalCivilLiabilityInsurance';
import MP from './pages/mp/MP';
import OtherPartnerships from './pages/otherpartnerships/OtherPartnerships';
import PortalPro from './pages/portalpro/PortalPro';
import Libax from './pages/libax/Libax';
import CertifiedEmails from './pages/certifiedemails/CertifiedEmails';
// ACTIONS
import ScrollToTop from './components/scrolltop/ScrollTop';
import { Lusopay } from './pages/lusopay/Lusopay';
import Nordstreet from './pages/nordstreet/Nordstreet';
import { Saldos } from './pages/saldos/Saldos';

function App() {
	// CONTEXT
	const { state } = useUserData();

	return (
		<div className="App">
			{state.user && state.user.id && (
				<BrowserRouter>
					<Header />
					<ScrollToTop>
						<Routes>
							<Route path="/" element={<Home userType={state.user.type} />} />
							<Route path="/Insurance" element={<Insurance userType={state.user.type} />} />
							<Route path="/CondominiumMultiriskInsurance" element={<CondominiumMultiriskInsurance />} />
							<Route path="/CompanyMultiriskInsurance" element={<CompanyMultiriskInsurance />} />
							<Route path="/WorkplaceAccident" element={<WorkplaceAccident />} />
							<Route path="/HealthInsurance" element={<HealthInsurance />} />
							<Route path="/ProfessionalCivilLiabilityInsurance" element={<ProfessionalCivilLiabilityInsurance />} />
							<Route path="/Energy" element={<Energy userType={state.user.type} />} />
							<Route path="/MP" element={<MP userType={state.user.type} />} />
							<Route path="/Cloudware" element={<Cloudware userType={state.user.type} />} />
							<Route path="/Nordstreet" element={<Nordstreet />} />
							<Route path="/Balance" element={<Balance />} />
							<Route path="/PortalPro" element={<PortalPro userType={state.user.type} />} />
							<Route path="/OtherPartnerships" element={<OtherPartnerships />} />
							<Route path="/Lusopay" element={<Lusopay userType={state.user.type} />} />
							<Route path="/Saldos" element={<Saldos userType={state.user.type} />} />
							<Route path="/Libax" element={<Libax userType={state.user.type} />} />
							<Route path="/CertifiedEmails" element={<CertifiedEmails userType={state.user.type} />} />
						</Routes>
					</ScrollToTop>
					<Footer userType={state.user.type} />
				</BrowserRouter>
			)}
		</div>
	);
}

export default App;
