// MODULES
import { Container } from 'react-bootstrap';
// COMPONENTS
import PageHeader from '../../components/header/PageHeader';

export default function OtherPartnerships() {
	return (
		<Container className="partnerships-main">
			<PageHeader
				subtitle={'Outras parcerias'}
				title={'Está a renovar um dos seguros dos seus condomínios? Ganhou um novo condomínio recentemente?'}
				desc={'Garanta a proteção dos seus condomínios e da sua empresa com soluções de seguros dedicadas ao setor de gestão de condomínios. Experimente connosco'}
			/>
		</Container>
	);
}
