import React from 'react';
// Components
import { Col, Container, Row } from 'react-bootstrap';
import PageHeader from '../../components/header/PageHeader';
// CONTENT
import { content } from './content/resources';
import { SelectContent } from '../../content/resources';
// UTILS
import { userTypes } from '../../utils/Enums';

const CertifiedEmails = () => {
	return (
		<div className="certifiedemails-main">
			<Container>
				<PageHeader
					logo={'/img/services_logo.png'}
					title={SelectContent(content.title, userTypes)}
					desc={SelectContent(content.desc, userTypes)}
					img={'/img/lusopay-image.png'}
					buttons={[
						{
							label: 'QUERO SER CONTACTADO',
							link: 'https://www.google.pt',
							log: {}
						}
					]}
				/>
				<Row>
					<Col>{SelectContent(content.services, userTypes)}</Col>
				</Row>
				<div>{SelectContent(content.information, userTypes)}</div>
			</Container>
			<div className="card-blue">
				<Container>
					<Row className="mx-3 text-center">
						<h3>{SelectContent(content.benefit, userTypes).title}</h3>
					</Row>
					<Row className="mt-2 mb-2 g-4 certified-emails-advantage-card" md={2}>
						{SelectContent(content.benefit, userTypes).benefitList.map((item, index) => (
							<Col key={index}>
								<div className="certified-emails-advantage-card--wrapper">
									<h4>{item.title}</h4>
									<p>{item.desc}</p>
								</div>
							</Col>
						))}
					</Row>
				</Container>
			</div>
			<Container>
				<Row>
					<Col className="d-flex flex-column align-content-center mt-5" lg="6">
						{SelectContent(content.costs, userTypes)}
					</Col>
					<Col lg="6">
						<table className="certified-emails-table mt-4">{SelectContent(content.table, userTypes)}</table>
					</Col>
				</Row>
				<div className="mt-5">
					<h3 className="text-center m-5">{SelectContent(content.faq, userTypes).title}</h3>
					{SelectContent(content.faq, userTypes).questionsList.map((item, index) => (
						<Col key={index}>
							<div className="">
								<h4>{item.title}</h4>
								<p>{item.desc}</p>
							</div>
						</Col>
					))}
				</div>
			</Container>
		</div>
	);
};

export default CertifiedEmails;
