import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { FaInfoCircle } from 'react-icons/fa';

export default function TooltipPopover({ element, placement = 'top', trigger = ['hover', 'focus'], popoverheader = '', popoverbody = '', size = 'normal', tooltipclass = 'ms-1' }) {
	return (
		<OverlayTrigger
			trigger={trigger}
			key={placement}
			placement={placement}
			overlay={
				<Popover id={`popover-positioned-${placement}`} className={size}>
					<Popover.Header as="h3">{popoverheader}</Popover.Header>
					<Popover.Body>{popoverbody}</Popover.Body>
				</Popover>
			}
		>
			{element ? (
				element
			) : (
				<span className={`tooltippopover ${tooltipclass}`}>
					<FaInfoCircle />
				</span>
			)}
		</OverlayTrigger>
	);
}
