import React, { useEffect, useState } from 'react';
// MODULES
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// COMPONENTS
import { Modal } from 'react-bootstrap';
import Loading from '../../../components/loading/Loading';
// ACTIONS
import { configurationDetails } from '../../../actions/Configurations';
// UTILS
import * as Utils from '../../../utils/Utils';
import { userTypes } from '../../../utils/Enums';
// RESOURCES
import { AES } from 'crypto-js';
import { SelectContent } from '../../../content/resources';
import { content } from '../content/resources';
import { useNavigate } from 'react-router-dom';

export default function LibaxLink() {
	// STATE
	const [isLoading, setIsLoading] = useState(false);
	const [valueConfig, setValueConfig] = useState(null);
	const [showModal, setShowModal] = useState(false);

	// USE EFFECT
	useEffect(() => {
		getConfigurationDetail();
	}, []);

	// METHODS
	const navigate = useNavigate();

	const handleOpenModal = () => {
		setShowModal(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};

	const getConfigurationDetail = async () => {
		setIsLoading(true);

		await configurationDetails({
			success: configs => {
				const valueConfig = JSON.parse(configs[0].value);
				setValueConfig(valueConfig);

				const userId = valueConfig.userId;
				const hash = valueConfig.hash;
				const libaxPage = '/Libax';

				const encryptionKey = 'Libax Platform Page';
				const encryptedPage = AES.encrypt(libaxPage, encryptionKey).toString();

				const urlWithCredentials = `https://id.libax.com/api/account/user-token?userId=${userId}&context=0&hash=${hash}&returnUrl=${encryptedPage}`;

				if (!Utils.isUndefinedOrNull(valueConfig) && valueConfig !== '') {
					const iframe = document.createElement('iframe');
					iframe.style.display = 'none';
					document.body.appendChild(iframe);

					iframe.src = urlWithCredentials;
				}
				setIsLoading(false);
			},
			error: error => {
				setIsLoading(false);
			}
		});
	};

	const handleLibaxNavigation = event => {
		event.preventDefault();

		getConfigurationDetail();

		if (!Utils.isUndefinedOrNull(valueConfig) && valueConfig !== '') {
			setTimeout(() => {
				navigate('/Libax');
			}, 500);
		} else {
			handleOpenModal();
		}
	};

	return (
		<>
			<Row className="my-4">
				<Col>
					<h2 className="mb-3">{SelectContent(content.libaxPlatform, userTypes)}</h2>
					<p style={{ color: '#398ec7' }}>{SelectContent(content.libaxPlatformDescription, userTypes)}</p>
					<Button className="service-btn mt-0 ms-0" onClick={handleLibaxNavigation}>
						Ver apólices
					</Button>
				</Col>
				<Modal className="mt-5" size="lg" show={showModal} onHide={handleCloseModal}>
					<Modal.Header style={{ padding: '1.25rem 1.5rem' }} closeButton>
						{SelectContent(content.modalTitle, userTypes)}
					</Modal.Header>
					<Modal.Body style={{ padding: '1.25rem 1.5rem' }}>{SelectContent(content.modalDescription, userTypes)}</Modal.Body>
					<Modal.Footer>
						<a href="#insurances" onClick={handleCloseModal}>
							<Button className="service-btn">Pedir cotação</Button>
						</a>
					</Modal.Footer>
				</Modal>
				{isLoading && <Loading label={'A carregar...'} />}
			</Row>
		</>
	);
}
