import React from 'react';
// MODULES
import Container from 'react-bootstrap/Container';
// COMPONENTS
import PageHeader from '../../components/header/PageHeader';
import HealthInsuranceForm from './components/form/HealthInsuranceForm';

export default function HealthInsurance() {
	// STATE

	return (
		<Container>
			<PageHeader subtitle={'Seguros +'} title={'Seguro saúde'} />
			<HealthInsuranceForm />
		</Container>
	);
}
