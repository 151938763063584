import React, { useState, useEffect, useCallback } from 'react';
// MODULES
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
// CONTEXT
import { useUserData } from '../../../../context/UserContext';
// COMPONENTS
import DefaultInput from '../../../../components/form/inputs/DefaultInput';
import DefaultSelect from '../../../../components/form/inputs/DefaultSelect';
import DefaultCheckbox from '../../../../components/form/inputs/DefaultCheckbox';
import Loading from '../../../../components/loading/Loading';
import { alertSuccess, alertError } from '../../../../components/feedback/Feedback';
// FORM
import useInput from '../../../../components/form/hooks/useInput';
import { zipCodeInputMask, vatNumberInputMask, currencyInputMask, phoneInputMask } from '../../../../components/form/masks/InputMasks';
import { isNotEmpty, isEmail, isChecked } from '../../../../components/form/validator/ValidatorRules';
import { isMandatoryMessage } from '../../../../components/form/validator/ValidatorMessages';
import { subcaeListoptions } from '../../../../components/form/fillforms/insurance';
// ACTIONS
import { send_notification } from '../../../../actions/Notifier';
import { detail as subsidiaryDetail } from '../../../../actions/Subsidiary';
import { save as saveLog } from '../../../../actions/LogRegister';
// UTILS
import { serviceOrigin, serviceType, servicePartner } from '../../../../utils/Enums';
import { InsurancePartnershipEmail } from '../../../../utils/Constants';
// CONTENT
import { SelectContent } from '../../../../content/resources';
// RESOURCES
import { WorkplaceAccidentEmailTemplate } from '../../emailtemplates/WorkplaceAccidentEmailTemplate';
import { termsAndConditions } from '../../../../components/form/fillforms/general';

export default function WorkplaceAccidentForm() {
	const {
		value: policyholderValue,
		isValid: policyholderIsValid,
		hasError: policyholderHasError,
		valueChangeHandler: policyholderChangeHandler,
		inputBlurHandler: policyholderBlurHandler,
		reset: resetPolicyholder
	} = useInput(isNotEmpty);
	const {
		value: vatNumberValue,
		isValid: vatNumberIsValid,
		hasError: vatNumberHasError,
		valueChangeHandler: vatNumberChangeHandler,
		inputBlurHandler: vatNumberBlurHandler,
		reset: resetVatNumber
	} = useInput(isNotEmpty);
	const { value: emailValue, isValid: emailIsValid, hasError: emailHasError, valueChangeHandler: emailChangeHandler, inputBlurHandler: emailBlurHandler, reset: resetEmail } = useInput(isEmail);
	const {
		value: phoneValue,
		isValid: phoneIsValid,
		hasError: phoneHasError,
		valueChangeHandler: phoneChangeHandler,
		inputBlurHandler: phoneBlurHandler,
		reset: resetPhone
	} = useInput(isNotEmpty);
	const {
		value: addressValue,
		isValid: addressIsValid,
		hasError: addressHasError,
		valueChangeHandler: addressChangeHandler,
		inputBlurHandler: addressBlurHandler,
		reset: resetAddress
	} = useInput(isNotEmpty);
	const {
		value: zipcodeValue,
		isValid: zipcodeIsValid,
		hasError: zipcodeHasError,
		valueChangeHandler: zipcodeChangeHandler,
		inputBlurHandler: zipcodeBlurHandler,
		reset: resetZipcode
	} = useInput(isNotEmpty);
	const {
		value: locationValue,
		isValid: locationIsValid,
		hasError: locationHasError,
		valueChangeHandler: locationChangeHandler,
		inputBlurHandler: locationBlurHandler,
		reset: resetLocation
	} = useInput(isNotEmpty);
	const {
		value: currentpolicynumberValue,
		isValid: currentpolicynumberIsValid,
		hasError: currentpolicynumberHasError,
		valueChangeHandler: currentpolicynumberChangeHandler,
		inputBlurHandler: currentpolicynumberBlurHandler,
		reset: resetCurrentpolicynumber
	} = useInput(isNotEmpty);
	const {
		value: subcaeValue,
		isValid: subcaeIsValid,
		hasError: subcaeHasError,
		valueChangeHandler: subcaeChangeHandler,
		inputBlurHandler: subcaeBlurHandler,
		reset: resetSubcae
	} = useInput(isNotEmpty);
	const {
		value: activityValue,
		isValid: activityIsValid,
		hasError: activityHasError,
		valueChangeHandler: activityChangeHandler,
		inputBlurHandler: activityBlurHandler,
		reset: resetActivity
	} = useInput(isNotEmpty);
	const {
		value: annualsaferemunerationValue,
		isValid: annualsaferemunerationIsValid,
		hasError: annualsaferemunerationHasError,
		valueChangeHandler: annualsaferemunerationChangeHandler,
		inputBlurHandler: annualsaferemunerationBlurHandler,
		reset: resetAnnualsaferemuneration
	} = useInput(isNotEmpty);
	const {
		value: currentinsuranceValue,
		isValid: currentinsuranceIsValid,
		hasError: currentinsuranceHasError,
		valueChangeHandler: currentinsuranceChangeHandler,
		inputBlurHandler: currentinsuranceBlurHandler,
		reset: resetCurrentinsurance
	} = useInput(isNotEmpty);
	const {
		value: termsandpolicyValue,
		isValid: termsandpolicyIsValid,
		hasError: termsandpolicyHasError,
		valueChangeHandler: termsandpolicyChangeHandler,
		inputBlurHandler: termsandpolicyBlurHandler,
		reset: resetTermsandpolicy
	} = useInput(isChecked);

	let formIsValid = false;

	if (
		policyholderIsValid &&
		vatNumberIsValid &&
		emailIsValid &&
		phoneIsValid &&
		addressIsValid &&
		zipcodeIsValid &&
		locationIsValid &&
		currentpolicynumberIsValid &&
		subcaeIsValid &&
		activityIsValid &&
		currentinsuranceIsValid &&
		annualsaferemunerationIsValid &&
		termsandpolicyIsValid
	) {
		formIsValid = true;
	}

	// CONTEXT
	const { state } = useUserData();

	// STATE
	const [isLoading, setIsLoading] = useState(false);
	const [log, setLog] = useState({
		id: 0,
		subsidiaryId: state.user.subsidiaryId,
		userId: state.user.id,
		originId: serviceOrigin.SERVICE_PLUS,
		startDate: new Date(),
		serviceTypeId: serviceType.INSURANCES,
		partnerId: servicePartner.FAST_PATH_WORK_ACCIDENTS
	});

	// USEEFFECT
	useEffect(
		() => {
			saveFormLog();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	// METHODS
	// Log
	const saveFormLog = useCallback(
		async submissionData => {
			await saveLog({
				data: { ...log, ...submissionData },
				success: logId => {
					setLog({ ...log, id: logId });
				},
				error: error => {}
			});
		}, // eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	// Notification
	const handleResetForm = () => {
		alertSuccess('Pedido de cotação submetido com sucesso');
		clearForm();
		setIsLoading(false);
	};

	function getEmailTemplate(subsidiary, message) {
		const emailsubject = 'Acidentes de trabalho - Pedido de cotação';

		return WorkplaceAccidentEmailTemplate({
			subject: emailsubject,
			message: message,
			subsidiaryName: subsidiary.name,
			subsidiaryId: state.user.subsidiaryId,
			subsidiaryVat: state.user.subsidiaryVat,
			policyholder: policyholderValue,
			vatNumber: vatNumberValue,
			email: emailValue,
			phone: phoneValue,
			address: addressValue,
			zipcode: zipcodeValue,
			location: locationValue,
			currentpolicynumber: currentpolicynumberValue,
			subcae: subcaeValue.label,
			activity: activityValue,
			annualsaferemuneration: annualsaferemunerationValue,
			currentinsurance: currentinsuranceValue
		});
	}

	const sendNotificationToUser = async (emailtemplate, emailValue) => {
		let _formData = new FormData();

		_formData.append('body', emailtemplate.body);
		_formData.append('subject', emailtemplate.subject);
		_formData.append('toAddresses', emailValue);
		_formData.append('isHTML', true);
		_formData.append('priority', 0);

		await send_notification({
			data: _formData,
			success: data => {
				handleResetForm();
			},
			error: error => {
				handleResetForm();
			}
		});
	};

	const sendNotification = async subsidiary => {
		const companyMessage = 'Foi efetuado um pedido de cotação de seguro com os dados a seguir apresentados:';
		const userMessage = 'O seu pedido de cotação foi submetido com sucesso. O nosso parceiro irá em breve entrar em contacto consigo.';
		const emailtemplate = getEmailTemplate(subsidiary, companyMessage);
		const useremailtemplate = getEmailTemplate(subsidiary, userMessage);
		let _formData = new FormData();

		_formData.append('body', emailtemplate.body);
		_formData.append('subject', emailtemplate.subject);
		_formData.append('toAddresses', InsurancePartnershipEmail);
		_formData.append('isHTML', true);
		_formData.append('priority', 0);

		await send_notification({
			data: _formData,
			success: data => {
				sendNotificationToUser(useremailtemplate, emailValue);
				saveFormLog({
					submissionDate: new Date()
				});
			},
			error: error => {
				alertError('Ocorreu um erro na submissão do formulário. Por favor, tente novamente.');
				setIsLoading(false);
			}
		});
	};

	// Subsidiary
	const getSubsidiaryDetail = async () => {
		setIsLoading(true);

		await subsidiaryDetail({
			success: subsidiary => {
				sendNotification(subsidiary);
			},
			error: error => {
				setIsLoading(false);
				alertError('Ocorreu um erro na submissão do formulário. Por favor, tente novamente.');
			}
		});
	};

	// Form
	const clearForm = () => {
		resetPolicyholder();
		resetVatNumber();
		resetEmail();
		resetPhone();
		resetAddress();
		resetZipcode();
		resetLocation();
		resetCurrentpolicynumber();
		resetSubcae();
		resetActivity();
		resetCurrentinsurance();
		resetAnnualsaferemuneration();
		resetTermsandpolicy();
	};

	const validateAllFormInputs = () => {
		policyholderBlurHandler();
		policyholderChangeHandler(policyholderValue);
		vatNumberBlurHandler();
		vatNumberChangeHandler(vatNumberValue);
		emailBlurHandler();
		emailChangeHandler(emailValue);
		phoneBlurHandler();
		phoneChangeHandler(phoneValue);
		addressBlurHandler();
		addressChangeHandler(addressValue);
		zipcodeBlurHandler();
		zipcodeChangeHandler(zipcodeValue);
		locationBlurHandler();
		locationChangeHandler(locationValue);
		currentpolicynumberBlurHandler();
		currentpolicynumberChangeHandler(currentpolicynumberValue);
		subcaeBlurHandler();
		subcaeChangeHandler(subcaeValue);
		activityBlurHandler();
		activityChangeHandler(activityValue);
		annualsaferemunerationBlurHandler();
		annualsaferemunerationChangeHandler(annualsaferemunerationValue);
		currentinsuranceBlurHandler();
		currentinsuranceChangeHandler(currentinsuranceValue);
		termsandpolicyBlurHandler();
		termsandpolicyChangeHandler(termsandpolicyValue);
	};

	const handleSubmit = event => {
		event.preventDefault();

		if (!formIsValid) {
			validateAllFormInputs();
			return;
		}

		getSubsidiaryDetail();
	};

	return (
		<>
			<Form className="form--container" onSubmit={handleSubmit}>
				<Row>
					<Col md={12} lg={2}>
						<h5>Empresa</h5>
					</Col>
					<Col md={12} lg={10}>
						<Row>
							<Col>
								<DefaultInput
									label={'Tomador'}
									name={'policyholder'}
									required={true}
									type={'text'}
									value={policyholderValue}
									onChange={policyholderChangeHandler}
									onBlur={policyholderBlurHandler}
									hasError={policyholderHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<DefaultInput
									label={'NIF'}
									name={'vatNumber'}
									required={true}
									maxlength={9}
									type={'mask'}
									mask={vatNumberInputMask}
									value={vatNumberValue}
									onChange={vatNumberChangeHandler}
									onBlur={vatNumberBlurHandler}
									hasError={vatNumberHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							<Col>
								<DefaultInput
									label={'Email'}
									name={'email'}
									required={true}
									type={'text'}
									value={emailValue}
									onChange={emailChangeHandler}
									onBlur={emailBlurHandler}
									hasError={emailHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							<Col>
								<DefaultInput
									label={'Telefone'}
									name={'phone'}
									required={true}
									type={'mask'}
									mask={phoneInputMask}
									value={phoneValue}
									onChange={phoneChangeHandler}
									onBlur={phoneBlurHandler}
									hasError={phoneHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
								<DefaultInput
									label={'Morada'}
									name={'address'}
									required={true}
									type={'text'}
									value={addressValue}
									onChange={addressChangeHandler}
									onBlur={addressBlurHandler}
									hasError={addressHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							<Col>
								<DefaultInput
									label={'Código postal'}
									name={'zipcode'}
									required={true}
									type={'mask'}
									mask={zipCodeInputMask}
									value={zipcodeValue}
									onChange={zipcodeChangeHandler}
									onBlur={zipcodeBlurHandler}
									hasError={zipcodeHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							<Col>
								<DefaultInput
									label={'Localidade'}
									name={'location'}
									required={true}
									type={'text'}
									value={locationValue}
									onChange={locationChangeHandler}
									onBlur={locationBlurHandler}
									hasError={locationHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
						</Row>
						<Row xs={1} md={3}>
							<Col>
								<DefaultInput
									label={'Nº de apólice atual'}
									name={'currentpolicynumber'}
									required={true}
									type={'text'}
									value={currentpolicynumberValue}
									onChange={currentpolicynumberChangeHandler}
									onBlur={currentpolicynumberBlurHandler}
									hasError={currentpolicynumberHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							<Col>
								<DefaultSelect
									isClearable={true}
									label={'Sub-CAE'}
									instanceId={'subcaeSelect'}
									required={true}
									options={subcaeListoptions}
									defaultValue={null}
									value={subcaeValue}
									onChange={subcaeChangeHandler}
									onBlur={subcaeBlurHandler}
									hasError={subcaeHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							<Col>
								<DefaultInput
									label={'Atividade'}
									name={'activity'}
									required={true}
									type={'text'}
									value={activityValue}
									onChange={activityChangeHandler}
									onBlur={activityBlurHandler}
									hasError={activityHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
						</Row>
						<Row>
							<Col md={3}>
								<DefaultInput
									label={'Remuneração bruta anual'}
									name={'annualsaferemuneration'}
									required={true}
									extraCharacter={'€'}
									type={'mask'}
									mask={currencyInputMask}
									value={annualsaferemunerationValue}
									onChange={annualsaferemunerationChangeHandler}
									onBlur={annualsaferemunerationBlurHandler}
									hasError={annualsaferemunerationHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							<Col md={3}>
								<DefaultInput
									label={'Qual a seguradora atual'}
									name={'currentinsurance'}
									required={true}
									type={'text'}
									value={currentinsuranceValue}
									onChange={currentinsuranceChangeHandler}
									onBlur={currentinsuranceBlurHandler}
									hasError={currentinsuranceHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
						</Row>
						<Row>
							<Col className="form-authorize mt-4">
								<DefaultCheckbox
									name="termsandpolicy"
									label={SelectContent(termsAndConditions.urlTerms, state.user.type)}
									checked={termsandpolicyValue}
									onChange={termsandpolicyChangeHandler}
									onBlur={termsandpolicyBlurHandler}
									hasError={termsandpolicyHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<Button type={'submit'} className={'service-btn'}>
									Submeter pedido
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
			{isLoading && <Loading label={'A carregar...'} />}
		</>
	);
}
