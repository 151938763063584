export const objectOfInsuranceOptions = [
	{
		id: 1,
		label: 'Condomínio total'
	},
	{
		id: 2,
		label: 'Partes comuns'
	},
	{
		id: 3,
		label: 'Partes do edifício'
	}
];

export const propertyContentOptions = [
	{
		id: 1,
		label: 'Imóvel'
	},
	{
		id: 2,
		label: 'Conteúdo'
	},
	{
		id: 3,
		label: 'Ambos'
	}
];

export const foundationTypeOptions = [
	{
		id: 1,
		label: 'Bens ao ar livre'
	},
	{
		id: 2,
		label: 'Betão'
	},
	{
		id: 3,
		label: 'Betão Armado-Anti-Sísmico'
	},
	{
		id: 4,
		label: 'Enchimento Areia e Inertes'
	},
	{
		id: 5,
		label: 'Estacaria Madeira'
	},
	{
		id: 6,
		label: 'Estacaria Madeira-Anti-Sísmico'
	},
	{
		id: 7,
		label: 'Estacaria Metal'
	},
	{
		id: 8,
		label: 'Estacaria Metal-Anti-Sísmico'
	},
	{
		id: 9,
		label: 'Estrutura Metálica'
	},
	{
		id: 10,
		label: 'Estrut. Metálica-Anti-Sísmico'
	}
];

export const typeOfConstructionOptions = [
	{
		id: 1,
		label: 'Aglomerado'
	},
	{
		id: 2,
		label: 'Betão Armado (com separação de pisos em madeira)'
	},
	{
		id: 3,
		label: 'Betão Armado'
	},
	{
		id: 4,
		label: 'Cimento e Tijolo'
	},
	{
		id: 5,
		label: 'Cimento/Tijolo (com separação de pisos em madeira)'
	},
	{
		id: 6,
		label: 'Est.Metal.(LSF) c/ Paredes OSB'
	},
	{
		id: 7,
		label: 'Est.Metal.(LSF) s/ Paredes OSB'
	},
	{
		id: 8,
		label: 'Estrutura Metálica'
	},
	{
		id: 9,
		label: 'Madeira'
	},
	{
		id: 10,
		label: 'Painel Sandwich EPS'
	},
	{
		id: 11,
		label: 'Painel Sandwich Lã Rocha ou mineral/Lã Vidro'
	},
	{
		id: 12,
		label: 'Painel Sandwich PIR'
	},
	{
		id: 13,
		label: 'Painel Sandwich PUR'
	},
	{
		id: 14,
		label: 'Pedra e Cal'
	},
	{
		id: 15,
		label: 'Pedra e Cal (com separação de pisos em madeira)'
	},
	{
		id: 16,
		label: 'Pré-Fabricado em Betão'
	},
	{
		id: 17,
		label: 'Pré-Fabricado em Madeira'
	},
	{
		id: 18,
		label: 'Tabique'
	}
];

export const roofMaterialOptions = [
	{
		id: 1,
		label: 'Chapas Aço revestidas com Betume e Folhas Aluminio'
	},
	{
		id: 2,
		label: 'Colmo'
	},
	{
		id: 3,
		label: 'Fibrocimento'
	},
	{
		id: 4,
		label: 'Lusalite'
	},
	{
		id: 5,
		label: 'Madeira'
	},
	{
		id: 6,
		label: 'Metálica'
	},
	{
		id: 7,
		label: 'Painel Sandwich PUR/PIR (mais 10% área coberta)'
	},
	{
		id: 8,
		label: 'Painel Sandwich PUR/PIR (menos 10% área coberta)'
	},
	{
		id: 9,
		label: 'Painel Sandwich EPS'
	},
	{
		id: 10,
		label: 'Painel Sandwich Lã Rocha ou Mineral/Lã de Vidro'
	},
	{
		id: 11,
		label: 'Palha'
	},
	{
		id: 12,
		label: 'Placa'
	},
	{
		id: 13,
		label: 'Plástico Translúcido (inferior a 5% área coberta)'
	},
	{
		id: 14,
		label: 'Plástico Translúcido (superior a 5% área coberta)'
	},
	{
		id: 15,
		label: 'Telha'
	},
	{
		id: 16,
		label: 'Telha Asfáltica/Telha e Soletos metálicos'
	},
	{
		id: 17,
		label: 'Zinco'
	}
];

export const indemnityPeriodOptions = [
	{
		id: 1,
		label: '3 meses'
	},
	{
		id: 2,
		label: '6 meses'
	},
	{
		id: 3,
		label: '9 meses'
	},
	{
		id: 4,
		label: '12 meses'
	}
];

export const subcaeListoptions = [
	{
		id: 111100,
		label: 'Cerealicultura (exceto arroz)'
	},
	{
		id: 111200,
		label: 'Cultura leguminosas secas e sementes oleaginosas'
	},
	{
		id: 112000,
		label: 'Cultura de arroz'
	},
	{
		id: 113000,
		label: 'Culturas produtos hortícolas, raízes e tubérculos'
	},
	{
		id: 114000,
		label: 'Cultura de cana-de-açúcar'
	},
	{
		id: 115000,
		label: 'Cultura de tabaco'
	},
	{
		id: 116000,
		label: 'Cultura de plantas têxteis'
	},
	{
		id: 119100,
		label: 'Cultura flores e plantas ornamentais'
	},
	{
		id: 119200,
		label: 'Outras culturas temporárias, n.e.'
	},
	{
		id: 121000,
		label: 'Viticultura'
	},
	{
		id: 122000,
		label: 'Cultura frutos tropicais e subtropicais'
	},
	{
		id: 123000,
		label: 'Cultura de citrinos'
	},
	{
		id: 124000,
		label: 'Cultura pomóideas e prunóideas'
	},
	{
		id: 125100,
		label: 'Cultura de frutos de casca rija'
	},
	{
		id: 125200,
		label: 'Cultura outros frutos em árvores e arbustos'
	},
	{
		id: 126100,
		label: 'Olivicultura'
	},
	{
		id: 126200,
		label: 'Cultura outros frutos oleaginosos'
	},
	{
		id: 127000,
		label: 'Cultura plantas destinadas à preparação bebidas'
	},
	{
		id: 128000,
		label: 'Cultura especiarias, plantas aromát., med. e farm'
	},
	{
		id: 129000,
		label: 'Outras culturas permanentes'
	},
	{
		id: 130000,
		label: 'Cultura materiais propagação vegetativa'
	},
	{
		id: 141000,
		label: 'Criação bovinos para produção leite'
	},
	{
		id: 142000,
		label: 'Criação outros bovinos (exc. prod leite) e búfalo'
	},
	{
		id: 143000,
		label: 'Criação equinos, asininos e muares'
	},
	{
		id: 144000,
		label: 'Criação camelos e camelídeos'
	},
	{
		id: 145000,
		label: 'Criação de ovinos e caprinos'
	},
	{
		id: 146000,
		label: 'Suinicultura'
	},
	{
		id: 147000,
		label: 'Avicultura'
	},
	{
		id: 149100,
		label: 'Apicultura'
	},
	{
		id: 149200,
		label: 'Cunicultura'
	},
	{
		id: 149300,
		label: 'Criação de animais de companhia'
	},
	{
		id: 149400,
		label: 'Outra produção animal, n.e.'
	},
	{
		id: 150000,
		label: 'Agricultura e produção animal combinadas'
	},
	{
		id: 161000,
		label: 'Ativ. dos serviços relacionados com a agricultura'
	},
	{
		id: 162000,
		label: 'Ativ. dos serv. relac. prod. animal, exc serv vet'
	},
	{
		id: 163000,
		label: 'Preparação produtos agrícolas para venda'
	},
	{
		id: 164000,
		label: 'Preparação e tratamento sementes para propagação'
	},
	{
		id: 170100,
		label: 'Caça e repovoamento cinegético'
	},
	{
		id: 170200,
		label: 'Ativ serviços relac c/ caça e repovoam cinegético'
	},
	{
		id: 210000,
		label: 'Silvicultura e outras Ativ. florestais'
	},
	{
		id: 220000,
		label: 'Exploração florestal'
	},
	{
		id: 230000,
		label: 'Extração cortiça, prod. florestais, exc. madeira'
	},
	{
		id: 240000,
		label: 'Ativ. serv. relac. c/ silvicultura expl florestal'
	},
	{
		id: 311100,
		label: 'Pesca marítima'
	},
	{
		id: 311200,
		label: 'Apanha algas e outros produtos do mar'
	},
	{
		id: 312100,
		label: 'Pesca em águas interiores'
	},
	{
		id: 312200,
		label: 'Apanha produtos águas interiores'
	},
	{
		id: 321000,
		label: 'Aquicultura em águas salgadas e salobras'
	},
	{
		id: 322000,
		label: 'Aquicultura em águas doces'
	},
	{
		id: 510000,
		label: 'Extração hulha (inclui antracite)'
	},
	{
		id: 520000,
		label: 'Extração de lenhite'
	},
	{
		id: 610000,
		label: 'Extração de petróleo bruto'
	},
	{
		id: 620000,
		label: 'Extração de gás natural'
	},
	{
		id: 710000,
		label: 'Extração e preparação minérios ferro'
	},
	{
		id: 721000,
		label: 'Extração e preparação minérios urânio e tório'
	},
	{
		id: 729000,
		label: 'Extração e prep. outros minérios met não ferrosos'
	},
	{
		id: 811100,
		label: 'Extração de mármore e outras rochas carbonatadas'
	},
	{
		id: 811200,
		label: 'Extracção de granito ornamental e rochas similares'
	},
	{
		id: 811300,
		label: 'Extração de calcário e cré'
	},
	{
		id: 811400,
		label: 'Extração de gesso'
	},
	{
		id: 811500,
		label: 'Extração de ardósia'
	},
	{
		id: 812100,
		label: 'Extração saibro, areia e pedra britada'
	},
	{
		id: 812200,
		label: 'Extração de argilas e caulino'
	},
	{
		id: 891000,
		label: 'Extração minerais indúst. química e Fabric adubos'
	},
	{
		id: 892000,
		label: 'Extração da turfa'
	},
	{
		id: 893100,
		label: 'Extração de sal marinho'
	},
	{
		id: 893200,
		label: 'Extração de sal gema'
	},
	{
		id: 899100,
		label: 'Extração de feldspato'
	},
	{
		id: 899200,
		label: 'Extração outros minerais não metálicos, n.e.'
	},
	{
		id: 910000,
		label: 'Ativ. extração petróleo e gás, exceto prospecção'
	},
	{
		id: 990000,
		label: 'Outras ativ. serviços relac. c/ indúst. extractiv'
	},
	{
		id: 1011000,
		label: 'Abate gado (produção carne)'
	},
	{
		id: 1012000,
		label: 'Abate aves (produção carne)'
	},
	{
		id: 1013000,
		label: 'Fabricação produtos à base carne'
	},
	{
		id: 1020100,
		label: 'Preparação de produtos da pesca e da aquicultura'
	},
	{
		id: 1020200,
		label: 'Congelação de produtos da pesca e da aquicultura'
	},
	{
		id: 1020300,
		label: 'Conservação prod. da pesca e aquicultura em óleos'
	},
	{
		id: 1020400,
		label: 'Salga, seca e ativ. transf. prod. pesca e aquic.'
	},
	{
		id: 1031000,
		label: 'Preparação e conservação batatas'
	},
	{
		id: 1032000,
		label: 'Fabricação sumos frutos e produtos hortícolas'
	},
	{
		id: 1039100,
		label: 'Congelação frutos e produtos hortícolas'
	},
	{
		id: 1039200,
		label: 'Secagem e desidratação frutos e produt. hortícola'
	},
	{
		id: 1039300,
		label: 'Fabricação de doces, compotas, geleias e marmelada'
	},
	{
		id: 1039400,
		label: 'Descasque e transform. frutos casca rija comest.'
	},
	{
		id: 1039500,
		label: 'Prep. e conserv. frutos e prod. hort. outros proc'
	},
	{
		id: 1041100,
		label: 'Produção óleos e gorduras animais brutos'
	},
	{
		id: 1041200,
		label: 'Produção de azeite'
	},
	{
		id: 1041300,
		label: 'Produção óleos vegetais brutos (excepto azeite)'
	},
	{
		id: 1041400,
		label: 'Refinação azeite, óleos e gorduras'
	},
	{
		id: 1042000,
		label: 'Fabric. margarinas e gorduras aliment. similares'
	},
	{
		id: 1051000,
		label: 'Indústrias do leite e derivados'
	},
	{
		id: 1052000,
		label: 'Fabricação gelados e sorvetes'
	},
	{
		id: 1061100,
		label: 'Moagem de cereais'
	},
	{
		id: 1061200,
		label: 'Descasque, branqueamento e outros tratam do arroz'
	},
	{
		id: 1061300,
		label: 'Transformação cereais e leguminosas, n.e.'
	},
	{
		id: 1062000,
		label: 'Fabricação amidos, féculas e produtos afins'
	},
	{
		id: 1071100,
		label: 'Panificação'
	},
	{
		id: 1071200,
		label: 'Pastelaria'
	},
	{
		id: 1072000,
		label: 'Fabric bolachas, biscoitos, e pastelaria conserv.'
	},
	{
		id: 1073000,
		label: 'Fabricação massas alimentícias, cuscuz e similar'
	},
	{
		id: 1081000,
		label: 'Indústria do açúcar'
	},
	{
		id: 1082100,
		label: 'Fabricação cacau e chocolate'
	},
	{
		id: 1082200,
		label: 'Fabricação produtos confeitaria'
	},
	{
		id: 1083000,
		label: 'Indústria do café e do chá'
	},
	{
		id: 1084000,
		label: 'Fabricação condimentos e temperos'
	},
	{
		id: 1085000,
		label: 'Fabricação refeições e pratos pré-cozinhados'
	},
	{
		id: 1086000,
		label: 'Fabricação alimentos homogeneizados e dietéticos'
	},
	{
		id: 1089100,
		label: 'Fabric fermentos, leveduras e adjuvantes panific.'
	},
	{
		id: 1089200,
		label: 'Fabricação caldos, sopas e sobremesas'
	},
	{
		id: 1089300,
		label: 'Fabric outros produtos alimentares diversos, n.e.'
	},
	{
		id: 1091100,
		label: 'Fabricação de pré-misturas'
	},
	{
		id: 1091200,
		label: 'Fabric alimento p/ animais criação (exc aquicult)'
	},
	{
		id: 1091300,
		label: 'Fabricação alimentos para aquicultura'
	},
	{
		id: 1092000,
		label: 'Fabricação alimentos para animais companhia'
	},
	{
		id: 1101100,
		label: 'Fabricação aguardentes preparadas'
	},
	{
		id: 1101200,
		label: 'Fabricação aguardentes não preparadas'
	},
	{
		id: 1101300,
		label: 'Produção licores e outras bebidas destiladas'
	},
	{
		id: 1102100,
		label: 'Produção vinhos comuns e licorosos'
	},
	{
		id: 1102200,
		label: 'Produção vinhos espumantes e espumosos'
	},
	{
		id: 1103000,
		label: 'Fabric. cidra e outras bebidas fermentadas frutos'
	},
	{
		id: 1104000,
		label: 'Fabric vermutes e bebidas ferment. não destiladas'
	},
	{
		id: 1105000,
		label: 'Fabricação de cerveja'
	},
	{
		id: 1106000,
		label: 'Fabricação de malte'
	},
	{
		id: 1107100,
		label: 'Engarrafamento águas minerais naturais e nascente'
	},
	{
		id: 1107200,
		label: 'Fabric. refrigerantes e bebidas não alcoól., n.e.'
	},
	{
		id: 1200000,
		label: 'Preparação de tabaco'
	},
	{
		id: 1310100,
		label: 'Preparação e fiação fibras do tipo algodão'
	},
	{
		id: 1310200,
		label: 'Preparação e fiação fibras do tipo lã'
	},
	{
		id: 1310300,
		label: 'Preparação e fiação da seda'
	},
	{
		id: 1310400,
		label: 'Fabricação de linhas de costura'
	},
	{
		id: 1310500,
		label: 'Preparação e fiação linho e outras fibras têxteis'
	},
	{
		id: 1320100,
		label: 'Tecelagem fio do tipo algodão'
	},
	{
		id: 1320200,
		label: 'Tecelagem de fio do tipo lã'
	},
	{
		id: 1320300,
		label: 'Tecelagem fio do tipo seda e outros têxteis'
	},
	{
		id: 1330100,
		label: 'Branqueamento e tingimento'
	},
	{
		id: 1330200,
		label: 'Estampagem'
	},
	{
		id: 1330300,
		label: 'Acabamento fios, tecidos e artigos têxteis, n.e.'
	},
	{
		id: 1391000,
		label: 'Fabricação de tecidos de malha'
	},
	{
		id: 1392000,
		label: 'Fabricação artig. têxteis confec., exc. vestuário'
	},
	{
		id: 1393000,
		label: 'Fabricação tapetes e carpetes'
	},
	{
		id: 1394100,
		label: 'Fabricação de cordoaria'
	},
	{
		id: 1394200,
		label: 'Fabricação de redes'
	},
	{
		id: 1395000,
		label: 'Fabric não tecidos e respetiv. artig., exc. vest.'
	},
	{
		id: 1396100,
		label: 'Fabricação passamanarias e sirgarias'
	},
	{
		id: 1396200,
		label: 'Fabric. têxteis p/ uso técnico e industrial, n.e.'
	},
	{
		id: 1399100,
		label: 'Fabricação de bordados'
	},
	{
		id: 1399200,
		label: 'Fabricação de rendas'
	},
	{
		id: 1399300,
		label: 'Fabricação outros têxteis diversos, n.e.'
	},
	{
		id: 1411000,
		label: 'Confeção vestuário em couro'
	},
	{
		id: 1412000,
		label: 'Confeção vestuário trabalho'
	},
	{
		id: 1413100,
		label: 'Confeção outro vestuário exterior em série'
	},
	{
		id: 1413200,
		label: 'Confeção de outro vestuário exterior por medida'
	},
	{
		id: 1413300,
		label: 'Ativ. acabamento artigos vestuário'
	},
	{
		id: 1414000,
		label: 'Confeção vestuário interior'
	},
	{
		id: 1419000,
		label: 'Confeção outros artigos e acessórios vestuário'
	},
	{
		id: 1420000,
		label: 'Fabricação artigos peles com pêlo'
	},
	{
		id: 1431000,
		label: 'Fabricação meias e similares malha'
	},
	{
		id: 1439000,
		label: 'Fabricação outro vestuário malha'
	},
	{
		id: 1511100,
		label: 'Curtimenta e acabamento peles sem pêlo'
	},
	{
		id: 1511200,
		label: 'Fabricação couro reconstituído'
	},
	{
		id: 1511300,
		label: 'Curtimenta e acabamento peles com pêlo'
	},
	{
		id: 1512000,
		label: 'Fabric artigos viagem, uso pessoal, marroquinaria'
	},
	{
		id: 1520100,
		label: 'Fabricação de calçado'
	},
	{
		id: 1520200,
		label: 'Fabricação componentes para calçado'
	},
	{
		id: 1610100,
		label: 'Serração de madeira'
	},
	{
		id: 1610200,
		label: 'Impregnação de madeira'
	},
	{
		id: 1621100,
		label: 'Fabricação painéis partículas madeira'
	},
	{
		id: 1621200,
		label: 'Fabricação painéis fibras madeira'
	},
	{
		id: 1621300,
		label: 'Fabric folheados, contraplacados, outros painéis'
	},
	{
		id: 1622000,
		label: 'Parqueteria'
	},
	{
		id: 1623000,
		label: 'Fabric outras obras carpintaria para a construção'
	},
	{
		id: 1624000,
		label: 'Fabricação embalagens madeira'
	},
	{
		id: 1629100,
		label: 'Fabricação outras obras madeira'
	},
	{
		id: 1629200,
		label: 'Fabricação obras cestaria e espartaria'
	},
	{
		id: 1629300,
		label: 'Indústria preparação da cortiça'
	},
	{
		id: 1629400,
		label: 'Fabricação de rolhas de cortiça'
	},
	{
		id: 1629500,
		label: 'Fabricação outros produtos cortiça'
	},
	{
		id: 1711000,
		label: 'Fabricação de pasta'
	},
	{
		id: 1712000,
		label: 'Fabricação papel e cartão (excepto canelado)'
	},
	{
		id: 1721100,
		label: 'Fabric papel e cartão canelados (incl embalagens)'
	},
	{
		id: 1721200,
		label: 'Fabricação outras embalagens papel e cartão'
	},
	{
		id: 1722000,
		label: 'Fabric artigos papel p/ uso doméstico e sanitário'
	},
	{
		id: 1723000,
		label: 'Fabricação artigos papel para papelaria'
	},
	{
		id: 1724000,
		label: 'Fabricação de papel de parede'
	},
	{
		id: 1729000,
		label: 'Fabric outros artigos pasta papel, papel e cartão'
	},
	{
		id: 1811000,
		label: 'Impressão de jornais'
	},
	{
		id: 1812000,
		label: 'Outra impressão'
	},
	{
		id: 1813000,
		label: 'Ativ. preparação da impressão e produtos media'
	},
	{
		id: 1814000,
		label: 'Encadernação e Ativ. relacionadas'
	},
	{
		id: 1820000,
		label: 'Reprodução suportes gravados'
	},
	{
		id: 1910000,
		label: 'Fabricação produtos coqueria'
	},
	{
		id: 1920100,
		label: 'Fabricação produtos petrolíferos refinados'
	},
	{
		id: 1920200,
		label: 'Fabricação produtos petrolíferos a partir resíduo'
	},
	{
		id: 1920300,
		label: 'Fabricação briquetes e aglomerados hulha e lenhit'
	},
	{
		id: 2011000,
		label: 'Fabricação gases industriais'
	},
	{
		id: 2012000,
		label: 'Fabricação corantes e pigmentos'
	},
	{
		id: 2013000,
		label: 'Fabric. outros produtos químicos inorgânicos base'
	},
	{
		id: 2014100,
		label: 'Fabricação resinosos e seus derivados'
	},
	{
		id: 2014200,
		label: 'Fabric carvão (vegetal e animal) e produtos assoc'
	},
	{
		id: 2014300,
		label: 'Fabricação álcool etílico fermentação'
	},
	{
		id: 2014400,
		label: 'Fabric outros prod. químicos orgânicos base, n.e.'
	},
	{
		id: 2015100,
		label: 'Fabric. adubos quím. ou minerais e comp. azotados'
	},
	{
		id: 2015200,
		label: 'Fabricação de adubos orgânicos e organo-minerais'
	},
	{
		id: 2016000,
		label: 'Fabricação matérias plásticas sob formas primária'
	},
	{
		id: 2017000,
		label: 'Fabricação borracha sintética sob formas primária'
	},
	{
		id: 2020000,
		label: 'Fabric. pesticidas e outros produtos agroquímicos'
	},
	{
		id: 2030100,
		label: 'Fabric tintas (exc impressão), vernizes, mastique'
	},
	{
		id: 2030200,
		label: 'Fabricação tintas impressão'
	},
	{
		id: 2030300,
		label: 'Fabric pigmentos prep, composições vitrif e afins'
	},
	{
		id: 2041100,
		label: 'Fabricação sabões, detergentes e glicerina'
	},
	{
		id: 2041200,
		label: 'Fabricação produtos limpeza, polimento e proteção'
	},
	{
		id: 2042000,
		label: 'Fabricação perfumes, cosméticos, produtos higiene'
	},
	{
		id: 2051000,
		label: 'Fabricação explosivos e artigos pirotecnia'
	},
	{
		id: 2052000,
		label: 'Fabricação de colas'
	},
	{
		id: 2053000,
		label: 'Fabricação de óleos essenciais'
	},
	{
		id: 2059100,
		label: 'Fabricação de biodiesel'
	},
	{
		id: 2059200,
		label: 'Fabricação prod. químicos aux. p/ uso industrial'
	},
	{
		id: 2059300,
		label: 'Fabric óleos e massas lubrific., excl. refinarias'
	},
	{
		id: 2059400,
		label: 'Fabricação outros produtos químicos diversos, n.e'
	},
	{
		id: 2060000,
		label: 'Fabricação fibras sintéticas ou artificiais'
	},
	{
		id: 2110000,
		label: 'Fabricação produtos farmacêuticos base'
	},
	{
		id: 2120100,
		label: 'Fabricação de medicamentos'
	},
	{
		id: 2120200,
		label: 'Fabric outras preparações e artigos farmacêuticos'
	},
	{
		id: 2211100,
		label: 'Fabricação pneus e câmaras-de-ar'
	},
	{
		id: 2211200,
		label: 'Reconstrução de pneus'
	},
	{
		id: 2219100,
		label: 'Fabricação componentes borracha para calçado'
	},
	{
		id: 2219200,
		label: 'Fabricação outros produtos borracha, n.e.'
	},
	{
		id: 2221000,
		label: 'Fabricação chapas, folha, tubos e perfis plástico'
	},
	{
		id: 2222000,
		label: 'Fabricação embalagens plástico'
	},
	{
		id: 2223000,
		label: 'Fabricação artigos plástico para a construção'
	},
	{
		id: 2229100,
		label: 'Fabricação componentes plástico para calçado'
	},
	{
		id: 2229200,
		label: 'Fabricação outros artigos plástico, n.e.'
	},
	{
		id: 2311000,
		label: 'Fabricação de vidro plano'
	},
	{
		id: 2312000,
		label: 'Moldagem e transformação vidro plano'
	},
	{
		id: 2313100,
		label: 'Fabricação vidro embalagem'
	},
	{
		id: 2313200,
		label: 'Cristalaria'
	},
	{
		id: 2314000,
		label: 'Fabricação de fibras de vidro'
	},
	{
		id: 2319000,
		label: 'Fabricação e transf. vidro (inclui vidro técnico)'
	},
	{
		id: 2320000,
		label: 'Fabricação produtos cerâmicos refractários'
	},
	{
		id: 2331100,
		label: 'Fabricação de azulejos'
	},
	{
		id: 2331200,
		label: 'Fabricação ladrilhos, mosaicos e placas cerâmica'
	},
	{
		id: 2332100,
		label: 'Fabricação de tijolos'
	},
	{
		id: 2332200,
		label: 'Fabricação de telhas'
	},
	{
		id: 2332300,
		label: 'Fabricação de abobadilhas'
	},
	{
		id: 2332400,
		label: 'Fabric. outros produtos cerâmicos p/ a construção'
	},
	{
		id: 2341100,
		label: 'Olaria de barro'
	},
	{
		id: 2341200,
		label: 'Fabric. artigos uso doméstico faiança, porcelana'
	},
	{
		id: 2341300,
		label: 'Fabric. artigos ornamentação faiança, porcelana'
	},
	{
		id: 2341400,
		label: 'Ativ. decor. artig cerâmico uso domést ornamental'
	},
	{
		id: 2342000,
		label: 'Fabricação artigos cerâmicos para usos sanitários'
	},
	{
		id: 2343000,
		label: 'Fabric. isoladores e peças isolantes em cerâmica'
	},
	{
		id: 2344000,
		label: 'Fabric. outros produtos cerâmica p/ usos técnicos'
	},
	{
		id: 2349000,
		label: 'Fabric outros produtos cerâmicos não refractários'
	},
	{
		id: 2351000,
		label: 'Fabricação de cimento'
	},
	{
		id: 2352100,
		label: 'Fabricação de cal'
	},
	{
		id: 2352200,
		label: 'Fabricação de gesso'
	},
	{
		id: 2361000,
		label: 'Fabricação produtos betão para a construção'
	},
	{
		id: 2362000,
		label: 'Fabricação produtos gesso para a construção'
	},
	{
		id: 2363000,
		label: 'Fabricação de betão pronto'
	},
	{
		id: 2364000,
		label: 'Fabricação de argamassas'
	},
	{
		id: 2365000,
		label: 'Fabricação produtos fibrocimento'
	},
	{
		id: 2369000,
		label: 'Fabricação outros produtos betão, gesso e cimento'
	},
	{
		id: 2370100,
		label: 'Fabricação artigos mármore e rochas similares'
	},
	{
		id: 2370200,
		label: 'Fabricação artigos em ardósia (lousa)'
	},
	{
		id: 2370300,
		label: 'Fabricação artigos granito e rochas, n.e.'
	},
	{
		id: 2391000,
		label: 'Fabricação produtos abrasivos'
	},
	{
		id: 2399100,
		label: 'Fabricação misturas betuminosas'
	},
	{
		id: 2399200,
		label: 'Fabric prod. minerais não metálicos diversos, n.e'
	},
	{
		id: 2410000,
		label: 'Siderurgia e fabricação ferro-ligas'
	},
	{
		id: 2420000,
		label: 'Fabric. tubos, condutas, perfis ocos e acess, aço'
	},
	{
		id: 2431000,
		label: 'Estiragem a frio'
	},
	{
		id: 2432000,
		label: 'Laminagem a frio arco ou banda'
	},
	{
		id: 2433000,
		label: 'Perfilagem a frio'
	},
	{
		id: 2434000,
		label: 'Trefilagem a frio'
	},
	{
		id: 2441000,
		label: 'Obtenção e primeira transformação metais precioso'
	},
	{
		id: 2442000,
		label: 'Obtenção e primeira transformação alumínio'
	},
	{
		id: 2443000,
		label: 'Obtenção e transform. chumbo, zinco e estanho'
	},
	{
		id: 2444000,
		label: 'Obtenção e primeira transformação cobre'
	},
	{
		id: 2445000,
		label: 'Obtenção e transform. outros metais não ferrosos'
	},
	{
		id: 2446000,
		label: 'Tratamento combustível nuclear'
	},
	{
		id: 2451000,
		label: 'Fundição de ferro fundido'
	},
	{
		id: 2452000,
		label: 'Fundição de aço'
	},
	{
		id: 2453000,
		label: 'Fundição de metais leves'
	},
	{
		id: 2454000,
		label: 'Fundição outros metais não ferrosos'
	},
	{
		id: 2511000,
		label: 'Fabricação estruturas construções metálicas'
	},
	{
		id: 2512000,
		label: 'Fabric. portas, janelas e elementos simil. metal'
	},
	{
		id: 2521000,
		label: 'Fabric caldeiras e radiadores p/ aquecim. central'
	},
	{
		id: 2529000,
		label: 'Fabric outros reservatórios e recipientes metálic'
	},
	{
		id: 2530000,
		label: 'Fabric gerador vapor, exc caldeiras aquec central'
	},
	{
		id: 2540100,
		label: 'Fabricação armas caça, desporto e defesa'
	},
	{
		id: 2540200,
		label: 'Fabricação de armamento'
	},
	{
		id: 2550100,
		label: 'Fabric. produtos forjados, estampados e laminados'
	},
	{
		id: 2550200,
		label: 'Fabricação produtos por pulverometalurgia'
	},
	{
		id: 2561000,
		label: 'Tratamento e revestimento metais'
	},
	{
		id: 2562000,
		label: 'Atividades de mecânica geral'
	},
	{
		id: 2571000,
		label: 'Fabricação de cutelaria'
	},
	{
		id: 2572000,
		label: 'Fabric. fechaduras, dobradiças e outras ferragens'
	},
	{
		id: 2573100,
		label: 'Fabricação ferramentas manuais'
	},
	{
		id: 2573200,
		label: 'Fabricação ferramentas mecânicas'
	},
	{
		id: 2573300,
		label: 'Fabricação peças sinterizadas'
	},
	{
		id: 2573400,
		label: 'Fabricação de moldes metálicos'
	},
	{
		id: 2591000,
		label: 'Fabricação embalagens metálicas pesadas'
	},
	{
		id: 2592000,
		label: 'Fabricação embalagens metálicas ligeiras'
	},
	{
		id: 2593100,
		label: 'Fabricação de produtos de arame'
	},
	{
		id: 2593200,
		label: 'Fabricação de molas'
	},
	{
		id: 2593300,
		label: 'Fabricação correntes metálicas'
	},
	{
		id: 2594000,
		label: 'Fabricação rebites, parafusos e porcas'
	},
	{
		id: 2599100,
		label: 'Fabricação louça metálica e artigos uso doméstico'
	},
	{
		id: 2599200,
		label: 'Fabric. outros produtos metálicos diversos, n.e.'
	},
	{
		id: 2611000,
		label: 'Fabricação componentes electrónicos'
	},
	{
		id: 2612000,
		label: 'Fabricação placas circuitos electrónicos'
	},
	{
		id: 2620000,
		label: 'Fabricação computadores e equipamento periférico'
	},
	{
		id: 2630000,
		label: 'Fabric aparelhos e equipamentos para comunicações'
	},
	{
		id: 2640000,
		label: 'Fabric. recetores rádio, tv e bens consumo simil.'
	},
	{
		id: 2651100,
		label: 'Fabric. contadores elet., gás, água e outros líq.'
	},
	{
		id: 2651200,
		label: 'Fabric. inst. e aparelhos medida, verificação'
	},
	{
		id: 2652000,
		label: 'Fabricação relógios e material relojoaria'
	},
	{
		id: 2660000,
		label: 'Fabric. equip. radiação, electromédicos'
	},
	{
		id: 2670100,
		label: 'Fabricação inst. e equip. ópticos não oftálmicos'
	},
	{
		id: 2670200,
		label: 'Fabricação material fotográfico e cinematográfico'
	},
	{
		id: 2680000,
		label: 'Fabricação suportes inform. magnéticos e ópticos'
	},
	{
		id: 2711000,
		label: 'Fabric motores, geradores e transform. eléctricos'
	},
	{
		id: 2712100,
		label: 'Fabric material p/ instal. eléctricas alta tensão'
	},
	{
		id: 2712200,
		label: 'Fabric material p/ instal eléctricas baixa tensão'
	},
	{
		id: 2720000,
		label: 'Fabricação acumuladores e pilhas'
	},
	{
		id: 2731000,
		label: 'Fabricação cabos fibra óptica'
	},
	{
		id: 2732000,
		label: 'Fabric outros fios e cabos eléctricos e electrón.'
	},
	{
		id: 2733000,
		label: 'Fabric acessórios instal. eléctricas baixa tensão'
	},
	{
		id: 2740000,
		label: 'Fabric lâmpadas eléctricas e outro equip. ilumin.'
	},
	{
		id: 2751000,
		label: 'Fabricação electrodomésticos'
	},
	{
		id: 2752000,
		label: 'Fabric. aparelhos não eléctricos p/ uso doméstico'
	},
	{
		id: 2790000,
		label: 'Fabricação outro equipamento eléctrico'
	},
	{
		id: 2811000,
		label: 'Fabric. motores e turbinas'
	},
	{
		id: 2812000,
		label: 'Fabricação de equipamento hidráulico e pneumático'
	},
	{
		id: 2813000,
		label: 'Fabricação outras bombas e compressores'
	},
	{
		id: 2814000,
		label: 'Fabricação outras torneiras e válvulas'
	},
	{
		id: 2815000,
		label: 'Fabric rolamentos, engrenagens e órg. transmissão'
	},
	{
		id: 2821000,
		label: 'Fabricação fornos e queimadores'
	},
	{
		id: 2822100,
		label: 'Fabric ascensores, escadas e passadeiras rolantes'
	},
	{
		id: 2822200,
		label: 'Fabric equipamentos elevação e movimentação, n.e.'
	},
	{
		id: 2823000,
		label: 'Fabric. máq. e equip. escritório'
	},
	{
		id: 2824000,
		label: 'Fabricação máq.-ferramentas portáteis com motor'
	},
	{
		id: 2825000,
		label: 'Fabric. equip. não doméstico refrig. e ventilação'
	},
	{
		id: 2829100,
		label: 'Fabricação máquinas acondicionamento e embalagem'
	},
	{
		id: 2829200,
		label: 'Fabric. balanças e outro equipamento para pesagem'
	},
	{
		id: 2829300,
		label: 'Fabricação outras máq. diversas uso geral, n.e.'
	},
	{
		id: 2830000,
		label: 'Fabric máq e tratores agric, pecuária e silvicult'
	},
	{
		id: 2841000,
		label: 'Fabricação máquinas-ferramentas para metais'
	},
	{
		id: 2849000,
		label: 'Fabricação outras máquinas-ferramentas, n.e.'
	},
	{
		id: 2891000,
		label: 'Fabricação máquinas para a metalurgia'
	},
	{
		id: 2892000,
		label: 'Fabric. máq. indúst. extractivas e construção'
	},
	{
		id: 2893000,
		label: 'Fabric máq. indúst. alimentares, bebidas e tabaco'
	},
	{
		id: 2894000,
		label: 'Fabric. máq. indúst. têxtil, do vestuário e couro'
	},
	{
		id: 2895000,
		label: 'Fabric. Máq. para as indústrias do papel e cartão'
	},
	{
		id: 2896000,
		label: 'Fabric. máq. para as indúst. plástico e borracha'
	},
	{
		id: 2899100,
		label: 'Fabric máq indúst mat construção, cerâmica, vidro'
	},
	{
		id: 2899200,
		label: 'Fabricação outras máq. diversas uso específ., n.e'
	},
	{
		id: 2910000,
		label: 'Fabricação veículos automóveis'
	},
	{
		id: 2920000,
		label: 'Fabricação carroçarias, reboques e semi-reboques'
	},
	{
		id: 2931000,
		label: 'Fabric equip eléctrico e electrónico p/ automóv'
	},
	{
		id: 2932000,
		label: 'Fabric componentes e acess p/ veículos automóveis'
	},
	{
		id: 3011100,
		label: 'Const. embarcações metálicas e estrut. flutuantes'
	},
	{
		id: 3011200,
		label: 'Const embarcações não metál, exc recreio/desporto'
	},
	{
		id: 3012000,
		label: 'Construção embarcações recreio e desporto'
	},
	{
		id: 3020000,
		label: 'Fabric material circulante para caminhos-de-ferro'
	},
	{
		id: 3030000,
		label: 'Fabric aeronaves, veíc. espaciais e equip. relac.'
	},
	{
		id: 3040000,
		label: 'Fabricação veículos militares combate'
	},
	{
		id: 3091000,
		label: 'Fabricação de motociclos'
	},
	{
		id: 3092000,
		label: 'Fabricação de bicicletas e veículos para inválidos'
	},
	{
		id: 3099000,
		label: 'Fabricação outro equipamento transporte, n.e.'
	},
	{
		id: 3101000,
		label: 'Fabricação mobiliário para escritório e comércio'
	},
	{
		id: 3102000,
		label: 'Fabricação mobiliário cozinha'
	},
	{
		id: 3103000,
		label: 'Fabricação de colchoaria'
	},
	{
		id: 3109100,
		label: 'Fabricação mobiliário madeira para outros fins'
	},
	{
		id: 3109200,
		label: 'Fabricação de mobiliário metálico para outros fins'
	},
	{
		id: 3109300,
		label: 'Fabric mobiliário outros materiais p/ outros fins'
	},
	{
		id: 3109400,
		label: 'Ativ. acabamento mobiliário'
	},
	{
		id: 3211000,
		label: 'Cunhagem de moedas'
	},
	{
		id: 3212100,
		label: 'Fabricação de filigranas'
	},
	{
		id: 3212200,
		label: 'Fabric. artigos joalharia e artigos ourivesaria'
	},
	{
		id: 3212300,
		label: 'Trabalho diamantes e outras pedras preciosas'
	},
	{
		id: 3213000,
		label: 'Fabricação de bijutarias'
	},
	{
		id: 3220000,
		label: 'Fabricação instrumentos musicais'
	},
	{
		id: 3230000,
		label: 'Fabricação artigos desporto'
	},
	{
		id: 3240000,
		label: 'Fabricação jogos e brinquedos'
	},
	{
		id: 3250100,
		label: 'Fabricação material óptico oftálmico'
	},
	{
		id: 3250200,
		label: 'Fabric. mat. ortopédico, próteses e inst. Médicos'
	},
	{
		id: 3291000,
		label: 'Fabricação vassouras, escovas e pincéis'
	},
	{
		id: 3299100,
		label: 'Fabricação canetas, lápis e similares'
	},
	{
		id: 3299200,
		label: 'Fabricação fechos correr, botões e similares'
	},
	{
		id: 3299300,
		label: 'Fabricação guarda-sóis e chapéus chuva'
	},
	{
		id: 3299400,
		label: 'Fabricação equipamento protecção e segurança'
	},
	{
		id: 3299500,
		label: 'Fabricação caixões mortuários em madeira'
	},
	{
		id: 3299600,
		label: 'Outras indústrias transformadoras diversas, n.e.'
	},
	{
		id: 3311000,
		label: 'Reparação e manut. prod. metál. (exc máq e equip)'
	},
	{
		id: 3312000,
		label: 'Reparação e manutenção de máquinas e equipamentos'
	},
	{
		id: 3313000,
		label: 'Reparação e manutenção equip electrónico e óptico'
	},
	{
		id: 3314000,
		label: 'Reparação e manutenção equipamento eléctrico'
	},
	{
		id: 3315000,
		label: 'Reparação e manutenção embarcações'
	},
	{
		id: 3316000,
		label: 'Reparação e manut. aeronaves e veículos espaciais'
	},
	{
		id: 3317000,
		label: 'Reparação e manutenção outro equip. transporte'
	},
	{
		id: 3319000,
		label: 'Reparação e manutenção outro equipamento'
	},
	{
		id: 3320000,
		label: 'Instalação máquinas e equipamentos industriais'
	},
	{
		id: 3511100,
		label: 'Produção electricidade origem hídrica'
	},
	{
		id: 3511200,
		label: 'Produção electricidade origem térmica'
	},
	{
		id: 3511300,
		label: 'Prod. eletric. origem eólica, geotérmica, solar'
	},
	{
		id: 3512000,
		label: 'Transporte de electricidade'
	},
	{
		id: 3513000,
		label: 'Distribuição de electricidade'
	},
	{
		id: 3514000,
		label: 'Comércio de electricidade'
	},
	{
		id: 3521000,
		label: 'Produção de gás'
	},
	{
		id: 3522000,
		label: 'Distribuição de combustíveis gasosos por condutas'
	},
	{
		id: 3523000,
		label: 'Comércio de gás por condutas'
	},
	{
		id: 3530100,
		label: 'Prod. distrib. vapor, água e ar frio por conduta'
	},
	{
		id: 3530200,
		label: 'Produção de gelo'
	},
	{
		id: 3600100,
		label: 'Captação e tratamento de água'
	},
	{
		id: 3600200,
		label: 'Distribuição de água'
	},
	{
		id: 3700100,
		label: 'Recolha e drenagem águas residuais'
	},
	{
		id: 3700200,
		label: 'Tratamento de águas residuais'
	},
	{
		id: 3811100,
		label: 'Recolha de resíduos inertes'
	},
	{
		id: 3811200,
		label: 'Recolha outros resíduos não perigosos'
	},
	{
		id: 3812000,
		label: 'Recolha de resíduos perigosos'
	},
	{
		id: 3821100,
		label: 'Tratamento e eliminação resíduos inertes'
	},
	{
		id: 3821200,
		label: 'Tratamento e elimin. outros resíd. não perigosos'
	},
	{
		id: 3822000,
		label: 'Tratamento e eliminação resíduos perigosos'
	},
	{
		id: 3831100,
		label: 'Desmantelamento veículos automóveis, em fim vida'
	},
	{
		id: 3831200,
		label: 'Desmantelamento de equip elét, fim de vida'
	},
	{
		id: 3831300,
		label: 'Desmantelamento outros equip. e bens, em fim vida'
	},
	{
		id: 3832100,
		label: 'Valorização resíduos metálicos'
	},
	{
		id: 3832200,
		label: 'Valorização resíduos não metálicos'
	},
	{
		id: 3900000,
		label: 'Descontaminação e Ativ. similares'
	},
	{
		id: 4110000,
		label: 'Promoção imobiliária (desenv projectos edifícios)'
	},
	{
		id: 4120000,
		label: 'Construção edifícios (residenciais e não resid.)'
	},
	{
		id: 4211000,
		label: 'Construção estradas e pistas aeroportos'
	},
	{
		id: 4212000,
		label: 'Construção de vias férreas'
	},
	{
		id: 4213000,
		label: 'Construção de pontes e túneis'
	},
	{
		id: 4221000,
		label: 'Construção redes transp. águas, esgotos e fluídos'
	},
	{
		id: 4222000,
		label: 'Const. redes distribuição eletric., redes telecom'
	},
	{
		id: 4291000,
		label: 'Engenharia hidráulica'
	},
	{
		id: 4299000,
		label: 'Construção outras obras engenharia civil, n.e.'
	},
	{
		id: 4311000,
		label: 'Demolição'
	},
	{
		id: 4312000,
		label: 'Preparação dos locais construção'
	},
	{
		id: 4313000,
		label: 'Perfurações e sondagens'
	},
	{
		id: 4321000,
		label: 'Instalação eléctrica'
	},
	{
		id: 4322100,
		label: 'Instalação de canalizações'
	},
	{
		id: 4322200,
		label: 'Instalação de climatização'
	},
	{
		id: 4329000,
		label: 'Outras instalações em construções'
	},
	{
		id: 4331000,
		label: 'Estucagem'
	},
	{
		id: 4332000,
		label: 'Montagem trabalhos carpintaria e caixilharia'
	},
	{
		id: 4333000,
		label: 'Revestimento pavimentos e paredes'
	},
	{
		id: 4334000,
		label: 'Pintura e colocação de vidros'
	},
	{
		id: 4339000,
		label: 'Outras Ativ. acabamento em edifícios'
	},
	{
		id: 4391000,
		label: 'Ativ. colocação coberturas'
	},
	{
		id: 4399100,
		label: 'Aluguer equip. const. e demolição, com operador'
	},
	{
		id: 4399200,
		label: 'Outras ativ. especializ construção diversas, n.e.'
	},
	{
		id: 4511000,
		label: 'Comércio veículos automóveis ligeiros'
	},
	{
		id: 4519000,
		label: 'Comércio outros veículos automóveis'
	},
	{
		id: 4520000,
		label: 'Manutenção e reparação veículos automóveis'
	},
	{
		id: 4531000,
		label: 'Comércio grosso peças e acess. veíc. automóveis'
	},
	{
		id: 4532000,
		label: 'Comércio retalho peças e acess veíc. automóveis'
	},
	{
		id: 4540100,
		label: 'Comércio grosso e retalho motocic., peças e acess'
	},
	{
		id: 4540200,
		label: 'Manut. e reparação motociclos, peças e acessórios'
	},
	{
		id: 4611000,
		label: 'Agentes com. grosso matérias-primas agríc/têxteis'
	},
	{
		id: 4612000,
		label: 'Agentes com.grosso combustíveis, minérios, metais'
	},
	{
		id: 4613000,
		label: 'Agentes comércio grosso madeira e mat. construção'
	},
	{
		id: 4614000,
		label: 'Agentes com. grosso máq. e equip. industrial'
	},
	{
		id: 4615000,
		label: 'Agentes com. grosso mobiliário e ferragens'
	},
	{
		id: 4616000,
		label: 'Agentes com. grosso têxteis, vest, calçado, couro'
	},
	{
		id: 4617000,
		label: 'Agentes com grosso prod aliment, bebidas e tabaco'
	},
	{
		id: 4618000,
		label: 'Agentes especializ comércio grosso outros produto'
	},
	{
		id: 4619000,
		label: 'Agentes comércio por grosso misto sem predomin.'
	},
	{
		id: 4621100,
		label: 'Comércio por grosso alimentos para animais'
	},
	{
		id: 4621200,
		label: 'Comércio por grosso tabaco em bruto'
	},
	{
		id: 4621300,
		label: 'Comércio por grosso cortiça em bruto'
	},
	{
		id: 4621400,
		label: 'Com.grosso cereais, sementes, leguminosas, oleagi'
	},
	{
		id: 4622000,
		label: 'Comércio por grosso flores e plantas'
	},
	{
		id: 4623000,
		label: 'Comércio por grosso animais vivos'
	},
	{
		id: 4624000,
		label: 'Comércio por grosso peles e couro'
	},
	{
		id: 4631100,
		label: 'Comércio grosso fruta e prod. hort, exceto batata'
	},
	{
		id: 4631200,
		label: 'Comércio por grosso de batata'
	},
	{
		id: 4632000,
		label: 'Comércio por grosso carne e produtos à base carne'
	},
	{
		id: 4633100,
		label: 'Comércio por grosso leite, seus derivados e ovos'
	},
	{
		id: 4633200,
		label: 'Comércio grosso azeite, óleos e gorduras aliment.'
	},
	{
		id: 4634100,
		label: 'Comércio por grosso bebidas alcoólicas'
	},
	{
		id: 4634200,
		label: 'Comércio por grosso bebidas não alcoólicas'
	},
	{
		id: 4635000,
		label: 'Comércio por grosso de tabaco'
	},
	{
		id: 4636100,
		label: 'Comércio por grosso de açúcar'
	},
	{
		id: 4636200,
		label: 'Comércio grosso chocolate e produtos confeitaria'
	},
	{
		id: 4637000,
		label: 'Comércio por grosso café, chá, cacau e especiaria'
	},
	{
		id: 4638100,
		label: 'Comércio por grosso peixe, crustáceos e moluscos'
	},
	{
		id: 4638200,
		label: 'Comércio grosso outros produtos alimentares, n.e.'
	},
	{
		id: 4639000,
		label: 'Com grosso não espec prod aliment, bebidas tabaco'
	},
	{
		id: 4641000,
		label: 'Comércio por grosso de têxteis'
	},
	{
		id: 4642100,
		label: 'Comércio por grosso vestuário e acessórios'
	},
	{
		id: 4642200,
		label: 'Comércio por grosso de calçado'
	},
	{
		id: 4643000,
		label: 'Comércio grosso eletro., aparelhos rádio e telev.'
	},
	{
		id: 4644100,
		label: 'Comércio por grosso louças em cerâmica e em vidro'
	},
	{
		id: 4644200,
		label: 'Comércio por grosso produtos limpeza'
	},
	{
		id: 4645000,
		label: 'Comércio por grosso perfumes e produtos higiene'
	},
	{
		id: 4646000,
		label: 'Comércio por grosso produtos farmacêuticos'
	},
	{
		id: 4647000,
		label: 'Com grosso móveis uso doméstico, artig iluminação'
	},
	{
		id: 4648000,
		label: 'Comércio grosso relógios, ourivesaria e joalharia'
	},
	{
		id: 4649100,
		label: 'Comércio por grosso artigos papelaria'
	},
	{
		id: 4649200,
		label: 'Comércio por grosso de livros, revistas e jornais'
	},
	{
		id: 4649300,
		label: 'Comércio grosso brinquedos, jogos, artig desporto'
	},
	{
		id: 4649400,
		label: 'Outro comércio por grosso bens consumo, n.e.'
	},
	{
		id: 4651000,
		label: 'Com. grosso computadores, equip. periféricos'
	},
	{
		id: 4652000,
		label: 'Com.grosso equip. elect., telecom. e suas partes'
	},
	{
		id: 4661000,
		label: 'Comércio por grosso máquinas e equip., agrícolas'
	},
	{
		id: 4662000,
		label: 'Comércio por grosso máquinas-ferramentas'
	},
	{
		id: 4663000,
		label: 'Com grosso máq indúst extractiva, const eng civil'
	},
	{
		id: 4664000,
		label: 'Com. grosso máq. indúst. têxtil, máq. Costura'
	},
	{
		id: 4665000,
		label: 'Comércio por grosso mobiliário escritório'
	},
	{
		id: 4666000,
		label: 'Comércio grosso outras máq. e material escritório'
	},
	{
		id: 4669000,
		label: 'Comércio por grosso outras máquinas e equipamento'
	},
	{
		id: 4671100,
		label: 'Comércio por grosso produtos petrolíferos'
	},
	{
		id: 4671200,
		label: 'Com grosso combustíveis sólidos, líquidos, gasoso'
	},
	{
		id: 4672000,
		label: 'Comércio por grosso minérios e metais'
	},
	{
		id: 4673100,
		label: 'Comércio grosso madeira em bruto e produt. deriv.'
	},
	{
		id: 4673200,
		label: 'Com. grosso materiais construção (exceto madeira)'
	},
	{
		id: 4674000,
		label: 'Com.grosso ferragens e ferramentas'
	},
	{
		id: 4675000,
		label: 'Comércio por grosso produtos químicos'
	},
	{
		id: 4676100,
		label: 'Com grosso fibras têxteis naturais, artif e sinté'
	},
	{
		id: 4676200,
		label: 'Comércio por grosso outros bens intermédios, n.e.'
	},
	{
		id: 4677100,
		label: 'Comércio grosso sucatas e desperdícios metálicos'
	},
	{
		id: 4677200,
		label: 'Comércio grosso desperdíc têxteis, cartão e papel'
	},
	{
		id: 4677300,
		label: 'Comércio por grosso desperdícios materiais, n.e.'
	},
	{
		id: 4690000,
		label: 'Comércio por grosso não especializado'
	},
	{
		id: 4711100,
		label: 'Comércio retalho em supermercados e hipermercados'
	},
	{
		id: 4711200,
		label: 'Com. retalho não especializ.., com predom aliment'
	},
	{
		id: 4719100,
		label: 'Com. retalho não especializados, grandes armazéns'
	},
	{
		id: 4719200,
		label: 'Com. retalho não especializ, sem predom. alimento'
	},
	{
		id: 4721000,
		label: 'Com retalho frutas e prod hortic, estab especial'
	},
	{
		id: 4722000,
		label: 'Com. retalho carne e prod à base carne, estab esp'
	},
	{
		id: 4723000,
		label: 'Com. retalho peixe, crustáceos, estab. espec.'
	},
	{
		id: 4724000,
		label: 'Com. retalho pão, prod. pastelaria e confeitaria'
	},
	{
		id: 4725000,
		label: 'Comércio retalho bebidas, em estab. especializ.'
	},
	{
		id: 4726000,
		label: 'Comércio retalho tabaco, em estab. especializados'
	},
	{
		id: 4729100,
		label: 'Comércio retalho leite e deriv., estab especializ'
	},
	{
		id: 4729200,
		label: 'Com. retalho prod. aliment, naturais e dietéticos'
	},
	{
		id: 4729300,
		label: 'Outro comércio retalho prod. alimentares, n.e.'
	},
	{
		id: 4730000,
		label: 'Com. retalho combustível veículos motor'
	},
	{
		id: 4741000,
		label: 'Com. retalho computadores e unidades periféricas'
	},
	{
		id: 4742000,
		label: 'Comércio retalho equip. telecom, estab especializ'
	},
	{
		id: 4743000,
		label: 'Comércio ret. equip. audiov, em estab. Especializ'
	},
	{
		id: 4751000,
		label: 'Comércio retalho têxteis, em estab. especializ.'
	},
	{
		id: 4752100,
		label: 'Com. retalho ferragens e vidro plano, estab espec'
	},
	{
		id: 4752200,
		label: 'Com. retalho tintas, vernizes e prod. simil.'
	},
	{
		id: 4752300,
		label: 'Com. retalho material bricolage, equip. sanitário'
	},
	{
		id: 4753000,
		label: 'Com. retalho carpetes, cortinados, revestimentos'
	},
	{
		id: 4754000,
		label: 'Comércio retalho eletro., em estab. especializ.'
	},
	{
		id: 4759100,
		label: 'Com. retalho mobiliário e artigos iluminação'
	},
	{
		id: 4759200,
		label: 'Com. retalho louças, cutelaria, art uso doméstico'
	},
	{
		id: 4759300,
		label: 'Com. retalho outros artigos para o lar, n.e.,'
	},
	{
		id: 4761000,
		label: 'Comércio retalho livros, em estab. especializados'
	},
	{
		id: 4762000,
		label: 'Com retalho jornais, revistas e artigos papelaria'
	},
	{
		id: 4763000,
		label: 'Com. retalho discos, CD, DVD, cassetes e simil.'
	},
	{
		id: 4764000,
		label: 'Com. retalho artigos desporto, campismo e lazer'
	},
	{
		id: 4765000,
		label: 'Comércio retalho jogos e brinq., em estab. espec.'
	},
	{
		id: 4771100,
		label: 'Comércio retalho vest. adultos, estab. Especializ'
	},
	{
		id: 4771200,
		label: 'Com. retalho vestuário para bebés e crianças'
	},
	{
		id: 4772100,
		label: 'Comércio retalho calçado, em estab. especializ.'
	},
	{
		id: 4772200,
		label: 'Com. retalho marroquinaria e artigos viagem'
	},
	{
		id: 4773000,
		label: 'Com. retalho prod. Farmacêutic., estab especializ'
	},
	{
		id: 4774000,
		label: 'Com. retalho prod. médicos e ortop., estab. Espec'
	},
	{
		id: 4775000,
		label: 'Com retalho prod cosméticos e higiene, estab espe'
	},
	{
		id: 4776100,
		label: 'Com. retalho flores, plantas, sementes, fertiliz.'
	},
	{
		id: 4776200,
		label: 'Com retalho animais companhia e respet. alimentos'
	},
	{
		id: 4777000,
		label: 'Com. retalho relógios, ourivesaria e joalharia'
	},
	{
		id: 4778100,
		label: 'Com. retalho máq. e outro material escritório'
	},
	{
		id: 4778200,
		label: 'Com. retalho material óptico, fotográfico'
	},
	{
		id: 4778300,
		label: 'Com. retalho combustíveis para uso doméstico'
	},
	{
		id: 4778400,
		label: 'Com. retalho produtos novos, estab. espec., n.e.'
	},
	{
		id: 4779000,
		label: 'Comércio retalho artig. segunda mão, estab. Espec'
	},
	{
		id: 4781000,
		label: 'Com retalho feiras e unid móveis, alimentos'
	},
	{
		id: 4782000,
		label: 'Com retalho feiras e unid móveis, têxteis e simil'
	},
	{
		id: 4789000,
		label: 'Com retalho feiras e unid móveis, outros prod.'
	},
	{
		id: 4791000,
		label: 'Comércio retalho por correspond. ou via Internet'
	},
	{
		id: 4799000,
		label: 'Com. retalho não efectuado unidades móveis venda'
	},
	{
		id: 4910000,
		label: 'Transp. interurbano passag. por caminho-de-ferro'
	},
	{
		id: 4920000,
		label: 'Transporte mercadorias por caminhos-de-ferro'
	},
	{
		id: 4931000,
		label: 'Transp. terrestres, urbanos e suburbanos, passag.'
	},
	{
		id: 4932000,
		label: 'Transporte ocasional passageiros veículos ligeiro'
	},
	{
		id: 4939100,
		label: 'Transporte interurbano em autocarros'
	},
	{
		id: 4939200,
		label: 'Outros transp. terrestres passag. diversos, n.e'
	},
	{
		id: 4941000,
		label: 'Transporte rodov. nacional e intern. mercadorias'
	},
	{
		id: 4941001,
		label: 'Transporte rodoviário nacional mercadorias'
	},
	{
		id: 4941002,
		label: 'Transporte rodoviário internacional de mercadorias'
	},
	{
		id: 4941003,
		label: 'Transportes rodoviários de mercadorias perigosas'
	},
	{
		id: 4942000,
		label: 'Ativ. mudanças, por via rodoviária'
	},
	{
		id: 4950000,
		label: 'Transportes por oleodutos ou gasodutos'
	},
	{
		id: 5010100,
		label: 'Transportes marítimos não costeiros de passageiros'
	},
	{
		id: 5010200,
		label: 'Transportes costeiros e locais passageiros'
	},
	{
		id: 5020000,
		label: 'Transportes marítimos mercadorias'
	},
	{
		id: 5030000,
		label: 'Transportes passag. p/ vias navegáveis interiores'
	},
	{
		id: 5040000,
		label: 'Transp. mercadorias por vias navegáveis interiore'
	},
	{
		id: 5110000,
		label: 'Transportes aéreos passageiros'
	},
	{
		id: 5121000,
		label: 'Transportes aéreos mercadorias'
	},
	{
		id: 5122000,
		label: 'Transportes espaciais'
	},
	{
		id: 5210100,
		label: 'Armazenagem frigorífica'
	},
	{
		id: 5210200,
		label: 'Armazenagem não frigorífica'
	},
	{
		id: 5221100,
		label: 'Gestão infra-estruturas dos transportes terrestre'
	},
	{
		id: 5221200,
		label: 'Assistência a veículos na estrada'
	},
	{
		id: 5221300,
		label: 'Outras ativ auxiliares dos transportes terrestres'
	},
	{
		id: 5222000,
		label: 'Ativ. auxiliares dos transportes por água'
	},
	{
		id: 5223000,
		label: 'Ativ. auxiliares dos transportes aéreos'
	},
	{
		id: 5224000,
		label: 'Manuseamento de carga'
	},
	{
		id: 5229100,
		label: 'Organização do transporte'
	},
	{
		id: 5229200,
		label: 'Agentes aduaneiros e similares apoio ao transport'
	},
	{
		id: 5310000,
		label: 'Ativ. postais suj. a obrig. do serviço universal'
	},
	{
		id: 5320000,
		label: 'Outras Ativ. postais e courier'
	},
	{
		id: 5511100,
		label: 'Hotéis com restaurante'
	},
	{
		id: 5511200,
		label: 'Pensões com restaurante'
	},
	{
		id: 5511300,
		label: 'Estalagens com restaurante'
	},
	{
		id: 5511400,
		label: 'Pousadas com restaurante'
	},
	{
		id: 5511500,
		label: 'Motéis com restaurante'
	},
	{
		id: 5511600,
		label: 'Hotéis-Apartamentos com restaurante'
	},
	{
		id: 5511700,
		label: 'Aldeamentos turísticos com restaurante'
	},
	{
		id: 5511800,
		label: 'Apartamentos turísticos com restaurante'
	},
	{
		id: 5511900,
		label: 'Outros estabelecimentos hoteleiros com restaurante'
	},
	{
		id: 5512100,
		label: 'Hotéis sem restaurante'
	},
	{
		id: 5512200,
		label: 'Pensões sem restaurante'
	},
	{
		id: 5512300,
		label: 'Apartamentos turísticos sem restaurante'
	},
	{
		id: 5512400,
		label: 'Outros estabelecimentos hoteleiros sem restaurante'
	},
	{
		id: 5520100,
		label: 'Alojamento mobilado para turistas'
	},
	{
		id: 5520200,
		label: 'Turismo no espaço rural'
	},
	{
		id: 5520300,
		label: 'Colónias e campos de férias'
	},
	{
		id: 5520400,
		label: 'Outros locais alojamento curta duração'
	},
	{
		id: 5530000,
		label: 'Parques campismo e caravanismo'
	},
	{
		id: 5590000,
		label: 'Outros locais de alojamento'
	},
	{
		id: 5610100,
		label: 'Restaurantes tipo tradicional sem serviço entrega'
	},
	{
		id: 5610101,
		label: 'Restaurantes tipo tradicional com serviço entrega'
	},
	{
		id: 5610200,
		label: 'Restaurantes com lugares ao balcão'
	},
	{
		id: 5610201,
		label: 'Restaurantes c/ lug. balcão e c/ serviço entrega'
	},
	{
		id: 5610300,
		label: 'Restaurantes sem serviço mesa'
	},
	{
		id: 5610301,
		label: 'Restaurantes sem serviço mesa com serviço entrega'
	},
	{
		id: 5610400,
		label: 'Restaurantes típicos'
	},
	{
		id: 5610401,
		label: 'Restaurantes típicos e com serviço entrega'
	},
	{
		id: 5610500,
		label: 'Restaurantes com espaço dança'
	},
	{
		id: 5610600,
		label: 'Confeção refeições prontas a levar para casa'
	},
	{
		id: 5610601,
		label: 'Confeção refeições prontas levar c/ serv entrega'
	},
	{
		id: 5610700,
		label: 'Restaurantes, n.e. (inclui ativ. em meios móveis)'
	},
	{
		id: 5610701,
		label: 'Restaurantes n.e. (incl meios móveis) com entrega'
	},
	{
		id: 5621000,
		label: 'Fornecimento refeições para eventos'
	},
	{
		id: 5629000,
		label: 'Outras Ativ. serviço refeições'
	},
	{
		id: 5629001,
		label: 'Outras Ativ. serviço refeições com serv. entrega'
	},
	{
		id: 5630100,
		label: 'Cafés'
	},
	{
		id: 5630200,
		label: 'Bares'
	},
	{
		id: 5630300,
		label: 'Pastelarias e casas de chá'
	},
	{
		id: 5630400,
		label: 'Outros estabelecimentos de bebidas sem espectáculo'
	},
	{
		id: 5630500,
		label: 'Estabelecimentos bebidas com espaço dança'
	},
	{
		id: 5630600,
		label: 'Estabelecimentos bebidas itinerantes'
	},
	{
		id: 5811000,
		label: 'Edição de livros'
	},
	{
		id: 5812000,
		label: 'Edição listas destinadas a consulta'
	},
	{
		id: 5813000,
		label: 'Edição de jornais'
	},
	{
		id: 5814000,
		label: 'Edição revistas e outras publicações periódicas'
	},
	{
		id: 5819000,
		label: 'Outras Atividades de edição'
	},
	{
		id: 5821000,
		label: 'Edição de jogos de computador'
	},
	{
		id: 5829000,
		label: 'Edição outros programas informáticos'
	},
	{
		id: 5911000,
		label: 'Produção filmes, vídeos e programas televisão'
	},
	{
		id: 5912000,
		label: 'Ativ. técn pós-prod filmes, vídeos e programas tv'
	},
	{
		id: 5913000,
		label: 'Distribuição filmes, vídeos e programas televisão'
	},
	{
		id: 5914000,
		label: 'Projecção filmes e vídeos'
	},
	{
		id: 5920000,
		label: 'Ativ. gravação som e edição música'
	},
	{
		id: 6010000,
		label: 'Atividades de rádio'
	},
	{
		id: 6020000,
		label: 'Atividades de televisão'
	},
	{
		id: 6110000,
		label: 'Ativ. telecomunicações por fio'
	},
	{
		id: 6120000,
		label: 'Ativ. telecomunicações sem fio'
	},
	{
		id: 6130000,
		label: 'Ativ. telecomunicações por satélite'
	},
	{
		id: 6190000,
		label: 'Outras Ativ. telecomunicações'
	},
	{
		id: 6201000,
		label: 'Ativ. programação informática'
	},
	{
		id: 6202000,
		label: 'Ativ. consultoria em informática'
	},
	{
		id: 6203000,
		label: 'Gestão e exploração equipamento informático'
	},
	{
		id: 6209000,
		label: 'Outras ativ. relac. c/ tecn informação e informát'
	},
	{
		id: 6311000,
		label: 'Ativ processamento dados, domiciliação informação'
	},
	{
		id: 6312000,
		label: 'Portais Web'
	},
	{
		id: 6391000,
		label: 'Atividades de agências de notícias'
	},
	{
		id: 6399000,
		label: 'Outras Ativ. dos serviços informação, n.e.'
	},
	{
		id: 6411000,
		label: 'Banco central'
	},
	{
		id: 6419000,
		label: 'Outra intermediação monetária'
	},
	{
		id: 6420100,
		label: 'Ativ. soc. gestoras particip. sociais financeiras'
	},
	{
		id: 6420200,
		label: 'Ativ. soc. gest. particip sociais não financeiras'
	},
	{
		id: 6430000,
		label: 'Trusts, fundos e  entidades financeiras similares'
	},
	{
		id: 6491000,
		label: 'Atividades de locação financeira'
	},
	{
		id: 6492100,
		label: 'Ativ. das instituições financeiras crédito'
	},
	{
		id: 6492200,
		label: 'Ativ. das soc financeiras p/ aquisições a crédito'
	},
	{
		id: 6492300,
		label: 'Outras Ativ. crédito, n.e.'
	},
	{
		id: 6499100,
		label: 'Atividades de factoring'
	},
	{
		id: 6499200,
		label: 'Outras ativ. serviços financeiros diversos'
	},
	{
		id: 6511100,
		label: 'Seguros de vida'
	},
	{
		id: 6511200,
		label: 'Outras Ativ. complementares segurança social'
	},
	{
		id: 6512000,
		label: 'Seguros não vida'
	},
	{
		id: 6520000,
		label: 'Resseguros'
	},
	{
		id: 6530000,
		label: 'Fundos pensões e regimes profissionais complement'
	},
	{
		id: 6611000,
		label: 'Adm. mercados financeiros'
	},
	{
		id: 6612000,
		label: 'Ativ. negoc. conta terceiros valores mobiliários'
	},
	{
		id: 6619000,
		label: 'Outras ativ. auxiliares serviços financeiros'
	},
	{
		id: 6621000,
		label: 'Ativ. avaliação riscos e danos'
	},
	{
		id: 6622000,
		label: 'Atividades de mediadores de seguros'
	},
	{
		id: 6629000,
		label: 'Outras Ativ. auxiliares seguros e fundos pensões'
	},
	{
		id: 6630000,
		label: 'Atividades de gestão de fundos'
	},
	{
		id: 6810000,
		label: 'Compra e venda bens imobiliários'
	},
	{
		id: 6820000,
		label: 'Arrendamento bens imobiliários'
	},
	{
		id: 6831100,
		label: 'Ativ. mediação imobiliária'
	},
	{
		id: 6831200,
		label: 'Ativ. angariação imobiliária'
	},
	{
		id: 6831300,
		label: 'Ativ. avaliação imobiliária'
	},
	{
		id: 6832100,
		label: 'Adm. imóveis por conta outrem'
	},
	{
		id: 6832200,
		label: 'Administração de condomínios'
	},
	{
		id: 6910100,
		label: 'Atividades jurídicas'
	},
	{
		id: 6910200,
		label: 'Ativ. dos cartórios notariais'
	},
	{
		id: 6920000,
		label: 'Ativ. contabilidae auditoria; consultoria fiscal'
	},
	{
		id: 7010000,
		label: 'Atividades das sedes sociais'
	},
	{
		id: 7021000,
		label: 'Ativ. relações públicas e comunicação'
	},
	{
		id: 7022000,
		label: 'Outras ativ consultoria p/ os negócios e a gestão'
	},
	{
		id: 7111000,
		label: 'Atividades de arquitectura'
	},
	{
		id: 7112000,
		label: 'Ativ. engenharia e técnicas afins'
	},
	{
		id: 7120000,
		label: 'Ativ. ensaios e análises técnicas'
	},
	{
		id: 7211000,
		label: 'Investigação e desenvolvimento em biotecnologia'
	},
	{
		id: 7219000,
		label: 'Outra investig. desenv. ciências físicas naturais'
	},
	{
		id: 7220000,
		label: 'Investigação e desenv. ciências sociais e humanas'
	},
	{
		id: 7311000,
		label: 'Agências de publicidade'
	},
	{
		id: 7311001,
		label: 'Agências publicidade com manut. mobiliário urbano'
	},
	{
		id: 7312000,
		label: 'Ativ. representação nos meios comunicação'
	},
	{
		id: 7320000,
		label: 'Estudos mercado e sondagens opinião'
	},
	{
		id: 7410000,
		label: 'Atividades de design'
	},
	{
		id: 7420000,
		label: 'Atividades fotográficas'
	},
	{
		id: 7430000,
		label: 'Ativ. tradução e interpretação'
	},
	{
		id: 7490000,
		label: 'Outras ativ. consult., científ., técn. e similar'
	},
	{
		id: 7500000,
		label: 'Atividades veterinárias'
	},
	{
		id: 7711000,
		label: 'Aluguer veículos automóveis ligeiros'
	},
	{
		id: 7712000,
		label: 'Aluguer veículos automóveis pesados'
	},
	{
		id: 7721000,
		label: 'Aluguer bens recreativos e desportivos'
	},
	{
		id: 7722000,
		label: 'Aluguer videocassetes e discos'
	},
	{
		id: 7729000,
		label: 'Aluguer outros bens uso pessoal e doméstico'
	},
	{
		id: 7731000,
		label: 'Aluguer máquinas e equipamentos agrícolas'
	},
	{
		id: 7732000,
		label: 'Aluguer máq. e equip p/ a construção e eng. civil'
	},
	{
		id: 7733000,
		label: 'Aluguer máq. e equip. escritório (inc. comput.)'
	},
	{
		id: 7734000,
		label: 'Aluguer meios transporte marítimo e fluvial'
	},
	{
		id: 7735000,
		label: 'Aluguer meios transporte aéreo'
	},
	{
		id: 7739000,
		label: 'Aluguer de outras máquinas e equipamentos, n.e.'
	},
	{
		id: 7740000,
		label: 'Locação proprieda intelectual, exc direitos autor'
	},
	{
		id: 7810000,
		label: 'Ativ. das empresas selecção e colocação pessoal'
	},
	{
		id: 7820000,
		label: 'Ativ. das empresas trabalho temporário'
	},
	{
		id: 7830000,
		label: 'Outro fornecimento recursos humanos'
	},
	{
		id: 7911000,
		label: 'Ativ. das agências viagem'
	},
	{
		id: 7912000,
		label: 'Ativ. dos operadores turísticos'
	},
	{
		id: 7990000,
		label: 'Outros serviços reservas e Ativ. relacionadas'
	},
	{
		id: 8010000,
		label: 'Transporte valores ou serviços guarda-costas'
	},
	{
		id: 8010001,
		label: 'Ativ. segurança privada, n.e.'
	},
	{
		id: 8020000,
		label: 'Ativ. relacionadas com sistemas segurança'
	},
	{
		id: 8030000,
		label: 'Atividades de investigação'
	},
	{
		id: 8110000,
		label: 'Ativ. combinadas apoio aos edifícios'
	},
	{
		id: 8121000,
		label: 'Ativ. limpeza geral em edifícios'
	},
	{
		id: 8122000,
		label: 'Outras ativ. limpeza em edif. e equip industriais'
	},
	{
		id: 8129100,
		label: 'Ativ. desinfecção, desratização e similares'
	},
	{
		id: 8129200,
		label: 'Outras Ativ. limpeza, n.e.'
	},
	{
		id: 8130000,
		label: 'Ativ. plantação e manutenção jardins'
	},
	{
		id: 8211000,
		label: 'Ativ. combinadas serviços administrativos'
	},
	{
		id: 8219000,
		label: 'Execução fotocópias e apoio administrativo'
	},
	{
		id: 8220000,
		label: 'Ativ. dos centros chamadas'
	},
	{
		id: 8230000,
		label: 'Organiz. feiras, congressos e outros event. Simil'
	},
	{
		id: 8291000,
		label: 'Ativ. cobranças e avaliação crédito'
	},
	{
		id: 8292100,
		label: 'Engarrafamento de gases'
	},
	{
		id: 8292200,
		label: 'Outras atividades de embalagem'
	},
	{
		id: 8299000,
		label: 'Outras ativ. serv. apoio prest. às empresas, n.e.'
	},
	{
		id: 8411100,
		label: 'Administração central'
	},
	{
		id: 8411200,
		label: 'Administração regional autónoma'
	},
	{
		id: 8411300,
		label: 'Adm. Local - Freguesias'
	},
	{
		id: 8411301,
		label: 'Adm. Local - Municipios'
	},
	{
		id: 8411400,
		label: 'Atividades de apoio à administração pública'
	},
	{
		id: 8412100,
		label: 'Administração pública - atividades de saúde'
	},
	{
		id: 8412200,
		label: 'Adm. Púb. - Ativ. educação'
	},
	{
		id: 8412300,
		label: 'Adm. Púb. - Ativ. Sociais, excl. seg. social'
	},
	{
		id: 8413000,
		label: 'Adm. Púb. - Ativ. económicas'
	},
	{
		id: 8421000,
		label: 'Negócios estrangeiros'
	},
	{
		id: 8422000,
		label: 'Atividades de defesa'
	},
	{
		id: 8423000,
		label: 'Atividades de justiça'
	},
	{
		id: 8424000,
		label: 'Ativ. segurança e ordem pública'
	},
	{
		id: 8425000,
		label: 'Ativ. protecção civil. n.e.'
	},
	{
		id: 8425001,
		label: 'Bombeiros e outros serviços de combate a incêndios'
	},
	{
		id: 8430000,
		label: 'Ativ. segurança social obrigatória'
	},
	{
		id: 8510000,
		label: 'Educação pré-escolar'
	},
	{
		id: 8520100,
		label: 'Ensino básico (1º Ciclo)'
	},
	{
		id: 8520200,
		label: 'Ensino básico (2º Ciclo)'
	},
	{
		id: 8531000,
		label: 'Ensinos básico (3º Ciclo) e secundário geral'
	},
	{
		id: 8532000,
		label: 'Ensinos secundário tecnológico, artístico e prof.'
	},
	{
		id: 8541000,
		label: 'Ensino pós-secundário não superior'
	},
	{
		id: 8542000,
		label: 'Ensino superior'
	},
	{
		id: 8551000,
		label: 'Ensinos desportivo e recreativo'
	},
	{
		id: 8552000,
		label: 'Ensino de atividades culturais'
	},
	{
		id: 8553000,
		label: 'Escolas de condução automóvel p/ obtenção licença'
	},
	{
		id: 8553001,
		label: 'Escolas condução e pilotagem, outras n.e.'
	},
	{
		id: 8559100,
		label: 'Formação profissional'
	},
	{
		id: 8559200,
		label: 'Escolas de línguas'
	},
	{
		id: 8559300,
		label: 'Outras Ativ. educativas, n.e.'
	},
	{
		id: 8560000,
		label: 'Ativ. serviços apoio à educação'
	},
	{
		id: 8610000,
		label: 'Ativ. dos estabelecimentos saúde com internamento'
	},
	{
		id: 8621000,
		label: 'Ativ prática médica clínica geral, em ambulatório'
	},
	{
		id: 8622000,
		label: 'Ativ prática médica clínica espec, em ambulatório'
	},
	{
		id: 8623000,
		label: 'Ativ. medicina dentária e odontologia'
	},
	{
		id: 8690100,
		label: 'Laboratórios análises clínicas'
	},
	{
		id: 8690200,
		label: 'Atividades de ambulâncias'
	},
	{
		id: 8690300,
		label: 'Atividades de enfermagem'
	},
	{
		id: 8690400,
		label: 'Centros recolha e bancos órgãos'
	},
	{
		id: 8690500,
		label: 'Atividades termais'
	},
	{
		id: 8690600,
		label: 'Fisioterapia, massagem e ginástica médica'
	},
	{
		id: 8690601,
		label: 'Outras Ativ. saúhumana, n.e.'
	},
	{
		id: 8710000,
		label: 'Ativ. estab cuidados continuados integ, com aloj.'
	},
	{
		id: 8720000,
		label: 'Estab. doença mental e abuso drogas, com alojam.'
	},
	{
		id: 8730100,
		label: 'Ativ. apoio social p/ pess idosas, com alojamento'
	},
	{
		id: 8730200,
		label: 'Ativ. apoio social pess. c/ defic., com alojam.'
	},
	{
		id: 8790100,
		label: 'Ativ. apoio social p/ crianças e jovens, com aloj'
	},
	{
		id: 8790200,
		label: 'Ativ. apoio social com alojamento, n.e.'
	},
	{
		id: 8810100,
		label: 'Ativ. apoio social p/ pess idosas, sem alojamento'
	},
	{
		id: 8810200,
		label: 'Ativ. apoio social p/ pess. c/ defic., sem alojam'
	},
	{
		id: 8891000,
		label: 'Ativ. cuidados para crianças, sem alojamento'
	},
	{
		id: 8899000,
		label: 'Outras ativ. apoio social sem alojamento, n.e.'
	},
	{
		id: 9001000,
		label: 'Ativ. das artes do espectáculo'
	},
	{
		id: 9002000,
		label: 'Ativ. apoio às artes do espectáculo'
	},
	{
		id: 9003000,
		label: 'Criação artística e literária'
	},
	{
		id: 9004000,
		label: 'Exploração salas espectáculos e Ativ. conexas'
	},
	{
		id: 9101100,
		label: 'Atividades das bibliotecas'
	},
	{
		id: 9101200,
		label: 'Atividades dos arquivos'
	},
	{
		id: 9102000,
		label: 'Atividades dos museus'
	},
	{
		id: 9103000,
		label: 'Ativ. dos sítios e monumentos históricos'
	},
	{
		id: 9104100,
		label: 'Ativ. jardins zoológicos, botânicos e aquários'
	},
	{
		id: 9104200,
		label: 'Ativ. dos parques e reservas naturais'
	},
	{
		id: 9200000,
		label: 'Lotarias e outros jogos aposta'
	},
	{
		id: 9311000,
		label: 'Gestão instalações desportivas'
	},
	{
		id: 9312000,
		label: 'Ativ. clubes incluindo prática desp. e equip técn'
	},
	{
		id: 9312001,
		label: 'Ativ. clubes desportivos sem prática desportiva'
	},
	{
		id: 9313000,
		label: 'Atividades de ginásio (fitness)'
	},
	{
		id: 9319100,
		label: 'Organismos reguladores das Ativ. desportivas'
	},
	{
		id: 9319200,
		label: 'Outras Ativ. desportivas, n.e.'
	},
	{
		id: 9321000,
		label: 'Ativ. dos parques diversão e temáticos'
	},
	{
		id: 9321100,
		label: 'Ativividades dos parques diversão itinerantes'
	},
	{
		id: 9329100,
		label: 'Atividades tauromáquicas'
	},
	{
		id: 9329200,
		label: 'Ativ. dos portos recreio (marinas)'
	},
	{
		id: 9329300,
		label: 'Organização Ativ. animação turística'
	},
	{
		id: 9329400,
		label: 'Outras Ativ. diversão e recreativas, n.e.'
	},
	{
		id: 9329500,
		label: 'Outras atividades diversão itinerantes'
	},
	{
		id: 9411000,
		label: 'Ativ. organizações económicas e patronais'
	},
	{
		id: 9412000,
		label: 'Ativ. organizações profissionais'
	},
	{
		id: 9420000,
		label: 'Ativ. organizações sindicais'
	},
	{
		id: 9491000,
		label: 'Ativ. organizações religiosas'
	},
	{
		id: 9492000,
		label: 'Ativ. organizações políticas'
	},
	{
		id: 9499100,
		label: 'Associações culturais e recreativas'
	},
	{
		id: 9499200,
		label: 'Associações defesa do ambiente'
	},
	{
		id: 9499300,
		label: 'Associações juventude estudantes'
	},
	{
		id: 9499400,
		label: 'Associações pais e encarregados educação'
	},
	{
		id: 9499500,
		label: 'Outras Ativ. associativas, n.e.'
	},
	{
		id: 9511000,
		label: 'Reparação computadores e equipamento periférico'
	},
	{
		id: 9512000,
		label: 'Reparação equipamento comunicação'
	},
	{
		id: 9521000,
		label: 'Reparação televisores e outros bens consumo simil'
	},
	{
		id: 9522000,
		label: 'Reparação eletrod. e equip. uso domést. e jardim'
	},
	{
		id: 9523000,
		label: 'Reparação calçado e artigos couro'
	},
	{
		id: 9524000,
		label: 'Reparação mobiliário e similares, uso doméstico'
	},
	{
		id: 9525000,
		label: 'Reparação relógios e artigos joalharia'
	},
	{
		id: 9529000,
		label: 'Reparação outros bens uso pessoal e doméstico'
	},
	{
		id: 9601000,
		label: 'Lavagem e limpeza a seco têxteis e peles'
	},
	{
		id: 9602100,
		label: 'Salões de cabeleireiro'
	},
	{
		id: 9602200,
		label: 'Institutos de beleza'
	},
	{
		id: 9603000,
		label: 'Ativ. funerárias e conexas'
	},
	{
		id: 9604000,
		label: 'Atividades de bem-estar físico'
	},
	{
		id: 9609100,
		label: 'Ativ. tatuagem e similares'
	},
	{
		id: 9609200,
		label: 'Ativ. dos serviços para animais companhia'
	},
	{
		id: 9609300,
		label: 'Outras Ativ. serviços pessoais diversas, n.e.'
	},
	{
		id: 9700000,
		label: 'Ativ. das famílias empregadoras pessoal doméstico'
	},
	{
		id: 9810000,
		label: 'Ativ. produção bens pelas famílias p/ uso próprio'
	},
	{
		id: 9820000,
		label: 'Ativ. produção serv pelas famílias p/ uso próprio'
	},
	{
		id: 9900000,
		label: 'Ativ. organismos intern e inst extra-territoriais'
	}
];

export const genderOptions = [
	{
		id: 1,
		label: 'Masculino'
	},
	{
		id: 2,
		label: 'Feminino'
	},
	{
		id: 3,
		label: 'Outro'
	}
];

export const protectiveMeasureOptions = [
	{
		id: 1,
		label: 'Alarme'
	},
	{
		id: 2,
		label: 'Gradeamento'
	},
	{
		id: 3,
		label: 'Extintores'
	}
];
