import parse from 'html-react-parser';
import Form from 'react-bootstrap/Form';
import useInput, { updateFormControl } from '../hooks/useInput';

const Checkbox = ({ name, label, readOnly = false, disabled = false, errorMessage = '', formControl, validations = () => true }) => {
	const { value, isValid, hasError, hasErrorOnBlur, valueChangeHandler: onChange, inputBlurHandler: onBlur, reset } = useInput(validations);
	updateFormControl(formControl, name, value, isValid, hasError, hasErrorOnBlur, onChange, onBlur, reset);

	return (
		<>
			<Form.Check id={name} type="checkbox" label={parse(label)} checked={value} disabled={disabled} onChange={onChange} onBlur={onBlur} readOnly={readOnly} />
			{hasError && errorMessage !== '' && <small className={'text-danger d-block mt-2'}>{errorMessage}</small>}
		</>
	);
};

export default Checkbox;
