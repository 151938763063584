import React, { useCallback, useEffect, useState } from 'react';
// MODULES
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import moment from 'moment';
// CONTEXT
import { useUserData } from '../../../../context/UserContext';
// COMPONENTS
import { alertError, alertSuccess } from '../../../../components/feedback/Feedback';
import DefaultCheckbox from '../../../../components/form/inputs/DefaultCheckbox';
import DefaultDatepicker from '../../../../components/form/inputs/DefaultDatepicker';
import DefaultInput from '../../../../components/form/inputs/DefaultInput';
import DefaultRadio from '../../../../components/form/inputs/DefaultRadio';
import DefaultSelect from '../../../../components/form/inputs/DefaultSelect';
import Loading from '../../../../components/loading/Loading';
// FORM
import {
	protectiveMeasureOptions,
	foundationTypeOptions,
	indemnityPeriodOptions,
	propertyContentOptions,
	roofMaterialOptions,
	typeOfConstructionOptions
} from '../../../../components/form/fillforms/insurance';
import useInput from '../../../../components/form/hooks/useInput';
import { currencyInputMask, phoneInputMask, vatNumberInputMask, zipCodeInputMask } from '../../../../components/form/masks/InputMasks';
import { isMandatoryMessage } from '../../../../components/form/validator/ValidatorMessages';
import { isChecked, isEmail, isNotEmpty, isNotNullOrUndefinedOrEmpty } from '../../../../components/form/validator/ValidatorRules';
// UTILS
import { InsurancePartnershipEmail } from '../../../../utils/Constants';
import { serviceOrigin, servicePartner, serviceType } from '../../../../utils/Enums';
// ACTIONS
import { save as saveLog } from '../../../../actions/LogRegister';
import { send_notification } from '../../../../actions/Notifier';
import { detail as subsidiaryDetail } from '../../../../actions/Subsidiary';
// CONTENT
import { SelectContent } from '../../../../content/resources';
// RESOURCES
import { termsAndConditions } from '../../../../components/form/fillforms/general';
import { CompanyMultiriskInsuranceEmailTemplate } from '../../emailtemplates/CompanyMultiriskInsuranceEmailTemplate';

export default function CompanyMultiriskInsuranceForm() {
	const {
		value: policyholderValue,
		isValid: policyholderIsValid,
		hasError: policyholderHasError,
		valueChangeHandler: policyholderChangeHandler,
		inputBlurHandler: policyholderBlurHandler,
		reset: resetPolicyholder
	} = useInput(isNotEmpty);
	const {
		value: vatNumberValue,
		isValid: vatNumberIsValid,
		hasError: vatNumberHasError,
		valueChangeHandler: vatNumberChangeHandler,
		inputBlurHandler: vatNumberBlurHandler,
		reset: resetVatNumber
	} = useInput(isNotEmpty);
	const { value: emailValue, isValid: emailIsValid, hasError: emailHasError, valueChangeHandler: emailChangeHandler, inputBlurHandler: emailBlurHandler, reset: resetEmail } = useInput(isEmail);
	const {
		value: phoneValue,
		isValid: phoneIsValid,
		hasError: phoneHasError,
		valueChangeHandler: phoneChangeHandler,
		inputBlurHandler: phoneBlurHandler,
		reset: resetPhone
	} = useInput(isNotEmpty);
	const {
		value: addressValue,
		isValid: addressIsValid,
		hasError: addressHasError,
		valueChangeHandler: addressChangeHandler,
		inputBlurHandler: addressBlurHandler,
		reset: resetAddress
	} = useInput(isNotEmpty);
	const {
		value: zipcodeValue,
		isValid: zipcodeIsValid,
		hasError: zipcodeHasError,
		valueChangeHandler: zipcodeChangeHandler,
		inputBlurHandler: zipcodeBlurHandler,
		reset: resetZipcode
	} = useInput(isNotEmpty);
	const {
		value: locationValue,
		isValid: locationIsValid,
		hasError: locationHasError,
		valueChangeHandler: locationChangeHandler,
		inputBlurHandler: locationBlurHandler,
		reset: resetLocation
	} = useInput(isNotEmpty);
	const {
		value: propertycontentValue,
		isValid: propertycontentIsValid,
		hasError: propertycontentHasError,
		valueChangeHandler: propertycontentChangeHandler,
		inputBlurHandler: propertycontentBlurHandler,
		reset: resetPropertycontent
	} = useInput(isNotNullOrUndefinedOrEmpty);
	const {
		value: licenseEmissionDateValue,
		isValid: licenseEmissionDateIsValid,
		hasError: licenseEmissionDateHasError,
		valueChangeHandler: licenseEmissionDateChangeHandler,
		inputBlurHandler: licenseEmissionDateBlurHandler,
		reset: resetLicenseEmissionDate
	} = useInput(isNotEmpty);
	const {
		value: realestatecapitalValue,
		isValid: realestatecapitalIsValid,
		hasError: realestatecapitalHasError,
		valueChangeHandler: realestatecapitalChangeHandler,
		inputBlurHandler: realestatecapitalBlurHandler,
		reset: resetRealestatecapital
	} = useInput(isNotEmpty);
	const {
		value: contentcapitalValue,
		isValid: contentcapitalIsValid,
		hasError: contentcapitalHasError,
		valueChangeHandler: contentcapitalChangeHandler,
		inputBlurHandler: contentcapitalBlurHandler,
		reset: resetContentcapital
	} = useInput(isNotEmpty);
	const {
		value: sectionactivityValue,
		isValid: sectionactivityIsValid,
		hasError: sectionactivityHasError,
		valueChangeHandler: sectionactivityChangeHandler,
		inputBlurHandler: sectionactivityBlurHandler,
		reset: resetSectionactivity
	} = useInput(isNotEmpty);
	const {
		value: occupationValue,
		isValid: occupationIsValid,
		hasError: occupationHasError,
		valueChangeHandler: occupationChangeHandler,
		inputBlurHandler: occupationBlurHandler,
		reset: resetOccupation
	} = useInput(isNotEmpty);
	const {
		value: foundationtypeValue,
		isValid: foundationtypeIsValid,
		hasError: foundationtypeHasError,
		valueChangeHandler: foundationtypeChangeHandler,
		inputBlurHandler: foundationtypeBlurHandler,
		reset: resetFoundationtype
	} = useInput(isNotNullOrUndefinedOrEmpty);
	const {
		value: typeofconstructionValue,
		isValid: typeofconstructionIsValid,
		hasError: typeofconstructionHasError,
		valueChangeHandler: typeofconstructionChangeHandler,
		inputBlurHandler: typeofconstructionBlurHandler,
		reset: resetTypeofconstruction
	} = useInput(isNotNullOrUndefinedOrEmpty);
	const {
		value: roofmaterialValue,
		isValid: roofmaterialIsValid,
		hasError: roofmaterialHasError,
		valueChangeHandler: roofmaterialChangeHandler,
		inputBlurHandler: roofmaterialBlurHandler,
		reset: resetRoofmaterial
	} = useInput(isNotNullOrUndefinedOrEmpty);
	const { value: intendprofitlostValue, valueChangeHandler: intendprofitlostChangeHandler, inputBlurHandler: intendprofitlostBlurHandler, reset: resetIntendprofitlost } = useInput(() => true);
	const {
		value: losscapitalprofitsValue,
		valueChangeHandler: losscapitalprofitsChangeHandler,
		inputBlurHandler: losscapitalprofitsBlurHandler,
		reset: resetLosscapitalprofits
	} = useInput(() => true);
	const { value: indemnityperiodValue, valueChangeHandler: indemnityperiodChangeHandler, inputBlurHandler: indemnityperiodBlurHandler, reset: resetIndemnityperiod } = useInput(() => true);
	const {
		value: protectivemeasureValue,
		isValid: protectivemeasureIsValid,
		hasError: protectivemeasureHasError,
		valueChangeHandler: protectivemeasureChangeHandler,
		inputBlurHandler: protectivemeasureBlurHandler,
		reset: resetProtectivemeasure
	} = useInput(isNotNullOrUndefinedOrEmpty);
	const {
		value: termsandpolicyValue,
		isValid: termsandpolicyIsValid,
		hasError: termsandpolicyHasError,
		valueChangeHandler: termsandpolicyChangeHandler,
		inputBlurHandler: termsandpolicyBlurHandler,
		reset: resetTermsandpolicy
	} = useInput(isChecked);

	let formIsValid = false;

	if (
		policyholderIsValid &&
		vatNumberIsValid &&
		emailIsValid &&
		phoneIsValid &&
		addressIsValid &&
		zipcodeIsValid &&
		locationIsValid &&
		licenseEmissionDateIsValid &&
		realestatecapitalIsValid &&
		propertycontentIsValid &&
		contentcapitalIsValid &&
		sectionactivityIsValid &&
		occupationIsValid &&
		foundationtypeIsValid &&
		typeofconstructionIsValid &&
		roofmaterialIsValid &&
		protectivemeasureIsValid &&
		termsandpolicyIsValid
	) {
		formIsValid = true;
	}

	// CONTEXT
	const { state } = useUserData();

	// STATE
	const [isLoading, setIsLoading] = useState(false);
	const [log, setLog] = useState({
		id: 0,
		subsidiaryId: state.user.subsidiaryId,
		userId: state.user.id,
		originId: serviceOrigin.SERVICE_PLUS,
		startDate: new Date(),
		serviceTypeId: serviceType.INSURANCES,
		partnerId: servicePartner.FAST_PATH_COMPANY
	});

	// USEEFFECT
	useEffect(
		() => {
			saveFormLog();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	// METHODS
	// Log
	const saveFormLog = useCallback(
		async submissionData => {
			await saveLog({
				data: { ...log, ...submissionData },
				success: logId => {
					setLog({ ...log, id: logId });
				},
				error: error => {}
			});
		}, // eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	// Notification
	const handleResetForm = () => {
		alertSuccess('Pedido de cotação submetido com sucesso');
		clearForm();
		setIsLoading(false);
	};

	function getEmailTemplate(subsidiary, message) {
		const emailsubject = 'Multirriscos empresa - Pedido de cotação';

		return CompanyMultiriskInsuranceEmailTemplate({
			subject: emailsubject,
			message: message,
			subsidiaryName: subsidiary.name,
			subsidiaryId: state.user.subsidiaryId,
			subsidiaryVat: state.user.subsidiaryVat,
			policyholder: policyholderValue,
			vatNumber: vatNumberValue,
			email: emailValue,
			phone: phoneValue,
			address: addressValue,
			zipcode: zipcodeValue,
			location: locationValue,
			propertycontent: propertycontentValue.label,
			licenseEmissionDate: moment(licenseEmissionDateValue).format('YYYY'),
			realestatecapital: realestatecapitalValue,
			contentcapital: contentcapitalValue,
			sectionactivity: sectionactivityValue,
			occupation: occupationValue,
			foundationtype: foundationtypeValue.label,
			typeofconstruction: typeofconstructionValue.label,
			roofmaterial: roofmaterialValue.label,
			protectivemeasure: protectivemeasureValue.label,
			intendprofitlost: intendprofitlostValue === 'true' ? 'Sim' : 'Não',
			losscapitalprofits: losscapitalprofitsValue,
			indemnityperiod: indemnityperiodValue ? indemnityperiodValue.label : ''
		});
	}

	const sendNotificationToUser = async (emailtemplate, emailValue) => {
		let _formData = new FormData();

		_formData.append('body', emailtemplate.body);
		_formData.append('subject', emailtemplate.subject);
		_formData.append('toAddresses', emailValue);
		_formData.append('isHTML', true);
		_formData.append('priority', 0);

		await send_notification({
			data: _formData,
			success: data => {
				handleResetForm();
			},
			error: error => {
				handleResetForm();
			}
		});
	};

	const sendNotification = async subsidiary => {
		const companyMessage = 'Foi efetuado um pedido de cotação de seguro com os dados a seguir apresentados:';
		const userMessage = 'O seu pedido de cotação foi submetido com sucesso. O nosso parceiro irá em breve entrar em contacto consigo.';
		const emailtemplate = getEmailTemplate(subsidiary, companyMessage);
		const useremailtemplate = getEmailTemplate(subsidiary, userMessage);
		let _formData = new FormData();

		_formData.append('body', emailtemplate.body);
		_formData.append('subject', emailtemplate.subject);
		_formData.append('toAddresses', InsurancePartnershipEmail);
		_formData.append('isHTML', true);
		_formData.append('priority', 0);

		await send_notification({
			data: _formData,
			success: data => {
				sendNotificationToUser(useremailtemplate, emailValue);
				saveFormLog({
					submissionDate: new Date()
				});
			},
			error: error => {
				alertError('Ocorreu um erro na submissão do formulário. Por favor, tente novamente.');
				setIsLoading(false);
			}
		});
	};

	// Subsidiary
	const getSubsidiaryDetail = async () => {
		setIsLoading(true);

		await subsidiaryDetail({
			success: subsidiary => {
				sendNotification(subsidiary);
			},
			error: error => {
				setIsLoading(false);
				alertError('Ocorreu um erro na submissão do formulário. Por favor, tente novamente.');
			}
		});
	};

	// Form
	const clearForm = () => {
		resetPolicyholder();
		resetVatNumber();
		resetEmail();
		resetPhone();
		resetAddress();
		resetZipcode();
		resetLocation();
		resetPropertycontent();
		resetLicenseEmissionDate();
		resetRealestatecapital();
		resetContentcapital();
		resetSectionactivity();
		resetOccupation();
		resetFoundationtype();
		resetTypeofconstruction();
		resetRoofmaterial();
		resetProtectivemeasure();
		resetTermsandpolicy();
		resetIntendprofitlost();
		resetLosscapitalprofits();
		resetIndemnityperiod();
	};

	const validateAllFormInputs = () => {
		policyholderBlurHandler();
		policyholderChangeHandler(policyholderValue);
		vatNumberBlurHandler();
		vatNumberChangeHandler(vatNumberValue);
		emailBlurHandler();
		emailChangeHandler(emailValue);
		phoneBlurHandler();
		phoneChangeHandler(phoneValue);
		addressBlurHandler();
		addressChangeHandler(addressValue);
		zipcodeBlurHandler();
		zipcodeChangeHandler(zipcodeValue);
		locationBlurHandler();
		locationChangeHandler(locationValue);
		propertycontentBlurHandler();
		propertycontentChangeHandler(propertycontentValue);
		licenseEmissionDateBlurHandler();
		licenseEmissionDateChangeHandler(licenseEmissionDateValue);
		realestatecapitalBlurHandler();
		realestatecapitalChangeHandler(realestatecapitalValue);
		contentcapitalBlurHandler();
		contentcapitalChangeHandler(contentcapitalValue);
		sectionactivityBlurHandler();
		sectionactivityChangeHandler(sectionactivityValue);
		occupationBlurHandler();
		occupationChangeHandler(occupationValue);
		foundationtypeBlurHandler();
		foundationtypeChangeHandler(foundationtypeValue);
		typeofconstructionBlurHandler();
		typeofconstructionChangeHandler(typeofconstructionValue);
		roofmaterialBlurHandler();
		roofmaterialChangeHandler(roofmaterialValue);
		protectivemeasureBlurHandler();
		protectivemeasureChangeHandler(protectivemeasureValue);
		termsandpolicyBlurHandler();
		termsandpolicyChangeHandler(termsandpolicyValue);
	};

	const handleSubmit = event => {
		event.preventDefault();

		if (!formIsValid) {
			validateAllFormInputs();
			return;
		}

		getSubsidiaryDetail();
	};

	return (
		<>
			<Form className="form--container" onSubmit={handleSubmit}>
				<Row>
					<Col md={12} lg={2}>
						<h5>Empresa</h5>
					</Col>
					<Col md={12} lg={10}>
						<Row>
							<Col>
								<DefaultInput
									label={'Tomador'}
									name={'policyholder'}
									required={true}
									type={'text'}
									value={policyholderValue}
									onChange={policyholderChangeHandler}
									onBlur={policyholderBlurHandler}
									hasError={policyholderHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<DefaultInput
									label={'NIF'}
									name={'vatNumber'}
									required={true}
									maxlength={9}
									type={'mask'}
									mask={vatNumberInputMask}
									value={vatNumberValue}
									onChange={vatNumberChangeHandler}
									onBlur={vatNumberBlurHandler}
									hasError={vatNumberHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							<Col>
								<DefaultInput
									label={'Email'}
									name={'email'}
									required={true}
									type={'text'}
									value={emailValue}
									onChange={emailChangeHandler}
									onBlur={emailBlurHandler}
									hasError={emailHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							<Col>
								<DefaultInput
									label={'Telefone'}
									name={'phone'}
									required={true}
									type={'mask'}
									mask={phoneInputMask}
									value={phoneValue}
									onChange={phoneChangeHandler}
									onBlur={phoneBlurHandler}
									hasError={phoneHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
								<DefaultInput
									label={'Morada'}
									name={'address'}
									required={true}
									type={'text'}
									value={addressValue}
									onChange={addressChangeHandler}
									onBlur={addressBlurHandler}
									hasError={addressHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							<Col>
								<DefaultInput
									label={'Código postal'}
									name={'zipcode'}
									required={true}
									type={'mask'}
									mask={zipCodeInputMask}
									value={zipcodeValue}
									onChange={zipcodeChangeHandler}
									onBlur={zipcodeBlurHandler}
									hasError={zipcodeHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							<Col>
								<DefaultInput
									label={'Localidade'}
									name={'location'}
									required={true}
									type={'text'}
									value={locationValue}
									onChange={locationChangeHandler}
									onBlur={locationBlurHandler}
									hasError={locationHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col md={12} lg={2}>
						<h5>Local de risco</h5>
					</Col>
					<Col md={12} lg={10}>
						<Row xs={1} md={2} xl={3} xxl={4}>
							<Col>
								<DefaultSelect
									label={'Imóvel / conteúdo'}
									isClearable={true}
									instanceId={'propertycontentSelect'}
									required={true}
									options={propertyContentOptions}
									defaultValue={null}
									value={propertycontentValue}
									onChange={propertycontentChangeHandler}
									onBlur={propertycontentBlurHandler}
									hasError={propertycontentHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							<Col>
								<DefaultDatepicker
									label={'Ano de contrução'}
									value={licenseEmissionDateValue}
									dateFormat={'YYYY'}
									required={true}
									onChange={licenseEmissionDateChangeHandler}
									onBlur={licenseEmissionDateBlurHandler}
									hasError={licenseEmissionDateHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							<Col>
								<DefaultInput
									label={'Capital do imóvel'}
									name={'realestatecapital'}
									required={true}
									extraCharacter={'€'}
									type={'mask'}
									mask={currencyInputMask}
									value={realestatecapitalValue}
									onChange={realestatecapitalChangeHandler}
									onBlur={realestatecapitalBlurHandler}
									hasError={realestatecapitalHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							<Col>
								<DefaultInput
									label={'Capital do conteúdo'}
									name={'contentcapital'}
									required={true}
									extraCharacter={'€'}
									type={'mask'}
									mask={currencyInputMask}
									value={contentcapitalValue}
									onChange={contentcapitalChangeHandler}
									onBlur={contentcapitalBlurHandler}
									hasError={contentcapitalHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
						</Row>
						<Row xs={1} md={2} xl={3}>
							<Col>
								<DefaultInput
									label={'Sector de atividade'}
									name={'sectionactivity'}
									required={true}
									type={'text'}
									value={sectionactivityValue}
									onChange={sectionactivityChangeHandler}
									onBlur={sectionactivityBlurHandler}
									hasError={sectionactivityHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							<Col>
								<DefaultInput
									label={'Ocupação'}
									name={'occupation'}
									required={true}
									type={'text'}
									value={occupationValue}
									onChange={occupationChangeHandler}
									onBlur={occupationBlurHandler}
									hasError={occupationHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							<Col>
								<DefaultSelect
									label={'Tipo de fundação'}
									instanceId={'foundationtypeSelect'}
									required={true}
									options={foundationTypeOptions}
									defaultValue={null}
									value={foundationtypeValue}
									onChange={foundationtypeChangeHandler}
									onBlur={foundationtypeBlurHandler}
									hasError={foundationtypeHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							<Col>
								<DefaultSelect
									label={'Tipo de construção'}
									instanceId={'typeofconstructionSelect'}
									required={true}
									options={typeOfConstructionOptions}
									defaultValue={null}
									value={typeofconstructionValue}
									onChange={typeofconstructionChangeHandler}
									onBlur={typeofconstructionBlurHandler}
									hasError={typeofconstructionHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							<Col>
								<DefaultSelect
									label={'Material do telhado'}
									instanceId={'roofmaterialSelect'}
									required={true}
									options={roofMaterialOptions}
									defaultValue={null}
									value={roofmaterialValue}
									onChange={roofmaterialChangeHandler}
									onBlur={roofmaterialBlurHandler}
									hasError={roofmaterialHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							<Col>
								<DefaultSelect
									label={'Medidas de proteção'}
									instanceId={'protectivemeasureSelect'}
									required={true}
									options={protectiveMeasureOptions}
									defaultValue={null}
									value={protectivemeasureValue}
									onChange={protectivemeasureChangeHandler}
									onBlur={protectivemeasureBlurHandler}
									hasError={protectivemeasureHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<DefaultRadio
									label={'Segurar perda de lucros'}
									name={'intendprofitlost'}
									value={intendprofitlostValue}
									onChange={intendprofitlostChangeHandler}
									onBlur={intendprofitlostBlurHandler}
								/>
							</Col>
							{intendprofitlostValue === 'true' && (
								<>
									<Col>
										<DefaultInput
											label={'Capital perda de lucros'}
											name={'losscapitalprofits'}
											extraCharacter={'€'}
											type={'mask'}
											mask={currencyInputMask}
											value={losscapitalprofitsValue}
											onChange={losscapitalprofitsChangeHandler}
											onBlur={losscapitalprofitsBlurHandler}
										/>
									</Col>
									<Col>
										<DefaultSelect
											label={'Período de indeminização'}
											isClearable={true}
											instanceId={'indemnityperiodeSelect'}
											options={indemnityPeriodOptions}
											defaultValue={null}
											value={indemnityperiodValue}
											onChange={indemnityperiodChangeHandler}
											onBlur={indemnityperiodBlurHandler}
										/>
									</Col>
								</>
							)}
						</Row>
						<Row>
							<Col className="form-authorize mt-4">
								<DefaultCheckbox
									name="termsandpolicy"
									label={SelectContent(termsAndConditions.urlTerms, state.user.type)}
									checked={termsandpolicyValue}
									onChange={termsandpolicyChangeHandler}
									onBlur={termsandpolicyBlurHandler}
									hasError={termsandpolicyHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<Button type={'submit'} className={'service-btn'}>
									Submeter pedido
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
			{isLoading && <Loading label={'A carregar...'} />}
		</>
	);
}
