import React, { useState, useEffect, useCallback } from 'react';
// MODULES
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
// CONTEXT
import { useUserData } from '../../../../context/UserContext';
// COMPONENTS
import Input from '../../../../components/form/inputs/Input';
import Select from '../../../../components/form/inputs/Select';
import Checkbox from '../../../../components/form/inputs/Checkbox';
import Datepicker from '../../../../components/form/inputs/Datepicker';
import Loading from '../../../../components/loading/Loading';
import { alertSuccess, alertError } from '../../../../components/feedback/Feedback';
// FORM

import { zipCodeInputMask, vatNumberInputMask, phoneInputMask } from '../../../../components/form/masks/InputMasks';
import { isNotEmpty, isEmail, isChecked, isNotNullOrUndefinedOrEmpty } from '../../../../components/form/validator/ValidatorRules';
import { isMandatoryMessage, invalidEmailMessage } from '../../../../components/form/validator/ValidatorMessages';
import { genderOptions } from '../../../../components/form/fillforms/insurance';
// ACTIONS
import { send_notification } from '../../../../actions/Notifier';
import { detail as subsidiaryDetail } from '../../../../actions/Subsidiary';
import { save as saveLog } from '../../../../actions/LogRegister';
// UTILS
import { serviceOrigin, serviceType, servicePartner } from '../../../../utils/Enums';
import { InsurancePartnershipEmail } from '../../../../utils/Constants';
// CONTENT
import { SelectContent } from '../../../../content/resources';
// RESOURCES
import { HealthInsuranceEmailTemplate } from '../../emailtemplates/HealthInsuranceEmailTemplate';
import { termsAndConditions } from '../../../../components/form/fillforms/general';

export default function HealthInsuranceForm() {
	const [formControl] = useState({});

	// CONTEXT
	const { state } = useUserData();

	// STATE
	const [isLoading, setIsLoading] = useState(false);
	const [log, setLog] = useState({
		id: 0,
		subsidiaryId: state.user.subsidiaryId,
		userId: state.user.id,
		originId: serviceOrigin.SERVICE_PLUS,
		startDate: new Date(),
		serviceTypeId: serviceType.INSURANCES,
		partnerId: servicePartner.FAST_PATH_HEALTH
	});

	// USEEFFECT
	useEffect(
		() => {
			saveFormLog();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	// METHODS
	// Log
	const saveFormLog = useCallback(
		async submissionData => {
			await saveLog({
				data: { ...log, ...submissionData },
				success: logId => {
					setLog({ ...log, id: logId });
				},
				error: error => {}
			});
		}, // eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	// Notification
	const handleResetForm = () => {
		alertSuccess('Pedido de cotação submetido com sucesso');
		formControl.reset();
		setIsLoading(false);
	};

	function getEmailTemplate(subsidiary, message) {
		const emailsubject = 'Seguro de saúde - Pedido de cotação';

		return HealthInsuranceEmailTemplate({
			subject: emailsubject,
			message: message,
			subsidiaryName: subsidiary.name,
			subsidiaryId: state.user.subsidiaryId,
			subsidiaryVat: state.user.subsidiaryVat,
			policyholder: formControl.policyholder.value,
			vatNumber: formControl.vatNumber.value,
			email: formControl.email.value,
			phone: formControl.phone.value,
			address: formControl.address.value,
			zipcode: formControl.zipcode.value,
			location: formControl.location.value,
			birthDate: formControl.birthDate.value,
			gender: formControl.gender.value.label
		});
	}

	const sendNotificationToUser = async (emailtemplate, emailValue) => {
		let _formData = new FormData();

		_formData.append('body', emailtemplate.body);
		_formData.append('subject', emailtemplate.subject);
		_formData.append('toAddresses', emailValue);
		_formData.append('isHTML', true);
		_formData.append('priority', 0);

		await send_notification({
			data: _formData,
			success: data => {
				handleResetForm();
			},
			error: error => {
				handleResetForm();
			}
		});
	};

	const sendNotification = async subsidiary => {
		const companyMessage = 'Foi efetuado um pedido de cotação de seguro com os dados a seguir apresentados:';
		const userMessage = 'O seu pedido de cotação foi submetido com sucesso. O nosso parceiro irá em breve entrar em contacto consigo.';
		const emailtemplate = getEmailTemplate(subsidiary, companyMessage);
		const useremailtemplate = getEmailTemplate(subsidiary, userMessage);
		let _formData = new FormData();

		_formData.append('body', emailtemplate.body);
		_formData.append('subject', emailtemplate.subject);
		_formData.append('toAddresses', InsurancePartnershipEmail);
		_formData.append('isHTML', true);
		_formData.append('priority', 0);

		await send_notification({
			data: _formData,
			success: data => {
				sendNotificationToUser(useremailtemplate, formControl.email.value);
				saveFormLog({
					submissionDate: new Date()
				});
			},
			error: error => {
				alertError('Ocorreu um erro na submissão do formulário. Por favor, tente novamente.');
				setIsLoading(false);
			}
		});
	};

	// Subsidiary
	const getSubsidiaryDetail = async () => {
		setIsLoading(true);

		await subsidiaryDetail({
			success: subsidiary => {
				sendNotification(subsidiary);
			},
			error: error => {
				setIsLoading(false);
				alertError('Ocorreu um erro na submissão do formulário. Por favor, tente novamente.');
			}
		});
	};

	const handleSubmit = event => {
		event.preventDefault();

		if (formControl.valid()) getSubsidiaryDetail();
	};

	return (
		<>
			<Form className="form--container" onSubmit={handleSubmit}>
				<Row>
					<Col md={12} lg={2}>
						<h5>Dados</h5>
					</Col>
					<Col md={12} lg={10}>
						<Row>
							<Col>
								<Input
									label={'Tomador'}
									name={'policyholder'}
									required={true}
									type={'text'}
									errorMessage={isMandatoryMessage}
									formControl={formControl}
									validations={isNotEmpty}
								/>
							</Col>
							<Col md={3}>
								<Input
									label={'NIF'}
									name={'vatNumber'}
									required={true}
									maxlength={9}
									type={'mask'}
									mask={vatNumberInputMask}
									formControl={formControl}
									errorMessage={isMandatoryMessage}
									validations={isNotEmpty}
								/>
							</Col>
						</Row>
						<Row xs={1} md={2} xl={4}>
							<Col>
								<Input
									label={'Email'}
									name={'email'}
									required={true}
									type={'text'}
									formControl={formControl}
									errorMessage={[isMandatoryMessage, invalidEmailMessage]}
									validations={[isNotEmpty, isEmail]}
								/>
							</Col>
							<Col>
								<Input
									label={'Telefone'}
									name={'phone'}
									required={true}
									type={'mask'}
									mask={phoneInputMask}
									formControl={formControl}
									errorMessage={isMandatoryMessage}
									validations={isNotEmpty}
								/>
							</Col>
							<Col>
								<Datepicker
									label={'Data de nascimento'}
									name={'birthDate'}
									required={true}
									formControl={formControl}
									errorMessage={isMandatoryMessage}
									validations={isNotEmpty}
								/>
							</Col>
							<Col>
								<Select
									label={'Sexo'}
									name={'gender'}
									isClearable={true}
									instanceId={'genderSelect'}
									required={true}
									options={genderOptions}
									defaultValue={null}
									formControl={formControl}
									errorMessage={isMandatoryMessage}
									validations={isNotNullOrUndefinedOrEmpty}
								/>
							</Col>
						</Row>
						<Row>
							<Col lg={6}>
								<Input
									label={'Morada'}
									name={'address'}
									required={true}
									type={'text'}
									formControl={formControl}
									errorMessage={isMandatoryMessage}
									validations={isNotEmpty}
								/>
							</Col>
							<Col>
								<Input
									label={'Código postal'}
									name={'zipcode'}
									required={true}
									type={'mask'}
									mask={zipCodeInputMask}
									formControl={formControl}
									errorMessage={isMandatoryMessage}
									validations={isNotEmpty}
								/>
							</Col>
							<Col>
								<Input
									label={'Localidade'}
									name={'location'}
									required={true}
									type={'text'}
									formControl={formControl}
									errorMessage={isMandatoryMessage}
									validations={isNotEmpty}
								/>
							</Col>
						</Row>
						<Row>
							<Col className="form-authorize mt-4">
								<Checkbox
									name="termsandpolicy"
									label={SelectContent(termsAndConditions.urlTerms, state.user.type)}
									formControl={formControl}
									errorMessage={isMandatoryMessage}
									validations={isChecked}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<Button type={'submit'} className={'service-btn'}>
									Submeter pedido
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
			{isLoading && <Loading label={'A carregar...'} />}
		</>
	);
}
