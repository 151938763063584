import React from 'react';
// MODULES
import Container from 'react-bootstrap/Container';
// COMPONENTS
import PageHeader from '../../components/header/PageHeader';
import WorkplaceAccidentForm from './components/form/WorkplaceAccidentForm';

export default function WorkplaceAccident() {
	// STATE

	return (
		<Container>
			<PageHeader subtitle={'Seguros +'} title={'Acidentes de trabalho'} />
			<WorkplaceAccidentForm />
		</Container>
	);
}
