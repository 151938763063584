import React, { useState, useEffect, useCallback } from 'react';
// MODULES
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import moment from 'moment';
// CONTEXT
import { useUserData } from '../../../../context/UserContext';
// COMPONENTS
import Loading from '../../../../components/loading/Loading';
import TooltipPopover from '../../../../components/tooltippopover/TooltipPopover';
import { alertSuccess, alertError } from '../../../../components/feedback/Feedback';
// FORM
import useInput from '../../../../components/form/hooks/useInput';
import DefaultInput from '../../../../components/form/inputs/DefaultInput';
import DefaultSelect from '../../../../components/form/inputs/DefaultSelect';
import DefaultRadio from '../../../../components/form/inputs/DefaultRadio';
import DefaultCheckbox from '../../../../components/form/inputs/DefaultCheckbox';
import DefaultDatepicker from '../../../../components/form/inputs/DefaultDatepicker';
import DefaultCondominiumSelect from '../../../../components/form/inputs/DefaultCondominiumSelect';
import DefaultVATInput from '../../../../components/form/inputs/DefaultVATInput';
import DefaultFileInput from '../../../../components/form/inputs/DefaultFileInput';
import { zipCodeInputMask, numberInputMask, currencyInputMask, phoneInputMask } from '../../../../components/form/masks/InputMasks';
import { isNotEmpty, isNotNullOrUndefinedOrEmpty, isEmail, isChecked, isNIPCValid, isNotEmptyFile } from '../../../../components/form/validator/ValidatorRules';
import { isMandatoryMessage } from '../../../../components/form/validator/ValidatorMessages';
// ACTIONS
import { save as condominiumSave } from '../../../../actions/Condominiums';
import { send_notification } from '../../../../actions/Notifier';
import { detail as subsidiaryDetail } from '../../../../actions/Subsidiary';
import { save as saveLog } from '../../../../actions/LogRegister';
// UTILS
import { serviceOrigin, serviceType, servicePartner } from '../../../../utils/Enums';
import { InsurancePartnershipEmail } from '../../../../utils/Constants';
// CONTENT
import { SelectContent } from '../../../../content/resources';
// RESOURCES
import { SeismicPhenomenaTooltipBody, RealEstateCapitalBelowTooltipBody, RealEstateCapitalAboveTooltipBody } from '../../content/resources';
import { objectOfInsuranceOptions, typeOfConstructionOptions } from '../../../../components/form/fillforms/insurance';
import { termsAndConditions } from '../../../../components/form/fillforms/general';
import { CondominiumMultiriskInsuranceEmailTemplate } from '../../emailtemplates/CondominiumMultiriskInsuranceEmailTemplate';

export default function CondominiumMultiriskInsuranceForm() {
	const {
		value: condominiumValue,
		isValid: condominiumIsValid,
		hasError: condominiumHasError,
		valueChangeHandler: condominiumChangeHandler,
		inputBlurHandler: condominiumBlurHandler,
		reset: resetCondominium
	} = useInput(isNotNullOrUndefinedOrEmpty);
	const {
		value: vatNumberValue,
		isValid: vatNumberIsValid,
		hasError: vatNumberHasError,
		hasErrorOnBlur: vatNumberHasErrorOnBlur,
		valueChangeHandler: vatNumberChangeHandler,
		inputBlurHandler: vatNumberBlurHandler,
		reset: resetVatNumber
	} = useInput(isNotEmpty, isNIPCValid);
	const {
		value: addressValue,
		isValid: addressIsValid,
		hasError: addressHasError,
		valueChangeHandler: addressChangeHandler,
		inputBlurHandler: addressBlurHandler,
		reset: resetAddress
	} = useInput(isNotEmpty);
	const {
		value: zipCodeValue,
		isValid: zipCodeIsValid,
		hasError: zipCodeHasError,
		valueChangeHandler: zipCodeChangeHandler,
		inputBlurHandler: zipCodeBlurHandler,
		reset: resetZipCode
	} = useInput(isNotEmpty);
	const {
		value: zipLocationValue,
		isValid: zipLocationIsValid,
		hasError: zipLocationHasError,
		valueChangeHandler: zipLocationChangeHandler,
		inputBlurHandler: zipLocationBlurHandler,
		reset: resetZipLocation
	} = useInput(isNotEmpty);
	const { value: managerValue, valueChangeHandler: managerChangeHandler, inputBlurHandler: managerBlurHandler } = useInput(() => true);
	const { value: emailValue, isValid: emailIsValid, hasError: emailHasError, valueChangeHandler: emailChangeHandler, inputBlurHandler: emailBlurHandler } = useInput(isEmail);
	const { value: phoneValue, isValid: phoneIsValid, hasError: phoneHasError, valueChangeHandler: phoneChangeHandler, inputBlurHandler: phoneBlurHandler } = useInput(isNotEmpty);
	const {
		value: realEstateCapitalValue,
		isValid: realEstateCapitalIsValid,
		hasError: realEstateCapitalHasError,
		valueChangeHandler: realEstateCapitalChangeHandler,
		inputBlurHandler: realEstateCapitalBlurHandler,
		reset: resetRealEstateCapital
	} = useInput(isNotEmpty);
	const {
		value: numberOfFractionsValue,
		isValid: numberOfFractionsIsValid,
		hasError: numberOfFractionsHasError,
		valueChangeHandler: numberOfFractionsChangeHandler,
		inputBlurHandler: numberOfFractionsBlurHandler,
		reset: resetNumberOfFractions
	} = useInput(isNotEmpty);
	const {
		value: objectOfInsuranceValue,
		isValid: objectOfInsuranceIsValid,
		hasError: objectOfInsuranceHasError,
		valueChangeHandler: objectOfInsuranceChangeHandler,
		inputBlurHandler: objectOfInsuranceBlurHandler,
		reset: resetObjectOfInsurance
	} = useInput(isNotNullOrUndefinedOrEmpty);
	const {
		value: insuranceStartDateValue,
		isValid: insuranceStartDateIsValid,
		hasError: insuranceStartDateHasError,
		valueChangeHandler: insuranceStartDateChangeHandler,
		inputBlurHandler: insuranceStartDateBlurHandler,
		reset: resetInsuranceStartDate
	} = useInput(isNotEmpty);
	const { value: seismicPhenomenaValue, valueChangeHandler: seismicPhenomenaChangeHandler, reset: resetSeismicPhenomena } = useInput(() => true);
	const {
		value: licenseEmissionDateValue,
		isValid: licenseEmissionDateIsValid,
		hasError: licenseEmissionDateHasError,
		valueChangeHandler: licenseEmissionDateChangeHandler,
		inputBlurHandler: licenseEmissionDateBlurHandler,
		reset: resetLicenseEmissionDate
	} = useInput(isNotEmpty);
	const {
		value: typeOfConstructionValue,
		isValid: typeOfConstructionIsValid,
		hasError: typeOfConstructionHasError,
		valueChangeHandler: typeOfConstructionChangeHandler,
		inputBlurHandler: typeOfConstructionBlurHandler,
		reset: resetTypeOfConstruction
	} = useInput(isNotNullOrUndefinedOrEmpty);
	const {
		value: numberOfFloorsAboveGroundValue,
		isValid: numberOfFloorsAboveGroundIsValid,
		hasError: numberOfFloorsAboveGroundHasError,
		valueChangeHandler: numberOfFloorsAboveGroundChangeHandler,
		inputBlurHandler: numberOfFloorsAboveGroundBlurHandler,
		reset: resetNumberOfFloorsAboveGround
	} = useInput(isNotEmpty);
	const {
		value: numberOfFloorsBelowGroundValue,
		isValid: numberOfFloorsBelowGroundIsValid,
		hasError: numberOfFloorsBelowGroundHasError,
		valueChangeHandler: numberOfFloorsBelowGroundChangeHandler,
		inputBlurHandler: numberOfFloorsBelowGroundBlurHandler,
		reset: resetNumberOfFloorsBelowGround
	} = useInput(isNotEmpty);
	const {
		value: remodelingWorksValue,
		isValid: remodelingWorksIsValid,
		hasError: remodelingWorksHasError,
		valueChangeHandler: remodelingWorksChangeHandler,
		inputBlurHandler: remodelingWorksBlurHandler,
		reset: resetRemodelingWorks
	} = useInput(isNotEmpty);
	const {
		value: ifYesValue,
		isValid: ifYesIsValid,
		hasError: ifYesHasError,
		valueChangeHandler: ifYesChangeHandler,
		inputBlurHandler: ifYesBlurHandler,
		reset: resetIfYes
	} = useInput(isNotEmpty);
	const {
		value: pdfAttachmentValue,
		isValid: pdfAttachmentIsValid,
		hasError: pdfAttachmentHasError,
		valueChangeHandler: pdfAttachmentChangeHandler,
		inputBlurHandler: pdfAttachmentBlurHandler,
		reset: resetPdfAttachment
	} = useInput(isNotEmptyFile);
	const {
		value: policyAttachmentValue,
		isValid: policyAttachmentIsValid,
		hasError: policyAttachmentHasError,
		valueChangeHandler: policyAttachmentChangeHandler,
		inputBlurHandler: policyAttachmentBlurHandler,
		reset: resetPolicyAttachment
	} = useInput(isNotEmptyFile);
	const {
		value: termsAndPolicyValue,
		isValid: termsAndPolicyIsValid,
		hasError: termsAndPolicyHasError,
		valueChangeHandler: termsAndPolicyChangeHandler,
		inputBlurHandler: termsAndPolicyBlurHandler,
		reset: resetTermsAndPolicy
	} = useInput(isChecked);

	const isRealEstateCapitalBelow10Million = realEstateCapitalValue ? realEstateCapitalValue.replaceAll('.', '').replace(',', '.') < 10000000 : true;
	const commomFormIsValid =
		condominiumIsValid && vatNumberIsValid && addressIsValid && zipCodeIsValid && zipLocationIsValid && emailIsValid && phoneIsValid && realEstateCapitalIsValid && termsAndPolicyIsValid;
	const below10MillionFormIsValid =
		commomFormIsValid &&
		numberOfFractionsIsValid &&
		objectOfInsuranceIsValid &&
		insuranceStartDateIsValid &&
		licenseEmissionDateIsValid &&
		typeOfConstructionIsValid &&
		numberOfFloorsAboveGroundIsValid &&
		numberOfFloorsBelowGroundIsValid &&
		remodelingWorksIsValid;
	let formIsValid = false;

	if (commomFormIsValid) {
		switch (true) {
			case !isRealEstateCapitalBelow10Million && pdfAttachmentIsValid && policyAttachmentIsValid:
				formIsValid = true;
				break;
			case isRealEstateCapitalBelow10Million:
				if (below10MillionFormIsValid) {
					switch (true) {
						case remodelingWorksValue === 'true':
							if (ifYesIsValid) formIsValid = true;
							break;
						default:
							formIsValid = true;
							break;
					}
				}
				break;
			default:
				break;
		}
	}

	// CONTEXT
	const { state } = useUserData();

	// STATE
	const [condominiums, setCondominiums] = useState([]);
	const [condominium, setCondominium] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [log, setLog] = useState({
		id: 0,
		subsidiaryId: state.user.subsidiaryId,
		userId: state.user.id,
		originId: serviceOrigin.SERVICE_PLUS,
		startDate: new Date(),
		serviceTypeId: serviceType.INSURANCES,
		partnerId: servicePartner.FAST_PATH_CONDOMINIUM
	});

	// USEEFFECT
	useEffect(
		() => {
			setDefaultManagerFormValues();
			saveFormLog();
		}, // eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	// METHODS
	// Condominium
	const handleCondominiumList = condominiums => {
		setCondominiums(condominiums);
	};

	const handleCondominiumDetail = condominium => {
		setCondominium(condominium);
		setDefaultFormValues(condominium);
	};

	const saveCondominiumDetail = async () => {
		await condominiumSave({
			data: {
				id: condominiumValue.id,
				features: {
					numberOfFloorsAboveGround: numberOfFloorsAboveGroundValue,
					numberOfFloorsBelowGround: numberOfFloorsBelowGroundValue
				},
				documents: {
					licenseEmissionDate: condominium.documents.licenseEmissionDate
						? new Date(new Date(condominium.documents.licenseEmissionDate).setFullYear(licenseEmissionDateValue))
						: new Date(new Date().getFullYear(), 0, 1)
				}
			},
			success: data => {},
			error: error => {}
		});
	};

	// Manager
	const setDefaultManagerFormValues = useCallback(
		async () => {
			managerChangeHandler(state.user.name);
			emailChangeHandler(state.user.email);
			phoneChangeHandler(state.user.cellphone ? state.user.cellphone.trim().replace(/.{3}/g, '$& ') : state.user.phone ? state.user.phone.trim().replace(/.{3}/g, '$& ') : '');
		}, // eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	// Subsidiary
	const getSubsidiaryDetail = async () => {
		setIsLoading(true);

		await subsidiaryDetail({
			success: subsidiary => {
				sendNotification(subsidiary);
			},
			error: error => {
				setIsLoading(false);
				alertError('Ocorreu um erro na submissão do formulário. Por favor, tente novamente.');
			}
		});
	};

	// Log
	const saveFormLog = useCallback(
		async submissionData => {
			await saveLog({
				data: { ...log, ...submissionData },
				success: logId => {
					setLog({ ...log, id: logId });
				},
				error: error => {}
			});
		}, // eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	// Notification
	const handleResetForm = () => {
		alertSuccess('Pedido de cotação submetido com sucesso');
		clearForm();
		setIsLoading(false);
	};

	function getEmailTemplate(subsidiary, message) {
		const emailsubject = 'Multirriscos condomínios - Pedido de cotação';

		if (isRealEstateCapitalBelow10Million) {
			return CondominiumMultiriskInsuranceEmailTemplate({
				subject: emailsubject,
				message: message,
				subsidiaryName: subsidiary.name,
				subsidiaryId: state.user.subsidiaryId,
				subsidiaryVat: state.user.subsidiaryVat,
				managerCondosCounter: condominiums.length,
				condominiumName: condominiumValue.name,
				condominiumVatNumber: vatNumberValue,
				condominiumAddress: addressValue,
				condominiumZipCode: zipCodeValue,
				condominiumZipLocation: zipLocationValue,
				managerName: managerValue,
				managerEmail: emailValue,
				managerPhone: phoneValue,
				realEstateCapital: realEstateCapitalValue,
				numberOfFractions: numberOfFractionsValue,
				objectOfInsurance: objectOfInsuranceValue.label,
				insuranceStartDate: moment(insuranceStartDateValue).format('DD-MM-YYYY'),
				licenseEmissionDate: moment(licenseEmissionDateValue).format('YYYY'),
				numberOfFloorsAboveGround: numberOfFloorsAboveGroundValue,
				numberOfFloorsBelowGround: numberOfFloorsBelowGroundValue,
				typeOfConstruction: typeOfConstructionValue.label,
				remodelingWorks: remodelingWorksValue === 'true' ? 'Sim' : 'Não',
				ifYes: ifYesValue,
				seismicPhenomena: seismicPhenomenaValue ? 'Sim' : 'Não'
			});
		} else {
			return CondominiumMultiriskInsuranceEmailTemplate({
				subject: emailsubject,
				message: message,
				subsidiaryName: subsidiary.name,
				subsidiaryId: state.user.subsidiaryId,
				subsidiaryVat: state.user.subsidiaryVat,
				managerCondosCounter: condominiums.length,
				condominiumName: condominiumValue.name,
				condominiumVatNumber: vatNumberValue,
				condominiumAddress: addressValue,
				condominiumZipCode: zipCodeValue,
				condominiumZipLocation: zipLocationValue,
				managerName: managerValue,
				managerEmail: emailValue,
				managerPhone: phoneValue,
				realEstateCapital: realEstateCapitalValue
			});
		}
	}

	const sendNotificationToUser = async (emailtemplate, emailValue) => {
		let _formData = new FormData();

		_formData.append('body', emailtemplate.body);
		_formData.append('subject', emailtemplate.subject);
		_formData.append('toAddresses', emailValue);
		_formData.append('isHTML', true);
		_formData.append('priority', 0);

		await send_notification({
			data: _formData,
			success: data => {
				handleResetForm();
			},
			error: error => {
				handleResetForm();
			}
		});
	};

	const sendNotification = async subsidiary => {
		const companyMessage = 'Foi efetuado um pedido de cotação de seguro com os dados a seguir apresentados:';
		const userMessage = 'O seu pedido de cotação foi submetido com sucesso. O nosso parceiro irá em breve entrar em contacto consigo.';
		const emailtemplate = getEmailTemplate(subsidiary, companyMessage);
		const useremailtemplate = getEmailTemplate(subsidiary, userMessage);
		let _formData = new FormData();

		if (!isRealEstateCapitalBelow10Million) {
			_formData.append(`attachments[0].attachmentName`, pdfAttachmentValue[0].name);
			_formData.append(`attachments[0].file`, pdfAttachmentValue[0]);
			_formData.append(`attachments[1].attachmentName`, policyAttachmentValue[0].name);
			_formData.append(`attachments[1].file`, policyAttachmentValue[0]);
		}

		_formData.append('condominiumId', condominiumValue.id);
		_formData.append('body', emailtemplate.body);
		_formData.append('subject', emailtemplate.subject);
		_formData.append('toAddresses', InsurancePartnershipEmail);
		_formData.append('isHTML', true);
		_formData.append('priority', 0);

		await send_notification({
			data: _formData,
			success: data => {
				sendNotificationToUser(useremailtemplate, emailValue);
				saveFormLog({
					condominiumId: condominiumValue.id,
					submissionDate: new Date()
				});
				if (isRealEstateCapitalBelow10Million) saveCondominiumDetail();
			},
			error: error => {
				alertError('Ocorreu um erro na submissão do formulário. Por favor, tente novamente.');
				setIsLoading(false);
			}
		});
	};

	// Form
	const setDefaultFormValues = c => {
		vatNumberChangeHandler(c.vatNumber ? c.vatNumber : '');
		addressChangeHandler(c.address && c.address.address && c.address.boxNumber ? `${c.address.address}, ${c.address.boxNumber}` : '');
		zipCodeChangeHandler(c.address && c.address.zipCode ? c.address.zipCode : '');
		zipLocationChangeHandler(c.address && c.address.zipLocation ? c.address.zipLocation : '');
		realEstateCapitalChangeHandler(
			c.collectiveInsurances && c.collectiveInsurances.length > 0 && c.collectiveInsurances[0].insuredAmount
				? c.collectiveInsurances[0].insuredAmount.toString().replace('.', ',')
				: ''
		);
		licenseEmissionDateChangeHandler(c.documents && c.documents.licenseEmissionDate ? new Date(c.documents.licenseEmissionDate).getFullYear().toString() : '');
		numberOfFloorsAboveGroundChangeHandler(c.features && c.features.numberOfFloorsAboveGround ? c.features.numberOfFloorsAboveGround.toString() : '');
		numberOfFloorsBelowGroundChangeHandler(c.features && c.features.numberOfFloorsBelowGround ? c.features.numberOfFloorsBelowGround.toString() : '');
	};

	const validateAllFormInputs = () => {
		condominiumBlurHandler();
		condominiumChangeHandler(condominiumValue);
		vatNumberBlurHandler();
		vatNumberChangeHandler(vatNumberValue);
		addressBlurHandler();
		addressChangeHandler(addressValue);
		zipCodeBlurHandler();
		zipCodeChangeHandler(zipCodeValue);
		zipLocationBlurHandler();
		zipLocationChangeHandler(zipLocationValue);
		emailBlurHandler();
		emailChangeHandler(emailValue);
		phoneBlurHandler();
		phoneChangeHandler(phoneValue);
		realEstateCapitalBlurHandler();
		realEstateCapitalChangeHandler(realEstateCapitalValue);
		termsAndPolicyBlurHandler();
		termsAndPolicyChangeHandler(termsAndPolicyValue);

		if (isRealEstateCapitalBelow10Million) {
			numberOfFractionsBlurHandler();
			numberOfFractionsChangeHandler(numberOfFractionsValue);
			objectOfInsuranceBlurHandler();
			objectOfInsuranceChangeHandler(objectOfInsuranceValue);
			insuranceStartDateBlurHandler();
			insuranceStartDateChangeHandler(insuranceStartDateValue);
			licenseEmissionDateBlurHandler();
			licenseEmissionDateChangeHandler(licenseEmissionDateValue);
			typeOfConstructionBlurHandler();
			typeOfConstructionChangeHandler(typeOfConstructionValue);
			numberOfFloorsAboveGroundBlurHandler();
			numberOfFloorsAboveGroundChangeHandler(numberOfFloorsAboveGroundValue);
			numberOfFloorsBelowGroundBlurHandler();
			numberOfFloorsBelowGroundChangeHandler(numberOfFloorsBelowGroundValue);
			remodelingWorksBlurHandler();
			remodelingWorksChangeHandler(remodelingWorksValue);
			if (remodelingWorksValue === 'true') {
				ifYesBlurHandler();
				ifYesChangeHandler(ifYesValue);
			}
		} else {
			pdfAttachmentBlurHandler();
			pdfAttachmentChangeHandler(pdfAttachmentValue);
			policyAttachmentBlurHandler();
			policyAttachmentChangeHandler(policyAttachmentValue);
		}
	};

	const clearForm = () => {
		resetCondominium();
		resetVatNumber();
		resetAddress();
		resetZipCode();
		resetZipLocation();
		resetRealEstateCapital();
		resetNumberOfFractions();
		resetObjectOfInsurance();
		resetInsuranceStartDate();
		resetSeismicPhenomena();
		resetLicenseEmissionDate();
		resetTypeOfConstruction();
		resetNumberOfFloorsAboveGround();
		resetNumberOfFloorsBelowGround();
		resetRemodelingWorks();
		resetIfYes();
		resetPdfAttachment();
		resetPolicyAttachment();
		resetTermsAndPolicy();
		resetFileInput();
	};

	const handleSubmit = event => {
		event.preventDefault();

		if (!formIsValid) {
			validateAllFormInputs();
			return;
		}

		getSubsidiaryDetail();
	};

	const [resetInputFile, setResetInputFile] = useState([]);

	const resetFileInput = () => {
		let randomString = Math.random().toString(36);
		setResetInputFile(randomString);
	};

	return (
		<>
			<Form className="form--container" onSubmit={handleSubmit}>
				<Row>
					<Col md={12} lg={2}>
						<h5>Condomínio</h5>
					</Col>
					<Col md={12} lg={10}>
						<Row xs={1} md={2}>
							<Col md={9}>
								<DefaultCondominiumSelect
									handleCondominiumList={handleCondominiumList}
									handleCondominiumDetail={handleCondominiumDetail}
									options={condominiums}
									value={condominiumValue}
									onChange={condominiumChangeHandler}
									onBlur={condominiumBlurHandler}
									hasError={condominiumHasError}
									needDetail={true}
								/>
							</Col>
							<Col md={3}>
								<DefaultVATInput
									value={vatNumberValue}
									onChange={vatNumberChangeHandler}
									onBlur={vatNumberBlurHandler}
									hasError={vatNumberHasError}
									hasErrorOnBlur={vatNumberHasErrorOnBlur}
								/>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={6}>
								<DefaultInput
									label={'Morada'}
									name={'address'}
									required={true}
									type={'text'}
									value={addressValue}
									text={'Por favor confirme que a morada é igual à morada da fatura de electicidade.'}
									onChange={addressChangeHandler}
									onBlur={addressBlurHandler}
									hasError={addressHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							<Col xs={6} lg={3}>
								<DefaultInput
									label={'Código postal'}
									name={'zipCode'}
									required={true}
									type={'mask'}
									mask={zipCodeInputMask}
									value={zipCodeValue}
									onChange={zipCodeChangeHandler}
									onBlur={zipCodeBlurHandler}
									hasError={zipCodeHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							<Col xs={6} lg={3}>
								<DefaultInput
									label={'Localidade'}
									name={'zipLocation'}
									required={true}
									type={'text'}
									value={zipLocationValue}
									onChange={zipLocationChangeHandler}
									onBlur={zipLocationBlurHandler}
									hasError={zipLocationHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col md={12} lg={2}>
						<h5>Gestor</h5>
					</Col>
					<Col md={12} lg={10}>
						<Row>
							<Col md={12} lg={4}>
								<DefaultInput
									label={'Nome do gestor'}
									name={'manager'}
									required={true}
									type={'text'}
									value={managerValue}
									onChange={managerChangeHandler}
									onBlur={managerBlurHandler}
									isDisabled={true}
								/>
							</Col>
							<Col md={6} lg={4}>
								<DefaultInput
									label={'Email'}
									name={'email'}
									required={true}
									type={'text'}
									value={emailValue}
									onChange={emailChangeHandler}
									onBlur={emailBlurHandler}
									hasError={emailHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							<Col md={6} lg={4}>
								<DefaultInput
									label={'Telefone'}
									name={'phone'}
									required={true}
									type={'mask'}
									mask={phoneInputMask}
									value={phoneValue}
									onChange={phoneChangeHandler}
									onBlur={phoneBlurHandler}
									hasError={phoneHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col md={12} lg={2}>
						<h5>Local de risco</h5>
					</Col>
					<Col md={12} lg={10}>
						<Row xs={1} md={2} xl={4} xxl={4}>
							<Col>
								<DefaultInput
									label={`Capital a segurar`}
									labelTooltip={
										<TooltipPopover
											tooltipclass="ms-2"
											popoverheader={'Capital a segurar'}
											popoverbody={
												isRealEstateCapitalBelow10Million ? (
													<RealEstateCapitalBelowTooltipBody />
												) : (
													<RealEstateCapitalAboveTooltipBody />
												)
											}
										/>
									}
									name={'realEstateCapital'}
									required={true}
									extraCharacter={'€'}
									type={'mask'}
									mask={currencyInputMask}
									value={realEstateCapitalValue}
									onChange={realEstateCapitalChangeHandler}
									onBlur={realEstateCapitalBlurHandler}
									hasError={realEstateCapitalHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							{isRealEstateCapitalBelow10Million && (
								<>
									<Col>
										<DefaultDatepicker
											label={'Ano de contrução'}
											value={licenseEmissionDateValue}
											dateFormat={'YYYY'}
											required={true}
											onChange={licenseEmissionDateChangeHandler}
											onBlur={licenseEmissionDateBlurHandler}
											hasError={licenseEmissionDateHasError}
											errorMessage={isMandatoryMessage}
										/>
									</Col>
									<Col xl={6} xxl={6}>
										<DefaultSelect
											label={'Tipo de construção'}
											instanceId={'typeOfConstructionSelect'}
											required={true}
											options={typeOfConstructionOptions}
											defaultValue={null}
											value={typeOfConstructionValue}
											onChange={typeOfConstructionChangeHandler}
											onBlur={typeOfConstructionBlurHandler}
											hasError={typeOfConstructionHasError}
											errorMessage={isMandatoryMessage}
										/>
									</Col>
									<Col>
										<DefaultSelect
											label={'Objecto do Seguro'}
											isClearable={true}
											instanceId={'objectOfInsuranceSelect'}
											required={true}
											options={objectOfInsuranceOptions}
											defaultValue={null}
											value={objectOfInsuranceValue}
											onChange={objectOfInsuranceChangeHandler}
											onBlur={objectOfInsuranceBlurHandler}
											hasError={objectOfInsuranceHasError}
											errorMessage={isMandatoryMessage}
										/>
									</Col>
									<Col>
										<DefaultInput
											label={'Nº de frações'}
											name={'numberOfFractions'}
											required={true}
											type={'mask'}
											mask={numberInputMask}
											value={numberOfFractionsValue}
											onChange={numberOfFractionsChangeHandler}
											onBlur={numberOfFractionsBlurHandler}
											hasError={numberOfFractionsHasError}
											errorMessage={isMandatoryMessage}
										/>
									</Col>
									<Col>
										<DefaultInput
											label={'Nº de pisos acima do solo'}
											name={'numberOfFloorsAboveGround'}
											required={true}
											type={'mask'}
											mask={numberInputMask}
											value={numberOfFloorsAboveGroundValue}
											onChange={numberOfFloorsAboveGroundChangeHandler}
											onBlur={numberOfFloorsAboveGroundBlurHandler}
											hasError={numberOfFloorsAboveGroundHasError}
											errorMessage={isMandatoryMessage}
										/>
									</Col>
									<Col>
										<DefaultInput
											label={'Nº de pisos abaixo do solo'}
											name={'numberOfFloorsBelowGround'}
											required={true}
											type={'mask'}
											mask={numberInputMask}
											value={numberOfFloorsBelowGroundValue}
											onChange={numberOfFloorsBelowGroundChangeHandler}
											onBlur={numberOfFloorsBelowGroundBlurHandler}
											hasError={numberOfFloorsBelowGroundHasError}
											errorMessage={isMandatoryMessage}
										/>
									</Col>
									<Col>
										<Form.Label>Proteções extra</Form.Label>
										<div className="d-flex">
											<DefaultCheckbox
												name="seismicPhenomena"
												label="Fenómenos sísmicos"
												checked={seismicPhenomenaValue}
												onChange={seismicPhenomenaChangeHandler}
											/>
											<TooltipPopover popoverheader={'Fenómenos sísmicos'} popoverbody={<SeismicPhenomenaTooltipBody />} />
										</div>
									</Col>
									<Col xl={6} xxl={6} className="mb-4">
										<DefaultRadio
											label={'Obras de remodelação/recuperação'}
											name={'remodelingWorks'}
											required={true}
											vertical={false}
											value={remodelingWorksValue}
											onChange={remodelingWorksChangeHandler}
											onBlur={remodelingWorksBlurHandler}
											hasError={remodelingWorksHasError}
											errorMessage={isMandatoryMessage}
										/>
									</Col>
									<Col>
										{remodelingWorksValue === 'true' && (
											<DefaultInput
												label={'Se "Sim" qual o valor'}
												name={'ifYes'}
												required={true}
												extraCharacter={'€'}
												type={'mask'}
												mask={currencyInputMask}
												value={ifYesValue}
												onChange={ifYesChangeHandler}
												onBlur={ifYesBlurHandler}
												hasError={ifYesHasError}
												errorMessage={isMandatoryMessage}
											/>
										)}
									</Col>
									<Col xxl={3}>
										<DefaultDatepicker
											label={'Data início do seguro'}
											value={insuranceStartDateValue}
											required={true}
											onChange={insuranceStartDateChangeHandler}
											onBlur={insuranceStartDateBlurHandler}
											hasError={insuranceStartDateHasError}
											errorMessage={isMandatoryMessage}
										/>
									</Col>
								</>
							)}
						</Row>
						{!isRealEstateCapitalBelow10Million && (
							<>
								<div>Por favor, download do PDF, preencha e faça upload nesta página juntamente com a apólice em vigor.</div>
								<Row xs={1} md={2}>
									<Col>
										<Button className="download-btn" href={'/docs/CHECK LIST COTAÇÕES CONDOMÍNIOS.pdf'} target="_blank">
											Download do PDF
										</Button>
									</Col>
								</Row>
								<Row xs={1} md={2}>
									<Col>
										<DefaultFileInput
											label={'Carregar documento'}
											value={pdfAttachmentValue}
											key={resetInputFile || ''}
											required={true}
											onChange={pdfAttachmentChangeHandler}
											onBlur={pdfAttachmentBlurHandler}
											hasError={pdfAttachmentHasError}
											errorMessage={isMandatoryMessage}
										/>
									</Col>
								</Row>
								<Row xs={1} md={2}>
									<Col>
										<DefaultFileInput
											label={'Carregar apólice'}
											value={policyAttachmentValue}
											key={resetInputFile || ''}
											required={true}
											onChange={policyAttachmentChangeHandler}
											onBlur={policyAttachmentBlurHandler}
											hasError={policyAttachmentHasError}
											errorMessage={isMandatoryMessage}
										/>
									</Col>
								</Row>
							</>
						)}
						<Row>
							<Col className="form-authorize mt-4">
								<DefaultCheckbox
									name="termsAndPolicy"
									label={SelectContent(termsAndConditions.urlTerms, state.user.type)}
									checked={termsAndPolicyValue}
									onChange={termsAndPolicyChangeHandler}
									onBlur={termsAndPolicyBlurHandler}
									hasError={termsAndPolicyHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<Button type={'submit'} className={'service-btn'}>
									Submeter pedido
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
			{isLoading && <Loading label={'A carregar...'} />}
		</>
	);
}
