// MODULES
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//COMPONENT
import PageHeader from '../../components/header/PageHeader';
import CardBorders from '../../components/card/CardBorders';
// UTILS
import { serviceType, servicePartner } from '../../utils/Enums';
// CONTENT
import { content } from './content/resources';
import { SelectContent } from '../../content/resources';

export default function Cloudware({ userType }) {
	const cardContent = SelectContent(content.cardContent, userType);

	return (
		<Container className="cloudware-main page-bubble-main">
			<PageHeader
				logo={'/img/logo-cloudware.jpg'}
				title={SelectContent(content.title, userType)}
				desc={SelectContent(content.desc, userType)}
				img={'/img/cloudware.png'}
				buttons={[
					{
						label: 'ADERIR',
						link: 'https://cloudware.pt/business/subscricao/?promo_code=IMPROXY',
						log: {
							serviceTypeId: serviceType.OTHER,
							partnerId: servicePartner.CLOUDWARE
						}
					}
				]}
			/>
			<Row md={2} lg={3} className="g-5 mt-5">
				{cardContent.map((card, index) => (
					<Col key={index} className="card-main">
						<CardBorders content={card} />
					</Col>
				))}
			</Row>
		</Container>
	);
}
