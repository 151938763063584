import React from 'react';
import ReactDOM from 'react-dom/client';
// CONTEXT
import { UserDataProvider } from './context/UserContext';
// COMPONENTS
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	//<React.StrictMode>
	<UserDataProvider>
		<App />
	</UserDataProvider>
	//</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
