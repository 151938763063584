import { ApiRequest } from './Helper';

export async function save(a) {
	try {
		const response = await ApiRequest('POST', JSON.stringify(a.data), `${process.env.REACT_APP_API_URL}/api/ServicesPlus/Forms/Logs`);

		if (response.ok) {
			const data = await response.json();
			a.success(data);
		} else {
			a.error(response.status);
		}
	} catch (error) {
		a.error();
	}
}
