export const defaultSelectStyles = {
	control: (baseStyles, state) => ({
		...baseStyles,
		borderRadius: '5px',
		borderColor: '#BFC3C8',
		backgroundColor: '#fff',
		boxShadow: 'none',
		'&:hover': {
			borderColor: '#BFC3C8'
		}
	}),
	singleValue: (baseStyles, state) => ({
		...baseStyles,
		fontSize: '14px',
		color: '#ff0000',
		fontWeight: '400'
	}),
	menu: (baseStyles, state) => ({
		...baseStyles,
		padding: '0'
	}),
	menuList: (baseStyles, state) => ({
		...baseStyles,
		fontSize: '14px',
		fontWeight: '400'
	}),
	indicatorSeparator: (baseStyles, state) => ({
		...baseStyles,
		display: 'none'
	}),
	valueContainer: (baseStyles, state) => ({
		...baseStyles,
		padding: '0 .75rem'
	})
};
