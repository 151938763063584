import { Link } from 'react-router-dom';

// MODULES
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

export default function CardBorders({ content }) {
	return (
		<div className="card-main--border">
			<div className="card-main--header">
				{content.icon && <content.icon />}
				{content.img && (
					<Row className="g-4">
						<img src={content.img} alt={content.title} />
					</Row>
				)}
				<h4 className="mb-4">{content.title}</h4>
				{content.desc}
			</div>
			{!content.files && (
				<div className="d-flex justify-content-center">
					<div className="card-main--btn">
						{content.btn && (
							<Link to={content.btn.link} className="service-btn">
								{content.btn.label}
							</Link>
						)}
					</div>
				</div>
			)}
			{content.comingsoon && <div className="fake-btn text-center">BREVEMENTE</div>}
			{content.files &&
				content.files.map((file, index) => {
					return (
						<Row key={index} className="justify-content-center mt-3">
							<Col className="text-center">
								<Button className={`service-btn ${file.customClass ? file.customClass : ''}`} href={file.pdf} target="_blank">
									{file.buttonTitle}
								</Button>
							</Col>
						</Row>
					);
				})}
		</div>
	);
}
