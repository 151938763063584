export const content = {
	title: {
		default: 'Métodos de pagamento integrados no Gecond'
	},
	desc: {
		default: (
			<>
				<label>
					Transferências simplificadas, MB WAY, referências Multibanco e Payshop: serviços interbancários assegurados pelo parceiro Lusopay, instituição de pagamento
					registada no Banco de Portugal e demais bancos centrais da União Europeia.
				</label>

				<h3 className="fw-bold mt-5">40% de bónus por pacote de referências MB</h3>
				<p>Campanha válida para aquisição de novos pacotes para condomínios que nunca adquiriram referências MB Lusopay.</p>
				<p>
					<strong>Como funciona?</strong> Por cada pacote adquirido a Improxy reverte 40% do valor da compra efetuada para o seu saldo do programa de parcerias
					<strong>Serviços+</strong>.
				</p>
			</>
		),
		selfManagement: (
			<p>
				Os condóminos podem utilizar diversos métodos de pagamento seguros, escolhendo o método da sua preferência. Para o condomínio, significa um custo reduzido e otimização
				de processos.
			</p>
		)
	},
	details: {
		title: {
			default: 'Vantagens'
		},
		list: {
			default: ['Custo reduzido por transação', 'Segurança e comodidade', 'Maior controlo dos recebimentos', 'Geração e envio automatizado dos recibos']
		},
		desc: {
			default: 'Acesso a uma listagem com todos os pagamentos efetuados. O Gecond assumirá os respetivos valores como pagos.'
		},
		descBold: {
			default: 'Poderá gerar e enviar os recibos de forma automatizada, após pagamento, caso esteja ativada esta opção na plataforma Gecond.'
		}
	},
	paymentSection: {
		title: {
			default: 'Métodos de pagamento: como funcionam?'
		},
		desc: {
			default: 'Os métodos de pagamento estarão disponíveis, caso estejam ativados, no email enviado ao condómino com o aviso de cobrança. Cabe ao condómino optar por qual usar.'
		},
		payment: {
			default: [
				{
					icon: '/img/multibanco.png',
					title: 'Referências Multibanco e Payshop',
					description:
						'O serviço de pagamento por referência MB, é uma funcionalidade que permite receber pagamentos por meio de códigos bancários normalizados.Serão enviados automaticamente, no corpo do email com o aviso de cobrança, os dados habituais para pagamento.'
				},
				{
					icon: '/img/wallet.png',
					title: 'Transferências simplificadas ',
					description: (
						<>
							É um meio de pagamento pelo qual o pagador realiza uma transferência SEPA+ sem ter de escrever o valor a pagar, o descritivo, o nome e o IBAN do
							destinatário do pagamento.
							<p>
								Quando o pagador seleciona este meio de pagamento, é encaminhado para o banco onde tem conta bancária e apenas terá de aceitar ou
								recusar a transação.
							</p>
						</>
					)
				},
				{
					icon: '/img/mbway-logo.png',
					title: 'MB WAY, o método mais popular',
					description:
						'O MB WAY é o método de pagamento com maior crescimento em Portugal, preferido por cada vez mais utilizadores pela sua simplicidade. Será disponibilizado um link no email e será direcionado para pay.gecond.com onde será pedido o número de telemóvel associado ao MB WAY.'
				}
			]
		}
	}
};
