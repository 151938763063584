// Modules
import { Button, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import PageHeader from '../../components/header/PageHeader';
import ParthnerBalance from './ParthnerBalance';
import Invoice from './TableInvoice';

export default function Balance() {
	const partnerList = [
		{
			partner: 'Fast Path',
			contract: '1',
			value: '23'
		},
		{
			partner: 'Endesa',
			contract: '3',
			value: '54'
		},
		{
			partner: 'Iberdrola',
			contract: '2',
			value: '36'
		},
		{
			partner: 'Luzigás',
			contract: '1',
			value: '18'
		}
	];
	return (
		<Container className="balance-main">
			<Row>
				<Col md={9}>
					<PageHeader subtitle={'Vantagens'} title={'Histórico de descontos'} />
				</Col>
				<Col className="my-4 mx-auto">
					<div className="balance-value--available">
						<p>Total disponível</p>
						<h1>24€</h1>
					</div>
				</Col>
			</Row>
			<Row className="gx-5">
				<Col>
					<div className="submit-invoice--main">
						<h3>Texto explicativo do procedimento para levantar o saldo disponível</h3>
						<Button className="service-btn--white">SUBMETER</Button>
					</div>
					{partnerList.map((item, index) => (
						<ParthnerBalance key={index} partner={item.partner} contract={item.contract} value={item.value} />
					))}
					<div className="balance-value--total">
						<span>Total acumulado</span> <span className="fs-2">131€</span>
					</div>
				</Col>
				<Col>
					<Invoice />
				</Col>
			</Row>
		</Container>
	);
}
