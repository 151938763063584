import { Link } from 'react-router-dom';

// MODULES
import Col from 'react-bootstrap/Col';

export default function OthersHighlightHome({ img, subtitle, title, desc, link, btnLabel, promo = false }) {
	return (
		<Col className="pb-5 z-3 position-relative">
			<div className="other-services">
				{promo && <div className="other-services--promo">{promo}</div>}
				<div className="other-services--img">
					<img src={img} alt={subtitle} />
				</div>
				{subtitle && <h6 className="notranslate">{subtitle}</h6>}
				<h4>{title}</h4>
				<p>{desc}</p>
				<div className="other-services--btn d-flex justify-content-center">
					{btnLabel ? (
						<Link to={link} className="service-btn">
							{btnLabel}
						</Link>
					) : (
						<div className="fake-btn"></div>
					)}
				</div>
			</div>
		</Col>
	);
}
