import { ApiRequestNoJson } from './Helper';

export async function send_notification(a) {
	try {
		const response = await ApiRequestNoJson('POST', a.data, `${process.env.REACT_APP_API_URL}/api/ServicesPlus/Forms/SendEmail`);

		if (response.ok) {
			const data = await response.json();
			a.success(data);
		} else {
			a.error(response.status);
		}
	} catch (error) {
		a.error(error);
	}
}
