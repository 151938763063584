import { renderTableHead, renderTableTitle, renderTableIntrodution, renderTableSectionTitle, renderTableFooter, renderTableRow } from '../../../components/email/Email';

export const CompanyMultiriskInsuranceEmailTemplate = data => {
	const emailSubject = data.subject;
	const emailBody = `<!DOCTYPE html PUBLIC '-//W3C//DTD HTML 4.01 Transitional//EN' 'http://www.w3.org/TR/html4/loose.dtd'><html lang="en">
		${renderTableHead(data.subject)}
		<body style='margin: 0; padding: 0; background-color: #f2f2f2; font-family: ' Century Gothic', 'Verdana' , 'Tahoma' ; font-size: 14px'>
			<span style='display: block; width: 640px !important; max-width: 640px; height: 1px' class='mobileOff'></span>
			<center>
				<table width="100%" border="0" cellpadding="0" cellspacing="0" bgcolor="#F2F2F2">
					<tr>
						<td align="center" valign="top">
							${renderTableTitle(data.subject)}
							${renderTableIntrodution(data.message)}
							<table width='640' cellpadding='0' cellspacing='0' border='0' class='wrapper' bgcolor='#FFFFFF'>
								${renderTableSectionTitle('Empresa')}
								<tr>
									<td align='center' valign='top'>
										${renderTableRow('Nome', data.subsidiaryName)}
										${renderTableRow('NIF', data.subsidiaryVat)}
									</td>
								</tr>
								${renderTableSectionTitle('Tomador')}
								<tr>
									<td align='center' valign='top'>
										${renderTableRow('Tomador', data.policyholder)}
										${renderTableRow('NIF', data.vatNumber)}
										${renderTableRow('Email', data.email)}
										${renderTableRow('Telefone', data.phone)}
										${renderTableRow('Morada', data.address)}
										${renderTableRow('Cód. postal', data.zipcode)}
										${renderTableRow('Localidade', data.location)}
									</td>
								</tr>
								${renderTableSectionTitle('Local de risco')}
								<tr>
									<td align='center' valign='top'>
										${renderTableRow('Imóvel / conteúdo', data.propertycontent)}
										${renderTableRow('Ano de construção', data.licenseEmissionDate)}
										${renderTableRow('Capital do imóvel', data.realestatecapital, '€')}
										${renderTableRow('Capital do conteúdo:', data.contentcapital, '€')}
										${renderTableRow('Sector de atividade', data.sectionactivity)}
										${renderTableRow('Ocupação', data.occupation)}
										${renderTableRow('Tipo de fundação', data.foundationtype)}
										${renderTableRow('Tipo de construção', data.typeofconstruction)}
										${renderTableRow('Material do telhado', data.roofmaterial)}
										${renderTableRow('Medidas de proteção', data.protectivemeasure)}
										${renderTableRow('Segurar perda de lucros', data.intendprofitlost)}
										${renderTableRow('Capital perda de lucros', data.losscapitalprofits, '€')}
										${renderTableRow('Período de indeminização', data.indemnityperiod)}
									</td>
								</tr>
							</table>
							${renderTableFooter(data.subsidiaryName)}
						</td>
					</tr>
				</table>
			</center>
		</body>
	</html>`;

	return {
		subject: emailSubject,
		body: emailBody
	};
};

export const CompanyMultiriskInsuranceEmailToUserTemplate = data => {
	const emailSubject = 'Multirriscos empresa - Pedido Proposta';
	const emailBody = `<!DOCTYPE html PUBLIC '-//W3C//DTD HTML 4.01 Transitional//EN' 'http://www.w3.org/TR/html4/loose.dtd'><html lang="en">
		${renderTableHead('Multirriscos empresa - Pedido Proposta')}
		<body style='margin: 0; padding: 0; background-color: #f2f2f2; font-family: ' Century Gothic', 'Verdana' , 'Tahoma' ; font-size: 14px'>
			<span style='display: block; width: 640px !important; max-width: 640px; height: 1px' class='mobileOff'></span>
			<center>
				<table width="100%" border="0" cellpadding="0" cellspacing="0" bgcolor="#F2F2F2">
					<tr>
						<td align="center" valign="top">
							${renderTableTitle('Multirriscos empresa')}
							${renderTableIntrodution('O seu pedido de cotação foi submetido com sucesso. O nosso parceiro irá em breve entrar em contacto consigo.')}
							<table width='640' cellpadding='0' cellspacing='0' border='0' class='wrapper' bgcolor='#FFFFFF'>
								${renderTableSectionTitle('Empresa')}
								<tr>
									<td align='center' valign='top'>
										${renderTableRow('Tomador', data.policyholder)}
										${renderTableRow('NIF', data.vatNumber)}
										${renderTableRow('Email', data.email)}
										${renderTableRow('Telefone', data.phone)}
										${renderTableRow('Morada', data.address)}
										${renderTableRow('Cód. postal', data.zipcode)}
										${renderTableRow('Localidade', data.location)}
									</td>
								</tr>
								${renderTableSectionTitle('Local de risco')}
								<tr>
									<td align='center' valign='top'>
										${renderTableRow('Imóvel / conteúdo', data.propertycontent)}
										${renderTableRow('Ano de construção', data.licenseEmissionDate)}
										${renderTableRow('Capital do imóvel', data.realestatecapital, '€')}
										${renderTableRow('Capital do conteúdo:', data.contentcapital, '€')}
										${renderTableRow('Sector de atividade', data.sectionactivity)}
										${renderTableRow('Ocupação', data.occupation)}
										${renderTableRow('Tipo de fundação', data.foundationtype)}
										${renderTableRow('Tipo de construção', data.typeofconstruction)}
										${renderTableRow('Material do telhado', data.roofmaterial)}
										${renderTableRow('Medidas de proteção', data.protectivemeasure)}
										${renderTableRow('Segurar perda de lucros', data.intendprofitlost)}
										${renderTableRow('Capital perda de lucros', data.losscapitalprofits, '€')}
										${renderTableRow('Período de indeminização', data.indemnityperiod)}
									</td>
								</tr>
							</table>
							${renderTableFooter(data.subsidiaryName)}
						</td>
					</tr>
				</table>
			</center>
		</body>
	</html>`;

	return {
		subject: emailSubject,
		body: emailBody
	};
};
