import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

export function toastSuccess(message) {
	Swal.fire({
		toast: true,
		titleText: message,
		text: 'Sucesso',
		icon: 'success',
		position: 'top-end',
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true
	});
}

export function toastError(message) {
	Swal.fire({
		toast: true,
		titleText: message,
		icon: 'error',
		position: 'top-end',
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true
	});
}

export function alertSuccess(message, description = '') {
	Swal.fire({
		titleText: message,
		text: description,
		icon: 'success',
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true
	});
}

export function alertError(message, description = '') {
	Swal.fire({
		titleText: message,
		text: description,
		icon: 'error',
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true
	});
}
