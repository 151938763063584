import React from 'react';
// MODULES
import Container from 'react-bootstrap/Container';
// COMPONENTS
import PageHeader from '../../components/header/PageHeader';
import ProfessionalCivilLiabilityInsuranceForm from './components/form/ProfessionalCivilLiabilityInsuranceForm';

export default function ProfessionalCivilLiabilityInsurance() {
	// STATE

	return (
		<Container>
			<PageHeader subtitle={'Seguros +'} title={'Seguro responsabilidade civil profissional'} />
			<ProfessionalCivilLiabilityInsuranceForm />
		</Container>
	);
}
