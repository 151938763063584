// MODULES
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// COMPONENTS
import PageHeader from '../../components/header/PageHeader';
import CardMini from '../../components/card/CardMini';
// CONTENT
import { content } from './content/resources';
import { SelectContent } from '../../content/resources';
// UTILS
import { serviceType, servicePartner } from '../../utils/Enums';

export default function MP({ userType }) {
	return (
		<>
			<Container className="mp-main page-bubble-main">
				<PageHeader
					logo={'/img/mp-cropped.jpg'}
					title={SelectContent(content.title, userType)}
					desc={SelectContent(content.desc, userType)}
					img={'/img/mp-post-service.png'}
					buttons={[
						{
							label: 'COMO FUNCIONA',
							link: 'https://areaclientes.improxy.com/pt-pt/knowledgebase/article/KA-01478'
						},
						{
							label: 'ADERIR',
							link: 'https://www.massivepurple-sp.pt/pages/mpsp-gecond/',
							log: {
								serviceTypeId: serviceType.OTHER,
								partnerId: servicePartner.MP
							}
						}
					]}
				/>
			</Container>
			<CardMini content={SelectContent(content.services, userType)} />
			<Container>
				<Row className="mt-4 mb-3">
					<div>{SelectContent(content.servicesDescription, userType).title}</div>
					<div>{SelectContent(content.servicesDescription, userType).servicesList}</div>
				</Row>
			</Container>
			<div className="card-blue">
				<Container>
					<Row className="mx-3 text-center">
						<h3>{SelectContent(content.benefit, userType).title}</h3>
					</Row>
					<Row className="mb-4 g-4 mp-advantage-card" md={2}>
						{SelectContent(content.benefit, userType).benefitList.map((item, index) => (
							<Col key={index}>
								<div className="mp-advantage-card--wrapper">
									<h4>{item.title}</h4>
									<p>{item.desc}</p>
								</div>
							</Col>
						))}
					</Row>
				</Container>
			</div>
		</>
	);
}
