import { userTypes } from '../utils/Enums';

export function SelectContent(label, type) {
	let finalContent;

	switch (type) {
		case userTypes.SELF_MANAGEMENT:
			label.selfManagement === undefined ? (finalContent = label.default) : (finalContent = label.selfManagement);
			break;
		default:
			finalContent = label.default;
	}
	return finalContent;
}
