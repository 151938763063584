var config = {
	// Site Config ====================================================
	siteTitle: 'Improxy Software',
	siteDescription: 'Improxy Software',
	siteCannonicalUrl: process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' ? 'http://localhost:3000 ' : 'https://fastpath.pt',
	siteKeywords: '',
	cryptoKey: 'Improxy Insurance'
};

module.exports = config;
