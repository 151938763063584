import React from 'react';
// MODULES
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// CONTEXT
import { useUserData } from '../../context/UserContext';
import { userTypes } from '../../utils/Enums';

export default function Header() {
	// CONTEXT
	const { state } = useUserData();

	// CONSTANTS

	return (
		<Navbar collapseOnSelect expand="lg" className="header-main">
			<Container className="header-main--wrapper">
				<Navbar.Brand href="/" className="header-main__logo">
					<img src="/img/services_logo.png" alt="Serviços +"></img>
				</Navbar.Brand>
				<Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
					<Nav className="d-flex align-items-center ">
						<Nav.Link href="/Insurance">Seguros +</Nav.Link>
						<Nav.Link href="/Energy">Eletricidade</Nav.Link>
						<Nav.Link href="/#partnerships">Outras parcerias</Nav.Link>
						{/* <div className="header-saldos__link">{showSaldos && <Nav.Link href="/Saldos">Saldos</Nav.Link>}</div> */}
						{/* <Nav.Link href="/FAQ">FAQ</Nav.Link> */}
					</Nav>
				</Navbar.Collapse>
				<div className="menu--main-btn">
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					{/* {showSaldos && (
						<Nav.Link className="header-main__button" href="/Saldos">
							Saldos
						</Nav.Link>
					)} */}
				</div>
			</Container>
		</Navbar>
	);
}
