import React, { useCallback, useEffect, useState } from 'react';
// MODULES
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
// CONTEXT
import { useUserData } from '../../../../context/UserContext';
// COMPONENTS
import { alertError, alertSuccess } from '../../../../components/feedback/Feedback';
import Loading from '../../../../components/loading/Loading';
// FORM
import useInput from '../../../../components/form/hooks/useInput';
import DefaultCheckbox from '../../../../components/form/inputs/DefaultCheckbox';
import DefaultCondominiumSelect from '../../../../components/form/inputs/DefaultCondominiumSelect';
import DefaultFileInput from '../../../../components/form/inputs/DefaultFileInput';
import DefaultInput from '../../../../components/form/inputs/DefaultInput';
import DefaultRadio from '../../../../components/form/inputs/DefaultRadio';
import DefaultSelect from '../../../../components/form/inputs/DefaultSelect';
import DefaultVATInput from '../../../../components/form/inputs/DefaultVATInput';
import { phoneInputMask, zipCodeInputMask } from '../../../../components/form/masks/InputMasks';
import { isMandatoryMessage } from '../../../../components/form/validator/ValidatorMessages';
import { isChecked, isEmail, isNIPCValid, isNotEmpty, isNotEmptyFile, isNotNullOrUndefinedOrEmpty } from '../../../../components/form/validator/ValidatorRules';
// ACTIONS
import { bankaccounts } from '../../../../actions/Condominiums';
import { save as saveLog } from '../../../../actions/LogRegister';
import { send_notification } from '../../../../actions/Notifier';
import { detail as subsidiaryDetail } from '../../../../actions/Subsidiary';
// UTILS
import { GecondPartnershipEmail } from '../../../../utils/Constants';
import { serviceOrigin, serviceType } from '../../../../utils/Enums';
// CONTENT
import { SelectContent } from '../../../../content/resources';
// RESOURCES
import { hirepoweroptions, preferredsupplieroptions, selectrateoptions } from '../../../../components/form/fillforms/energy';
import { termsAndConditions } from '../../../../components/form/fillforms/general';
import { EnergyEmailTemplate } from '../../emailtemplates/EnergyEmailTemplate';

export default function EnergyForm() {
	const {
		value: condominiumValue,
		isValid: condominiumIsValid,
		hasError: condominiumHasError,
		valueChangeHandler: condominiumChangeHandler,
		inputBlurHandler: condominiumBlurHandler,
		reset: resetCondominium
	} = useInput(isNotNullOrUndefinedOrEmpty);
	const {
		value: vatNumberValue,
		isValid: vatNumberIsValid,
		hasError: vatNumberHasError,
		hasErrorOnBlur: vatNumberHasErrorOnBlur,
		valueChangeHandler: vatNumberChangeHandler,
		inputBlurHandler: vatNumberBlurHandler,
		reset: resetVatNumber
	} = useInput(isNotEmpty, isNIPCValid);
	const {
		value: addressValue,
		isValid: addressIsValid,
		hasError: addressHasError,
		valueChangeHandler: addressChangeHandler,
		inputBlurHandler: addressBlurHandler,
		reset: resetAddress
	} = useInput(isNotEmpty);
	const {
		value: zipcodeValue,
		isValid: zipcodeIsValid,
		hasError: zipcodeHasError,
		valueChangeHandler: zipcodeChangeHandler,
		inputBlurHandler: zipcodeBlurHandler,
		reset: resetZipcode
	} = useInput(isNotEmpty);
	const {
		value: ziplocationValue,
		isValid: ziplocationIsValid,
		hasError: ziplocationHasError,
		valueChangeHandler: ziplocationChangeHandler,
		inputBlurHandler: ziplocationBlurHandler,
		reset: resetZiplocation
	} = useInput(isNotEmpty);
	const { value: managerValue, valueChangeHandler: managerChangeHandler, inputBlurHandler: managerBlurHandler } = useInput(() => true);
	const { value: emailValue, isValid: emailIsValid, hasError: emailHasError, valueChangeHandler: emailChangeHandler, inputBlurHandler: emailBlurHandler } = useInput(isEmail);
	const { value: phoneValue, isValid: phoneIsValid, hasError: phoneHasError, valueChangeHandler: phoneChangeHandler, inputBlurHandler: phoneBlurHandler } = useInput(isNotEmpty);
	const { value: bankaccountValue, valueChangeHandler: bankaccountChangeHandler, inputBlurHandler: bankaccountBlurHandler, reset: resetBankaccount } = useInput(() => true);
	const { value: cpeValue, isValid: cpeIsValid, hasError: cpeHasError, valueChangeHandler: cpeChangeHandler, inputBlurHandler: cpeBlurHandler, reset: resetCpe } = useInput(isNotEmpty);
	const {
		value: hirepowerValue,
		isValid: hirepowerIsValid,
		hasError: hirepowerHasError,
		valueChangeHandler: hirepowerChangeHandler,
		inputBlurHandler: hirepowerBlurHandler,
		reset: resetHirepower
	} = useInput(isNotNullOrUndefinedOrEmpty);
	const {
		value: selectrateValue,
		isValid: selectrateIsValid,
		hasError: selectrateHasError,
		valueChangeHandler: selectrateChangeHandler,
		inputBlurHandler: selectrateBlurHandler,
		reset: resetSelectrate
	} = useInput(isNotEmpty);
	const {
		value: preferredsupplierValue,
		isValid: preferredsupplierIsValid,
		hasError: preferredsupplierHasError,
		valueChangeHandler: preferredsupplierChangeHandler,
		inputBlurHandler: preferredsupplierBlurHandler,
		reset: resetPreferredsupplier
	} = useInput(isNotNullOrUndefinedOrEmpty);
	const {
		value: minuteAttachmentValue,
		isValid: minuteAttachmentIsValid,
		hasError: minuteAttachmentHasError,
		valueChangeHandler: minuteAttachmentChangeHandler,
		inputBlurHandler: minuteAttachmentBlurHandler,
		reset: resetMinuteAttachment
	} = useInput(isNotEmptyFile);
	const {
		value: invoiceAttachmentValue,
		isValid: invoiceAttachmentIsValid,
		hasError: invoiceAttachmentHasError,
		valueChangeHandler: invoiceAttachmentChangeHandler,
		inputBlurHandler: invoiceAttachmentBlurHandler,
		reset: resetInvoiceAttachment
	} = useInput(isNotEmptyFile);
	const {
		value: ibanproofAttachmentValue,
		isValid: ibanproofAttachmentIsValid,
		hasError: ibanproofAttachmentHasError,
		valueChangeHandler: ibanproofAttachmentChangeHandler,
		inputBlurHandler: ibanproofAttachmentBlurHandler,
		reset: resetIbanproofAttachment
	} = useInput(isNotEmptyFile);
	const {
		value: termsandpolicyValue,
		isValid: termsandpolicyIsValid,
		hasError: termsandpolicyHasError,
		valueChangeHandler: termsandpolicyChangeHandler,
		inputBlurHandler: termsandpolicyBlurHandler,
		reset: resetTermsandpolicy
	} = useInput(isChecked);

	let formIsValid = false;

	if (
		condominiumIsValid &&
		vatNumberIsValid &&
		addressIsValid &&
		zipcodeIsValid &&
		ziplocationIsValid &&
		emailIsValid &&
		phoneIsValid &&
		cpeIsValid &&
		hirepowerIsValid &&
		preferredsupplierIsValid &&
		selectrateIsValid &&
		minuteAttachmentIsValid &&
		invoiceAttachmentIsValid &&
		ibanproofAttachmentIsValid &&
		termsandpolicyIsValid
	) {
		formIsValid = true;
	}

	// CONTEXT
	const { state } = useUserData();

	// STATE
	const [condominiums, setCondominiums] = useState([]);
	const [condominiumBankAccounts, setCondominiumBankAccounts] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [log, setLog] = useState({
		id: 0,
		subsidiaryId: state.user.subsidiaryId,
		userId: state.user.id,
		originId: serviceOrigin.SERVICE_PLUS,
		startDate: new Date(),
		serviceTypeId: serviceType.ELECTRICITY
	});

	// USEEFFECT
	useEffect(
		() => {
			setDefaultManagerFormValues();
		}, // eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	// METHODS
	// Condominiums
	const handleCondominiumChange = option => {
		saveFormLog();
		condominiumChangeHandler(option);

		if (option) {
			getCondominiumBankAccounts(option);
		}
	};

	const handleCondominiumList = condominiums => {
		setCondominiums(condominiums);
	};

	const getCondominiumBankAccounts = async selectedcondominium => {
		await bankaccounts({
			condominium_id: selectedcondominium.id,
			success: bankaccounts => {
				setCondominiumBankAccounts(bankaccounts);
				setDefaultFormValues(selectedcondominium);
				setIsLoading(false);
			},
			error: error => {}
		});
	};

	const handlePreferredsupplierChange = option => {
		preferredsupplierChangeHandler(option);
		setLog({ ...log, partnerId: option ? option.id : null });
	};

	// Manager
	const setDefaultManagerFormValues = useCallback(
		async () => {
			managerChangeHandler(state.user.name);
			emailChangeHandler(state.user.email);
			phoneChangeHandler(state.user.cellphone ? state.user.cellphone.trim().replace(/.{3}/g, '$& ') : state.user.phone ? state.user.phone.trim().replace(/.{3}/g, '$& ') : '');
		}, // eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	// Subsidiary
	const getSubsidiaryDetail = async () => {
		setIsLoading(true);

		await subsidiaryDetail({
			success: subsidiary => {
				sendNotification(subsidiary);
			},
			error: error => {
				setIsLoading(false);
				alertError('Ocorreu um erro na submissão do formulário. Por favor, tente novamente.');
			}
		});
	};

	// Log
	const saveFormLog = useCallback(
		async submissionData => {
			await saveLog({
				data: { ...log, ...submissionData },
				success: logId => {
					setLog({ ...log, id: logId });
				},
				error: error => {}
			});
		}, // eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	// Notification
	const handleResetForm = () => {
		alertSuccess('O seu contrato foi submetido com sucesso.', 'Irá receber por email o seu contrato.');
		clearForm();
		setIsLoading(false);
	};

	function getEmailTemplate(subsidiary, message) {
		const emailsubject = `${preferredsupplierValue.label} - Pedido Proposta`;

		return EnergyEmailTemplate({
			subject: emailsubject,
			message: message,
			subsidiaryName: subsidiary.name,
			subsidiaryId: state.user.subsidiaryId,
			subsidiaryVat: state.user.subsidiaryVat,
			condominiumName: condominiumValue.name,
			condominiumVatNumber: vatNumberValue,
			condominiumAddress: addressValue,
			condominiumZipcode: zipcodeValue,
			condominiumZiplocation: ziplocationValue,
			managerName: managerValue,
			managerEmail: emailValue,
			managerPhone: phoneValue,
			iban: bankaccountValue ? `${bankaccountValue.ibanPrefix} ${bankaccountValue.nib} - ${bankaccountValue.bankSmallDesignation}` : '',
			cpe: cpeValue,
			selectrate: selectrateoptions[parseInt(selectrateValue) - 1].label,
			hirepower: hirepowerValue.label,
			preferredsupplier: preferredsupplierValue.label
		});
	}

	const sendNotificationToUser = async (emailtemplate, emailValue) => {
		let _formData = new FormData();

		_formData.append('body', emailtemplate.body);
		_formData.append('subject', emailtemplate.subject);
		_formData.append('toAddresses', emailValue);
		_formData.append('isHTML', true);
		_formData.append('priority', 0);

		await send_notification({
			data: _formData,
			success: data => {
				handleResetForm();
			},
			error: error => {
				handleResetForm();
			}
		});
	};

	const sendNotification = async subsidiary => {
		const companyMessage = 'Foi efetuado um pedido de proposta de energia com os dados a seguir apresentados:';
		const userMessage = 'O seu pedido foi submetido com sucesso. O nosso parceiro irá em breve entrar em contacto consigo.';
		const emailtemplate = getEmailTemplate(subsidiary, companyMessage);
		const useremailtemplate = getEmailTemplate(subsidiary, userMessage);
		let _formData = new FormData();

		_formData.append('condominiumId', condominiumValue.id);
		_formData.append('body', emailtemplate.body);
		_formData.append('subject', emailtemplate.subject);
		_formData.append('toAddresses', GecondPartnershipEmail);
		_formData.append('isHTML', true);
		_formData.append('priority', 0);
		_formData.append(`attachments[0].attachmentName`, minuteAttachmentValue[0].name);
		_formData.append(`attachments[0].file`, minuteAttachmentValue[0]);
		_formData.append(`attachments[1].attachmentName`, invoiceAttachmentValue[0].name);
		_formData.append(`attachments[1].file`, invoiceAttachmentValue[0]);
		_formData.append(`attachments[2].attachmentName`, ibanproofAttachmentValue[0].name);
		_formData.append(`attachments[2].file`, ibanproofAttachmentValue[0]);

		await send_notification({
			data: _formData,
			success: data => {
				sendNotificationToUser(useremailtemplate, emailValue);
				saveFormLog({
					condominiumId: condominiumValue.id,
					partnerId: preferredsupplierValue.id,
					submissionDate: new Date()
				});
			},
			error: error => {
				alertError('Ocorreu um erro na submissão do formulário.', 'Por favor, tente novamente.');
				setIsLoading(false);
			}
		});
	};

	// Form
	const setDefaultFormValues = c => {
		resetBankaccount();
		vatNumberChangeHandler(c.vatNumber ? c.vatNumber : '');
		addressChangeHandler(c.address && c.address.address && c.address.boxNumber ? `${c.address.address}, ${c.address.boxNumber}` : '');
		zipcodeChangeHandler(c.address && c.address.zipCode ? c.address.zipCode : '');
		ziplocationChangeHandler(c.address && c.address.zipLocation ? c.address.zipLocation : '');
	};

	const validateAllFormInputs = () => {
		condominiumBlurHandler();
		condominiumChangeHandler(condominiumValue);
		vatNumberBlurHandler();
		vatNumberChangeHandler(vatNumberValue);
		addressBlurHandler();
		addressChangeHandler(addressValue);
		zipcodeBlurHandler();
		zipcodeChangeHandler(zipcodeValue);
		ziplocationBlurHandler();
		ziplocationChangeHandler(ziplocationValue);
		emailBlurHandler();
		emailChangeHandler(emailValue);
		phoneBlurHandler();
		phoneChangeHandler(phoneValue);
		bankaccountBlurHandler();
		bankaccountChangeHandler(bankaccountValue);
		cpeBlurHandler();
		cpeChangeHandler(cpeValue);
		hirepowerBlurHandler();
		hirepowerChangeHandler(hirepowerValue);
		preferredsupplierBlurHandler();
		preferredsupplierChangeHandler(preferredsupplierValue);
		selectrateBlurHandler();
		selectrateChangeHandler(selectrateValue);
		minuteAttachmentBlurHandler();
		minuteAttachmentChangeHandler(minuteAttachmentValue);
		invoiceAttachmentBlurHandler();
		invoiceAttachmentChangeHandler(invoiceAttachmentValue);
		ibanproofAttachmentBlurHandler();
		ibanproofAttachmentChangeHandler(ibanproofAttachmentValue);
		termsandpolicyBlurHandler();
		termsandpolicyChangeHandler(termsandpolicyValue);
	};

	const clearForm = () => {
		resetCondominium();
		resetVatNumber();
		resetAddress();
		resetZipcode();
		resetZiplocation();
		resetBankaccount();
		resetCpe();
		resetHirepower();
		resetPreferredsupplier();
		resetSelectrate();
		resetMinuteAttachment();
		resetInvoiceAttachment();
		resetIbanproofAttachment();
		resetTermsandpolicy();
		resetFileInput();
	};

	const handleSubmit = event => {
		event.preventDefault();

		if (!formIsValid) {
			validateAllFormInputs();
			return;
		}

		getSubsidiaryDetail();
	};

	const [resetInputFile, setResetInputFile] = useState([]);

	const resetFileInput = () => {
		let randomString = Math.random().toString(36);
		setResetInputFile(randomString);
	};

	return (
		<>
			<Row className="my-4">
				<Col>
					<h2 className="text-center">Formulário de subscrição</h2>
				</Col>
			</Row>
			<Form className="form--container" onSubmit={handleSubmit}>
				<Row>
					<Col md={12} lg={2}>
						<h5>Condomínio</h5>
					</Col>
					<Col md={12} lg={10}>
						<Row xs={1} md={2}>
							<Col>
								<DefaultCondominiumSelect
									handleCondominiumList={handleCondominiumList}
									options={condominiums}
									value={condominiumValue}
									onChange={handleCondominiumChange}
									onBlur={condominiumBlurHandler}
									hasError={condominiumHasError}
								/>
							</Col>
							<Col>
								<DefaultVATInput
									value={vatNumberValue}
									onChange={vatNumberChangeHandler}
									onBlur={vatNumberBlurHandler}
									hasError={vatNumberHasError}
									hasErrorOnBlur={vatNumberHasErrorOnBlur}
								/>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={6}>
								<DefaultInput
									label={'Morada'}
									name={'address'}
									required={true}
									type={'text'}
									value={addressValue}
									text={'Por favor confirme que a morada é igual à morada da fatura de electicidade.'}
									onChange={addressChangeHandler}
									onBlur={addressBlurHandler}
									hasError={addressHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							<Col xs={6} lg={3}>
								<DefaultInput
									label={'Código postal'}
									name={'zipcode'}
									required={true}
									type={'mask'}
									mask={zipCodeInputMask}
									value={zipcodeValue}
									onChange={zipcodeChangeHandler}
									onBlur={zipcodeBlurHandler}
									hasError={zipcodeHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							<Col xs={6} lg={3}>
								<DefaultInput
									label={'Localidade'}
									name={'ziplocation'}
									required={true}
									type={'text'}
									value={ziplocationValue}
									onChange={ziplocationChangeHandler}
									onBlur={ziplocationBlurHandler}
									hasError={ziplocationHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col md={12} lg={2}>
						<h5>Gestor</h5>
					</Col>
					<Col md={12} lg={10}>
						<Row>
							<Col md={12} lg={4}>
								<DefaultInput
									label={'Nome do gestor'}
									name={'manager'}
									required={true}
									type={'text'}
									value={managerValue}
									onChange={managerChangeHandler}
									onBlur={managerBlurHandler}
									isDisabled={true}
								/>
							</Col>
							<Col md={6} lg={4}>
								<DefaultInput
									label={'Email'}
									name={'email'}
									required={true}
									type={'text'}
									value={emailValue}
									onChange={emailChangeHandler}
									onBlur={emailBlurHandler}
									hasError={emailHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							<Col md={6} lg={4}>
								<DefaultInput
									label={'Telefone'}
									name={'phone'}
									required={true}
									type={'mask'}
									mask={phoneInputMask}
									value={phoneValue}
									onChange={phoneChangeHandler}
									onBlur={phoneBlurHandler}
									hasError={phoneHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col md={12} lg={2}>
						<h5>Dados contrato</h5>
					</Col>
					<Col md={12} lg={10}>
						<Row xs={1} md={2}>
							<Col>
								<DefaultSelect
									label={'IBAN'}
									instanceId={'bankaccountSelect'}
									options={condominiumBankAccounts}
									getOptionValue={option => option.bankAccountId}
									getOptionLabel={option => `${option.ibanPrefix} ${option.nib} - ${option.bankSmallDesignation}`}
									defaultValue={null}
									value={bankaccountValue}
									isDisabled={condominiumBankAccounts.length === 0}
									onChange={bankaccountChangeHandler}
									onBlur={bankaccountBlurHandler}
								/>
							</Col>
							<Col>
								<DefaultInput
									label={'CPE'}
									name={'cpe'}
									required={true}
									type={'text'}
									text={'Pode encontrar o CPE numa fatura de energia relativa a este condomínio'}
									value={cpeValue}
									onChange={cpeChangeHandler}
									onBlur={cpeBlurHandler}
									hasError={cpeHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
						</Row>
						<Row xs={1} md={2}>
							<Col>
								<DefaultSelect
									label={'Potência a contratar (kVA)'}
									instanceId={'hirepowertSelect'}
									required={true}
									options={hirepoweroptions}
									defaultValue={null}
									value={hirepowerValue}
									onChange={hirepowerChangeHandler}
									onBlur={hirepowerBlurHandler}
									hasError={hirepowerHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							<Col>
								<DefaultSelect
									label={'Fornecedor preferencial'}
									instanceId={'preferredsupplier'}
									required={true}
									options={preferredsupplieroptions}
									defaultValue={null}
									value={preferredsupplierValue}
									onChange={handlePreferredsupplierChange}
									onBlur={preferredsupplierBlurHandler}
									hasError={preferredsupplierHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
							<Col>
								<DefaultRadio
									label={'Selecionar tarifa'}
									name={'selectrate'}
									value={selectrateValue}
									required={true}
									options={selectrateoptions}
									onChange={selectrateChangeHandler}
									onBlur={selectrateBlurHandler}
									hasError={selectrateHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
						</Row>
						<Row xs={1} md={2}>
							<Col>
								<DefaultFileInput
									label={'Ata da tomada de posse'}
									value={minuteAttachmentValue}
									key={resetInputFile || ''}
									required={true}
									onChange={minuteAttachmentChangeHandler}
									hasError={minuteAttachmentHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
						</Row>
						<Row xs={1} md={2}>
							<Col>
								<DefaultFileInput
									label={'Anexar faturas'}
									key={resetInputFile || ''}
									value={invoiceAttachmentValue}
									required={true}
									onChange={invoiceAttachmentChangeHandler}
									hasError={invoiceAttachmentHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
						</Row>
						<Row xs={1} md={2}>
							<Col>
								<DefaultFileInput
									label={'Comprovativo de IBAN'}
									key={resetInputFile || ''}
									value={ibanproofAttachmentValue}
									required={true}
									onChange={ibanproofAttachmentChangeHandler}
									hasError={ibanproofAttachmentHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col md={12} lg={{ span: 10, offset: 2 }}>
						<Row>
							<Col className="form-authorize mt-4">
								<DefaultCheckbox
									name="termsandpolicy"
									label={SelectContent(termsAndConditions.urlTerms, state.user.type)}
									checked={termsandpolicyValue}
									onChange={termsandpolicyChangeHandler}
									onBlur={termsandpolicyBlurHandler}
									hasError={termsandpolicyHasError}
									errorMessage={isMandatoryMessage}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<Button type={'submit'} className={'service-btn mt-5'}>
									Submeter pedido
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
			{isLoading && <Loading label={'A carregar...'} />}
		</>
	);
}
