import parse from 'html-react-parser';
import Form from 'react-bootstrap/Form';

const DefaultCheckbox = ({ name, checked, label, onChange, onBlur, readOnly = false, disabled = false, hasError = false, errorMessage = '' }) => {
	return (
		<>
			<Form.Check id={name} type="checkbox" label={parse(label)} checked={checked} disabled={disabled} onChange={onChange} onBlur={onBlur} readOnly={readOnly} />
			{hasError && errorMessage !== '' && <small className={'text-danger d-block mt-2'}>{errorMessage}</small>}
		</>
	);
};

export default DefaultCheckbox;
