// MODULES
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import MaskedInput from 'react-text-mask';
import useInput, { updateFormControl } from '../hooks/useInput';

const Input = ({
	label,
	showLabel = true,
	labelTooltip,
	type,
	maxlength,
	extraCharacter,
	name,
	required = false,
	errorMessage = '',
	text,
	readOnly = false,
	isDisabled = false,
	mask,
	guide = false,
	formControl,
	validations = () => true
}) => {
	const {
		value,
		isValid,
		hasError,
		hasErrorOnBlur,
		valueChangeHandler: onChange,
		inputBlurHandler: onBlur,
		reset
	} = useInput(typeof errorMessage === 'object' ? validations[0] : validations, typeof errorMessage === 'object' ? validations[1] : undefined);
	updateFormControl(formControl, name, value, isValid, hasError, hasErrorOnBlur, onChange, onBlur, reset);

	const getInputByType = () => {
		switch (type) {
			case 'textarea':
				return (
					<Form.Control
						name={name}
						maxLength={maxlength}
						value={value}
						onChange={onChange}
						onBlur={onBlur}
						readOnly={readOnly}
						rows={3}
						disabled={isDisabled}
					></Form.Control>
				);
			case 'mask':
				return (
					<InputGroup>
						<MaskedInput
							mask={mask}
							guide={guide}
							className="form-control"
							value={value}
							name={name}
							onChange={onChange}
							onBlur={onBlur}
							readOnly={readOnly}
							disabled={isDisabled}
						/>
						{extraCharacter && <InputGroup.Text>{extraCharacter}</InputGroup.Text>}
					</InputGroup>
				);
			default:
				return (
					<InputGroup>
						<Form.Control
							name={name}
							maxLength={maxlength}
							type={type}
							value={value}
							onChange={onChange}
							onBlur={onBlur}
							readOnly={readOnly}
							disabled={isDisabled}
						/>
						{extraCharacter && <InputGroup.Text>{extraCharacter}</InputGroup.Text>}
					</InputGroup>
				);
		}
	};

	let feed = '';
	if (hasError || hasErrorOnBlur) {
		if (typeof errorMessage === 'object') {
			if (hasErrorOnBlur) feed = errorMessage[1];
			else feed = errorMessage[0];
		} else {
			feed = errorMessage;
		}
	}

	return (
		<Form.Group className="mb-4">
			{showLabel && (
				<div className="d-flex">
					<Form.Label className={`${required ? 'form--required' : ''}`}>{label}</Form.Label>
					{labelTooltip && <>{labelTooltip}</>}
				</div>
			)}
			{getInputByType()}
			{text && <Form.Text className="text-muted">{text}</Form.Text>}
			{(hasError || hasErrorOnBlur) && feed !== '' && <small className={'text-danger d-block mt-2'}>{feed}</small>}
		</Form.Group>
	);
};

export default Input;
