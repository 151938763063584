import * as Utils from '../utils/Utils';
import { ApiRequest } from './Helper';

export async function list(a) {
	try {
		var parameters = [];

		if (!Utils.isUndefinedOrNull(a.withAddress)) parameters.push('withAddress=' + a.withAddress);
		if (!Utils.isUndefinedOrNull(a.withManagers)) parameters.push('withManagers=' + a.withManagers);
		if (!Utils.isUndefinedOrNull(a.withCounters)) parameters.push('withCounters=' + a.withCounters);
		if (!Utils.isUndefinedOrNull(a.managerEntityId)) parameters.push('managerEntityId=' + a.managerEntityId);

		const response = await ApiRequest('GET', null, `${process.env.REACT_APP_API_URL}/api/Gecond/Condominiums${parameters.length > 0 ? '?' + parameters.join('&') : ''}`);

		if (response.ok) {
			const data = await response.json();
			a.success(data);
		} else {
			a.error(response.status);
		}
	} catch (error) {
		a.error();
	}
}

export async function detail(a) {
	try {
		const response = await ApiRequest('GET', null, `${process.env.REACT_APP_API_URL}/api/Gecond/Condominiums/${a.id}`);

		if (response.ok) {
			const data = await response.json();
			a.success(data);
		} else {
			a.error(response.status);
		}
	} catch (error) {
		a.error();
	}
}

export async function save(a) {
	try {
		const response = await ApiRequest('POST', JSON.stringify(a.data), `${process.env.REACT_APP_API_URL}/api/Gecond/Condominiums/Save`);

		if (response.ok) {
			const data = await response.json();
			a.success(data);
		} else {
			a.error(response.status);
		}
	} catch (error) {
		a.error();
	}
}

export async function bankaccounts(a) {
	try {
		var parameters = [];

		if (!Utils.isUndefinedOrNull(a.condominium_id)) parameters.push('condominium_id=' + a.condominium_id);
		if (!Utils.isUndefinedOrNull(a.bank_account_id)) parameters.push('bank_account_id=' + a.bank_account_id);

		const response = await ApiRequest('GET', null, `${process.env.REACT_APP_API_URL}/api/Gecond/Condominiums/BankAccounts${parameters.length > 0 ? '?' + parameters.join('&') : ''}`);

		if (response.ok) {
			const data = await response.json();
			a.success(data);
		} else {
			a.error(response.status);
		}
	} catch (error) {
		a.error();
	}
}
