// MODULES
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import MaskedInput from 'react-text-mask';

const DefaultInput = ({
	label,
	showLabel = true,
	labelTooltip,
	value,
	onChange,
	onBlur,
	type,
	maxlength,
	extraCharacter,
	name,
	required = false,
	hasError = false,
	errorMessage = '',
	text,
	readOnly = false,
	isDisabled = false,
	mask,
	guide = false
}) => {
	const getInputByType = () => {
		switch (type) {
			case 'textarea':
				return (
					<Form.Control
						name={name}
						maxLength={maxlength}
						value={value}
						onChange={onChange}
						onBlur={onBlur}
						readOnly={readOnly}
						rows={3}
						disabled={isDisabled}
					></Form.Control>
				);
			case 'mask':
				return (
					<InputGroup>
						<MaskedInput
							mask={mask}
							guide={guide}
							className="form-control"
							value={value}
							name={name}
							onChange={onChange}
							onBlur={onBlur}
							readOnly={readOnly}
							disabled={isDisabled}
						/>
						{extraCharacter && <InputGroup.Text>{extraCharacter}</InputGroup.Text>}
					</InputGroup>
				);
			default:
				return (
					<InputGroup>
						<Form.Control
							name={name}
							maxLength={maxlength}
							type={type}
							value={value}
							onChange={onChange}
							onBlur={onBlur}
							readOnly={readOnly}
							disabled={isDisabled}
						/>
						{extraCharacter && <InputGroup.Text>{extraCharacter}</InputGroup.Text>}
					</InputGroup>
				);
		}
	};

	return (
		<Form.Group className="mb-4">
			{showLabel && (
				<div className="d-flex">
					<Form.Label className={`${required ? 'form--required' : ''}`}>{label}</Form.Label>
					{labelTooltip && <>{labelTooltip}</>}
				</div>
			)}
			{getInputByType()}
			{text && <Form.Text className="text-muted">{text}</Form.Text>}
			{hasError && errorMessage !== '' && <small className={'text-danger d-block mt-2'}>{errorMessage}</small>}
		</Form.Group>
	);
};

export default DefaultInput;
