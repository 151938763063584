import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const defaultNumberMaskOptions = {
	prefix: '',
	suffix: '',
	includeThousandsSeparator: false,
	thousandsSeparatorSymbol: '.',
	allowDecimal: true,
	decimalSymbol: ',',
	decimalLimit: 2,
	integerLimit: 12,
	allowNegative: false,
	allowLeadingZeroes: false
};

export const zipCodeInputMask = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

export const phoneInputMask = [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/];

export const numberInputMask = createNumberMask({
	...defaultNumberMaskOptions
});

export const vatNumberInputMask = createNumberMask({
	...defaultNumberMaskOptions,
	integerLimit: 9
});

export const currencyInputMask = createNumberMask({
	...defaultNumberMaskOptions,
	includeThousandsSeparator: true,
	allowDecimal: true,
	decimalLimit: 2
});
