import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, OverlayTrigger, Row } from 'react-bootstrap';
import Popover from 'react-bootstrap/Popover';
import { FaFilePdf } from 'react-icons/fa6';
import './CardPopover.scss';

function CardPopover() {
	const [simplePopoverShow, setSimplePopoverShow] = useState(false);
	const [biSchedulePopoverShow, setBiSchedulePopoverShow] = useState(false);

	const popoverRef = useRef(null);

	const handleOutsideClick = event => {
		if (popoverRef.current && !popoverRef.current.contains(event.target)) {
			setSimplePopoverShow(false);
			setBiSchedulePopoverShow(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleOutsideClick);

		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, []);

	const toggleSimplePopover = () => {
		setSimplePopoverShow(!simplePopoverShow);
		setBiSchedulePopoverShow(false);
	};

	const toggleBiSchedulePopover = () => {
		setBiSchedulePopoverShow(!biSchedulePopoverShow);
		setSimplePopoverShow(false);
	};

	const simplePopover = (
		<Popover id="popover-simple" ref={popoverRef}>
			<Popover.Body>
				<div className="endesa-popup">
					<ul className="list-unstyled">
						<li>
							<div className="d-flex justify-center align-items-center">
								<FaFilePdf size={25} color="#398ec7" />
								<a
									className="text-decoration-none mr-2"
									href="/docs/endesa/simples/A24.0223 Anexo de Precios. Tarifa Aniversario Luz & Gas.pdf"
									target="_blank"
								>
									<label>Tarifa Aniversário</label>
								</a>
							</div>
						</li>
						<li className="mt-3">
							<div className="d-flex justify-center align-items-center">
								<FaFilePdf size={25} color="#398ec7" className="pl-2" />
								<a className="text-decoration-none" href="/docs/endesa/simples/A24.0207 Anexo de Precios. Tarifa Quero+ Luz & Gas.pdf" target="_blank">
									<label>Tarifa Quero+</label>
								</a>
							</div>
						</li>
						<li className="mt-3">
							<div className="d-flex justify-center align-items-center">
								<FaFilePdf size={25} color="#398ec7" className="pl-2" />
								<a className="text-decoration-none" href="/docs/endesa/simples/A24.0219 Anexo de Precios. Tarifa e - Luz & Gas.pdf" target="_blank">
									<label>Tarifa E</label>
								</a>
							</div>
						</li>
						<li className="mt-3">
							<div className="d-flex justify-center align-items-center">
								<FaFilePdf size={25} color="#398ec7" className="pl-2" />
								<a className="text-decoration-none" href="/docs/endesa/simples/A24.0225 Anexo de Precios. Tarifa Tranquilidade Luz.pdf" target="_blank">
									<label>Tarifa Tranquilidade</label>
								</a>
							</div>
						</li>
						<li className="mt-3">
							<div className="d-flex justify-center align-items-center">
								<FaFilePdf size={25} color="#398ec7" className="pl-2" />
								<a className="text-decoration-none" href="/docs/endesa/simples/A24.0248 Anexo de Precios_Tarifa HAPPY Luz & Gas.pdf" target="_blank">
									<label>Tarifa Happy</label>
								</a>
							</div>
						</li>
					</ul>
				</div>
			</Popover.Body>
		</Popover>
	);

	const biSchedulePopover = (
		<Popover id="popover-bi-schedule" ref={popoverRef}>
			<Popover.Body>
				<div className="endesa-popup">
					<ul className="list-unstyled">
						<li>
							<div className="d-flex justify-center align-items-center">
								<FaFilePdf size={25} color="#398ec7" className="pl-2" />
								<a
									className="text-decoration-none"
									href="/docs/endesa/bihorario/A24.0208 Anexo de Precios. Tarifa Quero+ Luz Bi-horaria & Gas.pdf"
									target="_blank"
								>
									<label>Tarifa Quero+</label>
								</a>
							</div>
						</li>
						<li className="mt-3">
							<div className="d-flex justify-center align-items-center">
								<FaFilePdf size={25} color="#398ec7" className="pl-2" />
								<a
									className="text-decoration-none"
									href="/docs/endesa/bihorario/A24.0226 Anexo de Precios. Tarifa Tranquilidade Luz Bi-horaria.pdf"
									target="_blank"
								>
									<label>Tarifa Tranquilidade</label>
								</a>
							</div>
						</li>
						<li className="mt-3">
							<div className="d-flex justify-center align-items-center">
								<FaFilePdf size={25} color="#398ec7" className="pl-2" />
								<a
									className="text-decoration-none"
									href="/docs/endesa/bihorario/A24.0220 Anexo de Precios. Tarifa e - Luz Bi-horaria & Gas.pdf"
									target="_blank"
								>
									<label>Tarifa E</label>
								</a>
							</div>
						</li>
						<li className="mt-3">
							<div className="d-flex justify-center align-items-center">
								<FaFilePdf size={25} color="#398ec7" className="pl-2" />
								<a
									className="text-decoration-none"
									href="/docs/endesa/bihorario/A24.0249 Anexo de Precios. Tarifa Happy Luz Bi-horária & Gás.pdf"
									target="_blank"
								>
									<label>Tarifa Happy</label>
								</a>
							</div>
						</li>
					</ul>
				</div>
			</Popover.Body>
		</Popover>
	);

	return (
		<div className="card-main--border">
			<div className="card-main--header">
				<Row className="g-4">
					<img src="/img/logo-endesa.jpg" alt="endesa-logo" />
				</Row>
				<h4 className="mb-4">Endesa</h4>
				Planos de Energia dedicados aos condomínios com tarifa fixa. Liberdade total para decidir sobre a sua permanência na Endesa, a qualquer altura, em qualquer lugar, sem
				quaisquer penalizações.
			</div>
			<Row className="justify-content-center mt-3">
				<Col className="text-center">
					<div className="justify-content-center mt-3 row">
						<OverlayTrigger trigger="click" placement="top" show={simplePopoverShow} overlay={simplePopover}>
							<Button
								className="service-btn"
								onClick={e => {
									e.stopPropagation();
									toggleSimplePopover();
								}}
							>
								TARIFÁRIOS E CONDIÇÕES - Simples
							</Button>
						</OverlayTrigger>
					</div>

					<div className="justify-content-center mt-3 row">
						<OverlayTrigger trigger="click" placement="top" show={biSchedulePopoverShow} overlay={biSchedulePopover}>
							<Button className="service-btn" ref={popoverRef} onClick={toggleBiSchedulePopover}>
								TARIFÁRIOS E CONDIÇÕES - Bi-horário
							</Button>
						</OverlayTrigger>
					</div>
				</Col>
			</Row>
		</div>
	);
}

export default CardPopover;
