import { AES, enc } from 'crypto-js';
import config from '../config';

export const token_storage = 'w2binsurancestk';
export const backofficeToken = '891b05d8-2bd4-440c-98c0-d76bd98960df';
export const openApiToken = 'a92b9f59-9f64-4eca-a654-d7859bff43bc';

export function GetToken() {
	var token = sessionStorage.getItem(token_storage);
	if (token && token !== '') {
		return config.cryptoKey ? AES.decrypt(token, config.cryptoKey).toString(enc.Utf8) : token;
	}
}

export function SetToken(token) {
	sessionStorage.setItem(token_storage, config.cryptoKey ? AES.encrypt(token, config.cryptoKey) : token);
}

export function RemoveToken() {
	sessionStorage.removeItem(token_storage);
}

export function LogOut() {
	RemoveToken();
}
